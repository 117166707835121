import React, { useState, useRef, useEffect } from 'react';

const CodeInputBoxes = ({ onChange, onSubmit }) => {
  const [codeInputs, setCodeInputs] = useState(Array(6).fill(''));
  const inputRefs = useRef([]);
  inputRefs.current = codeInputs.map((_, i) => inputRefs.current[i] ?? React.createRef());

  const handleChange = (index, value) => {
    const newCodeInputs = [...codeInputs];
    if (!value) {
      // If value is empty, user might be deleting, handle in onKeyDown instead
      return;
    }
    if (!/^[0-9]$/.test(value)) return;

    newCodeInputs[index] = value;
    setCodeInputs(newCodeInputs);
    onChange(newCodeInputs);

    if (newCodeInputs.every(input => input !== '')) {
      onSubmit(newCodeInputs.join(''));
    }
  };

  const handleKeyDown = (event, index) => {
    if (event.key === 'Backspace' && !codeInputs[index]) {
      // Delete the value and move to the previous input if there's one
      const newCodeInputs = [...codeInputs];
      if (index > 0) {
        newCodeInputs[index - 1] = '';
        setCodeInputs(newCodeInputs);
        inputRefs.current[index - 1].current.focus();
        onChange(newCodeInputs);
      }
    } else if (event.key === 'Backspace') {
      // Clear the current box and keep the focus
      const newCodeInputs = [...codeInputs];
      newCodeInputs[index] = '';
      setCodeInputs(newCodeInputs);
      onChange(newCodeInputs);
    } else if (event.key !== 'Backspace' && codeInputs[index].length === 1) {
      // Focus the next input on inputting a number
      if (index < codeInputs.length - 1) {
        inputRefs.current[index + 1].current.focus();
      }
    }
  };

  useEffect(() => {
    // Automatically focus the first input on initial render
    inputRefs.current[0].current.focus();
  }, []);

  return (
    <div className="input-group-code">
      {codeInputs.map((_, idx) => (
        <input
          key={idx}
          ref={inputRefs.current[idx]}
          type="text"
          maxLength="1"
          className="code-input"
          value={codeInputs[idx]}
          onChange={(e) => handleChange(idx, e.target.value)}
          onKeyDown={(e) => handleKeyDown(e, idx)}
        />
      ))}
    </div>
  );
};

export default CodeInputBoxes;
