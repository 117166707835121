import React from "react";
import { Draggable } from "react-beautiful-dnd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faLocationDot,
  faEnvelope,
  faMobile,
  faCalendarDays,
  faKeyboard,
  faSquareCheck,
  faICursor,
  faHeading,
  faParagraph,
  faUpDown,
  faGripVertical,
  faSignature,
  faMinus,
} from "@fortawesome/free-solid-svg-icons";

<i class="fa-solid "></i>;
const iconMap = {
  faUser,
  faLocationDot,
  faEnvelope,
  faMobile,
  faCalendarDays,
  faKeyboard,
  faSquareCheck,
  faICursor,
  faHeading,
  faParagraph,
  faUpDown,
  faSignature,
  faMinus
};

<i class="fa-solid fa-heading"></i>;

const PaletteCard = ({ typeData }) => {
  return (
    <Draggable draggableId={typeData.type} index={0}>
      {(provided) => (
        <div
          className="PaletteFormElementCard"
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
        >
          <div className="icons">
            {typeData.icon && iconMap[typeData.icon] && (
              <FontAwesomeIcon icon={iconMap[typeData.icon]} />
            )}
          </div>

          <div>{typeData.title}</div>

          <div className="iconContainer">
            <FontAwesomeIcon icon={faGripVertical} />
          </div>
        </div>
      )}
    </Draggable>
  );
};

export default PaletteCard;
