import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import '../Website/Website.css';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import SearchBar from '../Articles/SearchBar';
import Topbar from './Topbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleUser, faMailReply, faLock, faHeadset } from '@fortawesome/free-solid-svg-icons';
import Modal from 'react-modal';
import { Link, useNavigate } from 'react-router-dom';
import SupportFeatured from '../Website/subcomponents/supportFeatured';
import HexagonSpinner from './Animations/Hexspinner';
import useUID from './useUID';

Modal.setAppElement('#root'); // Assuming your root element has the ID 'root'

const UserSupportPage = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState('');
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [userEmail, setUserEmail] = useState('');
  const [uid] = useUID();

  const navigate = useNavigate();
  const db = getFirestore();

  useEffect(() => {
    const fetchUserEmail = async () => {
      if (uid) {
        const userDocRef = doc(db, 'users', uid);
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists()) {
          setUserEmail(userDoc.data().email);
        } else {
          console.error('No such document!');
        }
      }
    };

    fetchUserEmail();
  }, [uid]);

  const openModal = (content) => {
    setModalContent(content);
    setIsModalOpen(true);
    setSubmitSuccess(false);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const goToForgotPassword = () => {
    navigate('/forgot-password'); // Navigates to the Forgot Password page
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    const functions = getFunctions();
    const sendEmail = httpsCallable(functions, 'sendEmailSupport'); 
    const formData = {
      email: event.target.email.value,
      subject: event.target.subject.value,
      product: event.target.product.value,
      priority: event.target.priority.value,
      message: event.target.message.value,
    };

    sendEmail(formData)
      .then(() => {
        setLoading(false);
        setSubmitSuccess(true);
      })
      .catch((error) => {
        console.error('Error sending email: ', error);
        setLoading(false);
        setSubmitSuccess(false);
      });
  };

  return (
    <>
      <Topbar />
      <section className="websiteblock">

      <Modal isOpen={isModalOpen} onRequestClose={closeModal} contentLabel="Support Modal" className='confirmModal'>
        <button onClick={closeModal} className='filesCloseButton'> X </button>
        {modalContent === 'contactForm' && (
          <div>
            {loading ? (
              <div>
                <HexagonSpinner />
                <button onClick={closeModal} className='filesCloseButton'> X </button>
              </div>
            ) : submitSuccess ? (
              <div>
                <p>Thank you for contacting us. Our support team will get back to you shortly.</p>
                <button onClick={closeModal} className='filesCloseButton'> X </button>
              </div>
            ) : (
              <div>
                <h2>Contact Support</h2>
                <form onSubmit={handleSubmit}>
                  <div className="input-group-row">
                    <div className="input-field">
                      <label htmlFor="email">Your Email:</label>
                      <input
                        id="email"
                        name="email"
                        type="email"
                        value={userEmail}
                        readOnly
                        required
                      />
                      <br />
                    </div>
                  </div>
                  <div className="input-group-row">
                    <div className="input-field">
                      <label htmlFor="subject">Subject:</label>
                      <input
                        id="subject"
                        name="subject"
                        type="text"
                        required
                      />
                      <br />
                    </div>
                  </div>
                  <div className="input-group-row">
                    <div className="input-field">
                      <label htmlFor="product">Product:</label>
                      <select id="product" name="product" required>
                        <option value="">Select a product</option>
                        <option value="Claims">Claims</option>
                        <option value="Eligibility">Eligibility</option>
                        <option value="Claim Status">Claim Status</option>
                        <option value="Patient Files">Patient Files</option>
                        <option value="AI">AI</option>
                        <option value="Auth">Auth</option>
                        <option value="Other">Other</option>
                      </select>
                      <br />
                    </div>
                  </div>
                  <div className="input-group-row">
                    <div className="input-field">
                      <label htmlFor="priority">Priority:</label>
                      <select id="priority" name="priority" required>
                        <option value="">Select priority level</option>
                        <option value="Low">Low</option>
                        <option value="Medium">Medium</option>
                        <option value="High">High</option>
                      </select>
                      <br />
                    </div>
                  </div>
                  <div className="input-group-row">
                    <div className="input-field">
                      <label htmlFor="message">Message:</label>
                      <textarea
                        id="message"
                        name="message"
                        rows="4"
                        required
                      ></textarea>
                      <br />
                    </div>
                  </div>
                  <button type="submit">Send Message</button>
                </form>
              </div>
            )}
          </div>
        )}
        {modalContent === 'supportEmail' && (
          <div>
            <h2>Need Help?</h2>
            <p>If you can't sign in or have any questions, please contact us at: <a href="mailto:support@popularishealth.com">support@popularishealth.com</a></p>
          </div>
        )}
        {modalContent === 'forgotPassword' && (
          <div>
            <h2>Forgot Your Password?</h2>
            <button onClick={goToForgotPassword} className='forgotPasswordButton'>Go to Forgot Password</button>
          </div>
        )}
        {modalContent === 'emergency' && (
          <div>
            <h2>Do you need immediate contact?</h2>
            <p>Please don't hesitate to call or text us at: (929) 382-4573</p>
            <p>Alternatively, you can email us at: <a href="mailto:support@popularishealth.com">support@popularishealth.com</a></p>
          </div>
        )}
      </Modal>
      
        <h2 className='typography-headline'>Search for blogs or support articles.</h2>
        <SearchBar category="support" />
        <SupportFeatured />      

        <div style={{ padding: "1rem", flexDirection: "column", alignItems: "normal", marginTop: "4rem"}} className='tile-content tile-white tile'>
          <div className='support-wrapper'>
            <div className='support-icon-features'>
              <FontAwesomeIcon style={{ height: '100px' }} icon={faHeadset}/>
            </div>
            <div>
              <h2 className='typography-headline'>Contact Support</h2>
              <p className='typography-label-alt-dark'>Fill out the form below to reach out to us.</p>
            </div>
          </div>
          <button className='sign-in-button-support' onClick={() => openModal('contactForm')}>Contact Form</button>

          <div className='support-modals'>
            <span style={{color: "#0147DF"}} className="support-button-text" onClick={() => openModal('supportEmail')}>
              <FontAwesomeIcon style={{ marginRight: "1rem" }} icon={faMailReply} />
              Can't sign in or have a question?
            </span>
            <span style={{color: "#0147DF"}} className="support-button-text" onClick={() => openModal('forgotPassword')}>
              <FontAwesomeIcon style={{ marginRight: "1rem" }} icon={faLock} />
              Forgot your password?
            </span>
            <span style={{color: "#0147DF"}} className="support-button-text" onClick={() => openModal('emergency')}>
              <FontAwesomeIcon style={{ marginRight: "1rem" }} icon={faCircleUser} />
              Need immediate assistance?
            </span>
          </div>
        </div>
      </section>
     
    </>
  );
};

export default UserSupportPage;
