import React, { useState } from "react";
import BillingCodesSelector from "../Subcomponents/BillingCodesSelector";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalculator } from "@fortawesome/free-solid-svg-icons";

const CodesMenu = ({ onClose }) => {
  const [showBillingCodes, setShowBillingCodes] = useState(false);

  const handleBillingCodesClick = () => {
    setShowBillingCodes(true);
  };

  const closeBillingCode = () => {
    setShowBillingCodes(false);
  };

  const handleBillingCodeSelect = () => {};

  // Determine the button class
  const buttonClass = `MainActionButton ${showBillingCodes ? "selected" : ""}`;

  return (
    <>
      {!showBillingCodes && (
        <button
          id="BillingCodesButton"
          onClick={handleBillingCodesClick}
          className={buttonClass}
        >
          <FontAwesomeIcon
            icon={faCalculator}
            style={{ marginRight: "10px" }}
          />
          My Billing Codes
        </button>
      )}

      {showBillingCodes && (
        <div className="Claims-inner-container">
          <div className="Claims-inner-content">
          <button className="filesCloseButton" onClick={closeBillingCode}>
            X
          </button>
          <div className="claimsMenus">
            <BillingCodesSelector
              onBillingCodeSelect={handleBillingCodeSelect}
            />
          </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CodesMenu;
