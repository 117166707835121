import React from 'react';
import styles from './Footer.module.css';

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles.left}>
        <a href="/terms">Terms and Conditions</a>
      </div>
      <div className={styles.middle}>
        <p>© Popularis Health - All Rights Reserved</p>
      </div>
      <div className={styles.right}>
        <a href="/privacy">Privacy Policy</a>
      </div>
    </footer>
  );
};

export default Footer;
