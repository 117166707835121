import React, { useState, useEffect } from 'react';
import { getFirestore, doc, getDoc, getDocs, collection, query, where, limit, orderBy, onSnapshot } from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBoltLightning } from '@fortawesome/free-solid-svg-icons';
import useUID from '../../General/useUID';
import { storage, auth, db } from '../../../firebase';
import QuicksearchData from '../../Eligibility/EligibilityResultsViewData';
import Modal from 'react-modal';

function InstantEligibility({ patientId }) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [hasRemainingRequests, setHasRemainingRequests] = useState(true);
  const [uid, subUserUID, isLoading] = useUID();
  const [patientNotFound, setPatientNotFound] = useState(false);
  const [patientData, setPatientData] = useState(null);
  const [dos, setDos] = useState(() => {
    const today = new Date();
    const formattedDate = today.toISOString().split('T')[0];
    return formattedDate;
  });
  const [providerOrganizationName, setProviderOrganizationName] = useState('');
  const [npi, setNPI] = useState('');
  const functions = getFunctions();

  function formatDate(dateStr) {
    if (!dateStr) {
      return ""; // Handle empty string case
    }

    const isMMDDYYYY = /^\d{2}\/\d{2}\/\d{4}|\d{4}-\d{2}-\d{2}$/;

    if (isMMDDYYYY) {
      return dateStr; // No conversion needed
    }

    if (dateStr.includes('-') && dateStr.length === 10) {
      const [year, month, day] = dateStr.split('-');
      return `${month}/${day}/${year}`;
    }

    console.warn("Invalid DOB format:", dateStr);
    return dateStr;
  }

  const decryptPatientData = async (encryptedPayload) => {
    const decryptFunction = httpsCallable(functions, "decrypt");
    const result = await decryptFunction(encryptedPayload);
    return result.data;
  };

  const handleRunEligibility = async () => {
    if (!uid) {
      setError("User identification is required to run eligibility checks.");
      setIsModalOpen(true);
      setLoading(false);
      return;
    }

    setLoading(true);
    setError(null);

    try {
      const userDocRef = doc(db, "users", uid);
      const userDocSnap = await getDoc(userDocRef);
      if (!userDocSnap.exists()) {
        throw new Error("User document does not exist.");
      }

      const providersRef = collection(db, `users/${uid}/providers`);
      const defaultProviderQuery = query(providersRef, where("isDefault", "==", true));
      const querySnapshot = await getDocs(defaultProviderQuery);

      if (querySnapshot.empty) {
        throw new Error("No default provider found for the user.");
      }

      const defaultProvider = querySnapshot.docs[0].data();
      const decryptFunction = httpsCallable(functions, 'decrypt');
      const ciphertext = defaultProvider.ciphertext;
      const iv = defaultProvider.iv;
      const result = await decryptFunction({ ciphertext, iv });

      if (!result.data.organizationName || !result.data.npi) {
        throw new Error("Decrypted data is missing organization name or NPI.");
      }

      const organizationName = result.data.organizationName;
      const npi = result.data.npi;

      await fetchAccountTierAndCheckRemainingRequests();

      const patientDocRef = doc(db, `patients/${uid}/patientData/${patientId}`);
      const patientDocSnap = await getDoc(patientDocRef);
      if (!patientDocSnap.exists()) {
        throw new Error('No patient data available for the provided ID.');
      }

      const patientData = patientDocSnap.data();
      const encryptedPayload = { iv: patientData.patient.iv, ciphertext: patientData.patient.ciphertext };
      if (!encryptedPayload.iv || !encryptedPayload.ciphertext) {
        throw new Error("Encrypted patient data is missing necessary elements (IV or ciphertext).");
      }

      const decryptedData = await decryptPatientData(encryptedPayload);
      const { firstName, lastName, dob, gender } = decryptedData.patient;
      const { RealtimePayerID, memberId, name } = decryptedData.payers;

      let payerID = RealtimePayerID;

      // If no RealtimePayerID in the patient's doc, search for it in the Payers collection
      if (!payerID) {
        const payersCollectionRef = collection(db, 'Payers');
        const payerQuery = query(payersCollectionRef, where('payerName', '==', name), limit(1));
        const payerQuerySnapshot = await getDocs(payerQuery);
        if (!payerQuerySnapshot.empty) {
          const payerDoc = payerQuerySnapshot.docs[0].data();
          payerID = payerDoc.RealtimePayerID;
        }
      }

      if (!payerID) {
        throw new Error(`No RealtimePayerID found for payer: ${name}`);
      }

      const formattedDob = formatDate(dob);
      const [year, month, day] = dos.split('-');
      const formattedDos = `${month}/${day}/${year}`;
      const payersArray = [{ tradingPartnerId: payerID, name: name }];

      if (!hasRemainingRequests) {
        throw new Error("You have reached the limit of 25 API requests for the free trial.");
      }

      const eligibilitySingleAPI = httpsCallable(functions, 'eligibilitySingleAPI');
      const apiResult = await eligibilitySingleAPI({
        firstName,
        lastName,
        dob: formattedDob,
        dos: formattedDos,
        gender,
        memberId,
        payers: payersArray,
        uid,
        organizationName,
        npi,
        patientId
      });

      handleAPIResult(apiResult);
    } catch (error) {
      console.error("Error in handleRunEligibility: ", error);
      setError(`An error occurred while running eligibility check: ${error.message}`);
      setIsModalOpen(true);
    } finally {
      setLoading(false);
    }
  };

  const handleAPIResult = (result) => {
    if (result && result.data && result.data.success && result.data.responses[0].success) {
      const eligibilityData = result.data.responses[0];
      setPatientData(eligibilityData);
    } else {
      processEligibilityErrors(result);
    }
  };

  const processEligibilityErrors = (result) => {
    let errorMessages = [];

    if (result.data.errors) {
      errorMessages = result.data.errors.map(err => {
        return `${err.description} (Code: ${err.code}). Follow up action: ${err.followupAction}. Possible resolution: ${err.possibleResolutions}`;
      });
    } else if (result.data.responses) {
      result.data.responses.forEach(response => {
        if (response.error) {
          errorMessages.push(`${response.error.description}. ${response.error.details}`);
        } else if (response.errors && response.errors.length > 0) {
          response.errors.forEach(error => {
            errorMessages.push(`${error.description}. ${error.possibleResolutions}`);
          });
        }
      });
    }

    if (errorMessages.length > 0) {
      setError(`Eligibility check failed:\n${errorMessages.join('\n')}`);
    } else {
      setError("Eligibility check failed (unknown error).");
    }

    setIsModalOpen(true);
    setLoading(false);
  };

  const fetchAccountTierAndCheckRemainingRequests = async () => {
    const userDocRef = doc(db, "users", uid);
    const userDocSnap = await getDoc(userDocRef);

    const AccountTier = userDocSnap.data().AccountTier || '';

    const requestsRef = collection(db, "users", uid, "API");
    const q = query(requestsRef, orderBy("timestamp", "desc"));

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      let total = 0;
      querySnapshot.forEach((doc) => {
        const requestData = doc.data();

        if (requestData.APIRequests) {
          const apiRequestsCount = parseInt(requestData.APIRequests, 10);
          total += apiRequestsCount;
        }
      });

      if (AccountTier === 'Freebie' && total >= 25) {
        setHasRemainingRequests(false);
      } else {
        setHasRemainingRequests(true);
      }
    });

    return () => unsubscribe();
  };

  useEffect(() => {
    setPatientNotFound(false);
  }, [patientId]);

  const closeModal = () => {
    setIsModalOpen(false);
    setError(null);
  };

  return (
    <>
      <button
        onClick={handleRunEligibility}
        className="btn btn-outline-primary mb-2"
        data-toggle="tooltip"
        data-placement="top"
        title="Run Eligibility on this claim"
        disabled={loading}
      >
        <div className="icon-container">
          <FontAwesomeIcon icon={faBoltLightning} size="lg" style={{ height: '30px' }} />
        </div>
        {loading ? "Checking..." : "Instant Eligibility"}
      </button>
      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        className="confirmModal"
        overlayClassName="modal-overlay"
      >
        <button className='filesCloseButton' onClick={closeModal}>X</button>
        <h2>Error</h2>
        <div>{error || 'An unexpected error occurred'}</div>
      </Modal>
      <div className='patientDataPopupContainer' style={{ display: patientData ? 'block' : 'none' }}>
        {patientData && (
          <div className='patientDataPopupContainer'>
            <QuicksearchData patientData={patientData} onClose={() => setPatientData(null)} />
          </div>
        )}
      </div>
    </>
  );
}

export default InstantEligibility;
