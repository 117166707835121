import React from "react";
import Footer from "../subcomponents/Footer";
import Header from "../subcomponents/Header";

const PrivacyPolicyPage = () => {
  return (
    <>
      <Header />
      <div className="privacy-policy-container">
        <h1 style={{ marginBottom: '24px' }}>Welcome to Popularis</h1>
        <p>
          Protecting the privacy of our users and the integrity of the medical data we warehouse is at the core of everything we do. By engaging with our services, you entrust us with your professional and sensitive information, a responsibility we do not take lightly.
        </p>

        <h2>Information We Collect</h2>
        <p>
          The only personal information we collect on users is their name and email address. Professional information is widely collected to use the platform effectively or to join our IPA. Practice information is required.
        </p>
        <p>
          We may also collect information about your use of Popularis, such as the pages you visit, the features you use, and the time and date of your visits.
        </p>

        <h2>How We Use Your Information</h2>
        <p>
          We use your personal information to provide our services to you, including billing and eligibility services, among others. We may also use your information to communicate with you about Popularis, send you marketing materials, and improve our services.
        </p>
        <p>We do not sell or rent your personal information to third parties.</p>

        <h2>Data Security and Privacy</h2>
        <p>
          <strong>Security Measures:</strong> We implement state-of-the-art security measures that comply with the highest standards in the industry to protect your data from unauthorized access, disclosure, alteration, and destruction.
        </p>
        <p>
          <strong>Security Rule Assessments:</strong> We regularly conduct thorough Security Rule Assessments to ensure our protective measures meet and exceed industry standards.
        </p>
        <p>
          <strong>Third-Party Assessments:</strong> To ensure our security measures are without reproach, we engage reputable third-party organizations to assess our systems.
        </p>
        <p>
          <strong>Data Backup and Disaster Recovery:</strong> We have implemented multiple data backup and disaster recovery systems to ensure the swift restoration of services in the event of a disaster.
        </p>
        <p>
          <strong>HIPAA Compliance:</strong> We abide by all physical and non-physical safeguards outlined by HIPAA but also proactively exceed these standards to protect your information.
        </p>
        <p>
          <strong>Encryption and Zero Trust:</strong> Claims data, patient data, and provider data are encrypted using two separate keys to ensure maximum security. This zero trust architecture guarantees that even our staff cannot peer behind the curtain, ensuring the highest level of data privacy.
        </p>
        <p>
          <strong>Geofencing:</strong> We employ geofencing to restrict access to our services from the US, EU, and Canada, enhancing data security and compliance with regional regulations.
        </p>
        <p>
          <strong>Blocking VPNs and Dark Web Access:</strong> To further protect our users, we block access from VPNs and dark web sources.
        </p>
        <p>
          <strong>reCAPTCHA Verification:</strong> We ensure seamless v3 reCAPTCHA verification on our app and patient-facing intake forms to prevent automated abuse and enhance security.
        </p>

        <h2>Access Control Measures</h2>
        <p>
          Popularis offers the ability to add users in the nurse category which has restrictions to billing tools and information. They do not have restrictions on viewing patient information.
        </p>

        <h2>Comprehensive Audit Trails</h2>
        <p>
          Detailed logs of all activities are maintained by Popularis and can be accessed by the user for review.
        </p>

        <h2>Credit Card Information</h2>
        <p>
          We do not store your credit card information and use Stripe's APIs to ensure a seamless and secure transaction.
        </p>

        <h2>Data Integrity</h2>
        <p>
          To ensure data integrity across the system, we have added extensive validation at all points of entry for data.
        </p>

        <h2>Breach Notification</h2>
        <p>
          Popularis has, per HIPAA and HHS requirements, strict policies regarding a breach of medical data.
        </p>

        <h2>Data Retention</h2>
        <p>
          In order to comply with HHS requirements, we retain medical data for a minimum of 7 years and only allow users to soft-delete patient data.
        </p>

        <h2>Your Rights</h2>
        <p>
          You have the right to access and correct your personal information, as well as to request that we delete your personal information. You may also object to the processing of your personal information and request that we restrict the processing of your personal information.
        </p>

        <h2>Changes to this Privacy Policy</h2>
        <p>Current iteration: 8/6/2024.</p>

        <h2>Contact Us</h2>
        <p>
          Should you have any questions or concerns about our privacy practices or the security of your information, please do not hesitate to contact us at support@popularis.com.
        </p>
        <p>
          By using Popularis, you agree to this privacy policy. Popularis is HIPAA compliant and we are committed to protecting the privacy and security of your personal information.
        </p>
      </div>
      <Footer />
    </>
  );
};

export default PrivacyPolicyPage;
