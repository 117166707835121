import { createUserWithEmailAndPassword, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import React, { useState, useEffect } from "react";
import { auth, db } from "../../firebase";
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import './AUTH.css';
import { doc, setDoc } from "firebase/firestore";
import { Link } from 'react-router-dom';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { Helmet } from 'react-helmet';
import styles from "./AUTH.css";
import { collection, query, where, getDocs } from "firebase/firestore";
import AuthHexSpinner from "./AuthHexSpinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faEyeSlash, faEye } from "@fortawesome/free-regular-svg-icons";
import { faHandshakeSimple } from "@fortawesome/free-solid-svg-icons";
import NoSpaceInput from "../General/NoSpaceInput";
import googleLogo from '../../components/Website/assets/googleLogo.png';
import Smalllogo from './smalllogo';

const debounce = (func, wait) => {
  let timeout;

  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };

    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
};

const SignUp = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { token } = useParams() || {};
  const functions = getFunctions();

  const [firstName, setFirstName] = useState(localStorage.getItem('firstName') || "");
  const [lastName, setLastName] = useState(localStorage.getItem('lastName') || "");
  const [email, setEmail] = useState(localStorage.getItem('email') || new URLSearchParams(location.search).get('email') || "");
  const [password, setPassword] = useState(localStorage.getItem('password') || "");
  const [confirmPassword, setConfirmPassword] = useState(localStorage.getItem('confirmPassword') || "");
  const [role, setRole] = useState('admin'); // Default role
  const [error, setError] = useState("");
  const [agree, setAgree] = useState(localStorage.getItem('agree') === 'true');
  const [isLoading, setIsLoading] = useState(false);
  const [passwordError, setPasswordError] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  useEffect(() => {
    const script = document.createElement('script');
    script.src = `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_RECAPTCHA_SITE_KEY}`;
    script.async = true;
    document.body.appendChild(script);

    return () => document.body.removeChild(script);
  }, []);

  useEffect(() => {
    validatePassword(password);
  }, [password]);

  useEffect(() => {
    localStorage.setItem('firstName', firstName);
    localStorage.setItem('lastName', lastName);
    localStorage.setItem('email', email);
    localStorage.setItem('password', password);
    localStorage.setItem('confirmPassword', confirmPassword);
    localStorage.setItem('agree', agree);
  }, [firstName, lastName, email, password, confirmPassword, agree]);

  useEffect(() => {
    const fetchInviteData = async () => {
      if (token) {
        const tokenQuery = query(collection(db, 'UserInviteTokens'), where('token', '==', token));
        const tokenQuerySnapshot = await getDocs(tokenQuery);
        if (!tokenQuerySnapshot.empty) {
          const inviteData = tokenQuerySnapshot.docs[0].data();
          setEmail(inviteData.email);
          setRole(inviteData.role);
        }
      }
    };
    fetchInviteData();
  }, [token]);

  const validatePassword = debounce((password) => {
    if (password.length >= 5) {
      const passwordRegex = /^(?=.*\d)(?=.*[A-Z]).{8,}$/;
      if (!passwordRegex.test(password)) {
        setPasswordError("Password must be at least 8 characters long, contain at least one uppercase letter, and at least one number");
      } else {
        setPasswordError("");
      }
    } else {
      setPasswordError("");
    }
  }, 500);

  const gtagSendEvent = (url) => {
    var callback = function () {
      if (typeof url === 'string') {
        window.location = url;
      }
    };
    gtag('event', 'conversion_event_signup', {
      'event_callback': callback,
      'event_timeout': 2000,
    });
    return false;
  };

  const HandlesignUp = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    if (!emailRegex.test(email)) {
      setError("Invalid email format.");
      setIsLoading(false);
      return;
    }

    if (passwordError) {
      setError(passwordError);
      setIsLoading(false);
      return;
    }

    const getGeolocation = async () => {
      const verifyGeolocation = httpsCallable(functions, 'verifyGeolocation');
      return verifyGeolocation();
    };

    try {
      const geolocationResult = await getGeolocation();

      if (geolocationResult.data.authenticated) {
        const passwordRegex = /^(?=.*\d)(?=.*[A-Z]).{8,}$/;
        if (!passwordRegex.test(password)) {
          setError("Password must be at least 8 characters long, contain at least one uppercase letter, and at least one number.");
          setIsLoading(false);
          return;
        }

        try {
          const userCredential = await createUserWithEmailAndPassword(auth, email, password);
          const userUID = userCredential.user.uid;

          await logSignUp(userUID, geolocationResult);

          if (token) {
            await handleSubUserSignUp(token, userCredential, firstName, lastName, email, geolocationResult);
          } else {
            await handleRegularSignUp(userCredential, userUID, firstName, lastName, email);
          }

          gtagSendEvent("/home");
        } catch (error) {
          console.error("Error during account creation:", error);
          switch (error.code) {
            case 'auth/email-already-in-use':
              setError("Email already in use. Please try another email.");
              break;
            case 'auth/weak-password':
              setError("Password is too weak.");
              break;
            case 'auth/invalid-email':
              setError("Invalid email format.");
              break;
            default:
              setError("Error creating account. Please try again.");
              break;
          }
          setIsLoading(false);
        }
      } else {
        setError("Access denied based on your location or VPN usage. Please contact support@popularishealth.com to get your IP whitelisted.");
        setIsLoading(false);
      }
    } catch (error) {
      console.error("SignUp Error: ", error);
      setError("Error during sign-up. Please try again.");
      setIsLoading(false);
    }
  };

  const handleRegularSignUp = async (userCredential, userUID, firstName, lastName, email) => {
    await setDoc(doc(db, "users", userCredential.user.uid), {
      firstName,
      lastName,
      email,
      AccountTier: "Freebie",
      role: role,
    });

    sessionStorage.setItem('parentUID', userCredential.user.uid);
    sessionStorage.setItem('effectiveUID', userCredential.user.uid);

    const accountStartDatabaseFn = httpsCallable(functions, 'accountStartDatabase');
    try {
      await accountStartDatabaseFn({ uid: userUID });
    } catch (error) {
      console.error('Error populating Firestore data: ', error);
    }

    navigate("/home");

    const sendVerificationEmailFn = httpsCallable(functions, 'sendVerificationEmail');
    try {
      const verificationData = { email, uid: userUID, firstName, lastName };
      await sendVerificationEmailFn(verificationData);
    } catch (error) {
      console.error('Error sending verification email: ', error);
    }

    const createStripeCustomerFn = httpsCallable(functions, 'createStripeCustomer');
    try {
      const user = { uid: userUID, email };
      await createStripeCustomerFn(user);
    } catch (error) {
      console.error('Error sending stripe: ', error);
    }

    const newUserNotificationFn = httpsCallable(functions, 'newUserNotification');
    try {
      const user = { firstName, lastName, email };
      const result = await newUserNotificationFn(user);
    } catch (error) {
      console.error('Error sending notification:', error);
    }
  };

  const handleSubUserSignUp = async (token, userCredential, firstName, lastName, email, geolocationResult) => {
    const tokenQuery = query(collection(db, 'UserInviteTokens'), where('token', '==', token));
    const tokenQuerySnapshot = await getDocs(tokenQuery);
    if (tokenQuerySnapshot.empty) {
      setError("Invalid invitation token.");
      setIsLoading(false);
      return;
    }

    const inviterUID = tokenQuerySnapshot.docs[0].data().uid;
    const role = tokenQuerySnapshot.docs[0].data().role;

    await setDoc(doc(db, "users", inviterUID, "subusers", userCredential.user.uid), {
      firstName: firstName,
      lastName: lastName,
      email: email,
      parentUID: inviterUID,
      role: role
    });

    sessionStorage.setItem('effectiveUID', userCredential.user.uid);
    sessionStorage.setItem('parentUID', inviterUID);

    await logSignUp(userCredential.user.uid, geolocationResult, email);  // Log subuser sign-up

    navigate("/home");
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleNameChange = (setter) => (e) => {
    const value = e.target.value.replace(/\s+/g, '');
    setter(value.charAt(0).toUpperCase() + value.slice(1));
  };

  const logSignUp = async (userUID, verifyGeolocationResult, email) => {
    const city = verifyGeolocationResult.data.fullGeoIPData?.city?.names?.en || "Unknown city";
    const country = verifyGeolocationResult.data.fullGeoIPData?.country?.names?.en || "Unknown country";
    const state = verifyGeolocationResult.data.fullGeoIPData?.subdivisions?.[0]?.names?.en || "Unknown state";
    const postalCode = verifyGeolocationResult.data.fullGeoIPData?.postal?.code || "Unknown postal code";
    const latitude = verifyGeolocationResult.data.fullGeoIPData?.location?.latitude;
    const longitude = verifyGeolocationResult.data.fullGeoIPData?.location?.longitude;
    const isp = verifyGeolocationResult.data.fullGeoIPData?.traits?.isp;
    const connectionType = verifyGeolocationResult.data.fullGeoIPData?.traits?.connectionType;
    const ipAddress = verifyGeolocationResult.data.ip || "";
    const currentTime = new Date().toISOString();

    const logMessage = email
      ? `${email} (subuser) signed up and created account from ${city}, ${state}, ${country} (IP: ${ipAddress}, Postal Code: ${postalCode}, Lat/Long: ${latitude},${longitude}, ISP: ${isp}, Connection Type: ${connectionType}). Sign-Up Time: ${currentTime}`
      : `User signed up and created account from ${city}, ${state}, ${country} (IP: ${ipAddress}, Postal Code: ${postalCode}, Lat/Long: ${latitude},${longitude}, ISP: ${isp}, Connection Type: ${connectionType}). Sign-Up Time: ${currentTime}`;

    const addLogFunction = httpsCallable(functions, 'addLog');
    await addLogFunction({
      uid: userUID,
      message: logMessage,
      loginDetails: JSON.stringify(verifyGeolocationResult.data)
    });
  };

  const handleGoogleSignUp = async () => {
    setIsLoading(true);
    try {
      const provider = new GoogleAuthProvider();
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      const userUID = user.uid;

      const geolocationResult = await httpsCallable(functions, 'verifyGeolocation')();

      await logSignUp(userUID, geolocationResult);

      if (token) {
        await handleSubUserSignUp(token, { user }, user.displayName.split(' ')[0], user.displayName.split(' ')[1], user.email, geolocationResult);
      } else {
        await handleRegularSignUp({ user }, userUID, user.displayName.split(' ')[0], user.displayName.split(' ')[1], user.email);
      }

      gtagSendEvent("/home");
    } catch (error) {
      console.error("Google Sign-Up Error: ", error);
      setError("Error during Google sign-up. Please try again.");
      setIsLoading(false);
    }
  };

  return (
    <>
      <Helmet>
        <title>Sign Up | Popularis Health</title>
        <meta name="description" content="Create your account on Popularis Health and start accessing our suite of medical tools. Join us today!" />
        <meta name="keywords" content="Sign Up, Create Account, Medical Tools, Medical Billing Software, Telehealth tools, Healthcare Billing, Clinic Management, Patient Management, AI in Healthcare, Popularis Health" />
        <meta property="og:title" content="Sign Up | Popularis Health" />
        <meta property="og:description" content="Create your account on Popularis Health and start accessing our suite of medical tools. Join us today!" />
        <meta property="og:image" content="https://firebasestorage.googleapis.com/v0/b/popularishealth.appspot.com/o/Popularis_logo_single.png?alt=media&token=e079bdf2-360b-42da-9ef2-1cdbdeb474cf" />
        <meta property="og:url" content="https://popularishealth.com/signup" />
      </Helmet>

      <div className="background">
        <div className="sign-up-background">
        <div className="signup-container">
          <form onSubmit={HandlesignUp}>
          <div>
            <Smalllogo />
          </div>
            <h1 className="heading">You are moments away from being unstopable.</h1>
            <p className="signup-subheading">Get started - it's free. No credit card needed.</p>
            <div className="customsignup-grid">
                {/* First Name */}
                <div className="input-field customsignup-grid-item">
                  <label htmlFor="firstName">First Name:</label>
                  <NoSpaceInput
                    type="text"
                    value={firstName}
                    onChange={handleNameChange(setFirstName)}
                    style={{ minWidth: '15rem' }}
                    className={styles["input-field"]}
                    required
                  />
                </div>

                {/* Last Name */}
                <div className="input-field customsignup-grid-item">
                  <label htmlFor="lastName">Last Name:</label>
                  <NoSpaceInput
                    type="text"
                    value={lastName}
                    onChange={handleNameChange(setLastName)}
                    style={{ minWidth: '15rem' }}
                    className={styles["input-field"]}
                    required
                  />
                </div>

                {/* Email */}
                <div className="input-field customsignup-grid-item">
                  <label htmlFor="email">Email:</label>
                  <NoSpaceInput
                    type="email"
                    placeholder=""
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className={styles["input-field"]}
                    style={{ minWidth: '15rem' }}
                    required
                  />
                </div>

                {/* Password */}
                <div className="input-field customsignup-grid-item">
                  <label htmlFor="password">Password:</label>
                  <input
                    id="Password"
                    type={showPassword ? 'text' : 'password'}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                    style={{ paddingRight: '40px', Width: '8rem' }} // Make room for the icon
                  />
                  <div className='eye-slash-container'>
                    <FontAwesomeIcon className='eye-slash' icon={showPassword ? faEye : faEyeSlash} onClick={togglePasswordVisibility} />
                  </div>
                </div>
              </div>

            <FontAwesomeIcon className='handshake-icon' icon={faHandshakeSimple} />
            {/* <p className="sign-up-legal-disclaimer">
              Your Popularis information is used to allow you to sign in securely and access your data. 
              Your patient and claims data is encrypted and backed up. Your user information is never shared. 
              See how your data in our <a href="/privacy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>.
              By creating an account, you agree to our <a href="/terms" target="_blank" rel="noopener noreferrer">Terms of Service</a>.
            </p> */}
            {passwordError && <p className="error-message">{passwordError}</p>}
            {error && <p className="error-message">{error}</p>}
            {isLoading ? (
              <>
                <AuthHexSpinner />
                <p className="authMessage">Creating Account...</p>
              </>
            ) : (
              <>
                <button type="submit" style={{ marginTop: "1rem", textAlign: "center" }} className="signup-btn">Sign Up</button>
                
                <p>Already have an account? <Link to="/signin" className="signin-btn">Sign In</Link></p>
                <div className="divider-wrapper">
                        <hr className="divider-line" />
                        <span className="divider-text">or</span>
                        <hr className="divider-line" />
                      </div>                <button type="button" className="signInGoogle-button" onClick={handleGoogleSignUp}>
                <img src={googleLogo} alt="Google Logo" className="google-logo" />
                  Continue with Google
                </button>
              </>
            )}
              <div className="links-signup">
            <a href="/privacy">Privacy Policy</a> <a> | </a>
            <a href="/terms">Terms and Conditions</a>
          </div>
          </form>
        </div>
      </div>
      </div>
    </>
  );
};

export default SignUp;
