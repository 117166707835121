import React from "react";
import Footer from "../subcomponents/Footer";
import Header from "../subcomponents/Header";

const TermsOfUsePage = () => {
  return (
    <>
      <Header />
      <div className="privacy-policy-container">
        <h1 style={{ marginBottom: '24px' }}>Terms of Service for Popularis Health</h1>
        <p>
          Thank you for using Popularis Health, a web application designed for large-volume medical clients such as hospitals, labs, and large clinics. To ensure the proper use of our service, we require all users to agree to the following terms of service and business associate agreement (BAA):
        </p>
        <h2>Use of Service</h2>
        <p>
          By using Popularis, you agree to use the service and/or platform for its intended purposes only. You agree not to use the service for any unlawful, fraudulent, or malicious activity.
        </p>
        <h2>Security and Privacy</h2>
        <p>
          Popularis is committed to protecting your privacy and the confidentiality of your data. We use industry-leading security measures to protect your data, and we comply with all relevant data protection laws. As a medical client, you acknowledge that you are a covered entity or business associate under HIPAA and that Popularis is a business associate. As such, you agree to enter into a BAA with us to ensure compliance with HIPAA regulations.
        </p>
        <h2>Billing and Payment</h2>
        <p>
          Popularis provides batch billing, eligibility, and claims status services. You agree to pay any applicable fees, maintain accurate billing information, and authorize daily credit card billing based on your usage. You will be billed for all requests made through your account, regardless of the outcome. Cancellations must occur before the next billing cycle to avoid charges, with no refunds for partial billing cycles or unused services.
        </p>
        <h2>Third-Party Payer Downtime</h2>
        <p>
          Popularis connects with multiple third-party payers for eligibility, claims, and claims status information. We strive to communicate any known issues swiftly to mitigate their impact on your operations.
        </p>
        <h2>Intellectual Property</h2>
        <p>
          The Popularis web application, its content, and any associated trademarks and copyrights are the property of Popularis. You're granted a non-exclusive, revocable license to use the service and materials for your internal business purposes, subject to these Terms.
        </p>
        <h2>Limitation of Liability</h2>
        <p>
          Popularis and its affiliates won’t be liable for any indirect, incidental, special, consequential, or punitive damages, including loss of profits, data, or other intangible losses, arising from your use or inability to use the services.
        </p>
        <h2>Indemnification</h2>
        <p>
          You agree to indemnify and hold Popularis harmless from any claims, damages, or expenses arising from your use of our service.
        </p>
        <h2>Termination</h2>
        <p>
          You may cancel your account at any time, with cancellation taking effect at the end of the current billing cycle. Popularis may also suspend or terminate your account for unauthorized use or non-compliance with the Terms.
        </p>
        <h2>Business Associate Agreement (BAA)</h2>
        <p>
          The BAA establishes how Popularis will comply with HIPAA regulations as a business associate. It details both Popularis's and the medical client's obligations regarding the use, disclosure, and protection of PHI.
        </p>
        <p>
          By using Popularis, you agree to these terms of service and the business associate agreement. If you have any questions, please contact us at support@popularishealth.com.
        </p>
      </div>
      <Footer />
    </>
  );
};

export default TermsOfUsePage;
