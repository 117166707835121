import React from "react";
import { Droppable } from "react-beautiful-dnd";
import PaletteCard from "../FormElements/PaletteCard";

const Palette = ({ onElementAdd, fieldDefinitions, saveFormData, formElementsDataCount, preview=false }) => {
  const fields = Object.entries(fieldDefinitions);
  return (
    <div className={(preview) ? "PaletteContainerPreview" : "PaletteContainer" }>
      <div className="PaletteDroppable">
        <Droppable droppableId="palette-droppable">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {fields.map(
                ([key, typeData]) => (
                  <PaletteCard
                    key={typeData?.type}
                    typeData={typeData}
                    onAdd={onElementAdd}
                    preview={preview}
                  />
                )
              )}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </div>
      <button className={`${(preview ? `SaveButtonPreview` : `SaveButton`)} w-100 ${!formElementsDataCount && `disabled`}`} disabled={!formElementsDataCount} onClick={() => saveFormData()}> Save</button>
    </div>
  );
};

export default Palette;