import React from 'react';
import Slider from 'react-slick';
import { useNavigate } from 'react-router-dom';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Import CSS for react-slick and your custom styles
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './ArticleCarousel.css'; // Ensure this path is correct

// Import images for articles
import articleImage1 from '../assets/articleImage1.jpg';
import articleImage2 from '../assets/articleImage2.jpg';
import articleImage3 from '../assets/articleImage3.jpg';
import articleImage4 from '../assets/articleImage4.jpg';
import articleImage5 from '../assets/articleImage5.jpg';

const articles = [
  {
    title: "How AI is Revolutionizing Medical Billing",
    url: "/article/How-AI-is-Revolutionizing-Medical-Billing--Introducing-Popularis--Healthcare-AI",
    image: articleImage1,
    excerpt: "Discover how AI is transforming the medical billing landscape with Popularis' innovative solutions.",
  },
  {
    title: "Navigating the Complexity of Healthcare Coding: Challenges and Solutions",
    url: "/article/Navigating-the-Complexity-of-Healthcare-Coding--Challenges-and-Solutions",
    image: articleImage2,
    excerpt: "Learn about the challenges in healthcare coding and how to overcome them effectively.",
  },
  {
    title: "Recovering Lost Revenue in Healthcare with Batch Eligibility Checks",
    url: "/article/Recovering-Lost-Revenue-in-Healthcare-with-Batch-Eligibility-Checks",
    image: articleImage3,
    excerpt: "Find out how batch eligibility checks can help recover lost revenue in healthcare.",
  },
  {
    title: "The Anatomy of Medical Billing",
    url: "/article/The-Anatomy-of-Medical-Billing",
    image: articleImage4,
    excerpt: "Understand the fundamental components of medical billing and their importance.",
  },
  {
    title: "Navigating the Healthcare Information Maze: The Perils of Search Engines and Research Articles",
    url: "/article/Navigating-the-Healthcare-Information-Maze--The-Perils-of-Search-Engines-and-Research-Articles",
    image: articleImage5,
    excerpt: "Explore the challenges of finding reliable healthcare information online.",
  },
];

const ArticleCarousel = () => {
    const navigate = useNavigate();
  
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      autoplay: true, // Enabled autoplay
      autoplaySpeed: 5000, // Slide duration in milliseconds
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: true,
    };
  
    const handleArticleClick = (url) => {
      navigate(url);
    };
  
    return (
      <section className="articles-carousel-section">
        <div className="golden-container">
          <h2 className="section-title">Featured Articles</h2>
          <Slider {...settings}>
            {articles.map((article, index) => (
              <div className="article-slide" key={index}>
                <div className="article-content">
                  <img src={article.image} alt={article.title} className="article-image" />
                  <div className="article-text">
                    <h3 className="article-title">{article.title}</h3>
                    <p className="article-excerpt">{article.excerpt}</p>
                    <button
                      className="read-more-button"
                      onClick={() => handleArticleClick(article.url)}
                    >
                      Read More <FontAwesomeIcon icon={faArrowRight} />
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </section>
    );
  };
  
  export default ArticleCarousel;