import React, { useState, useEffect } from "react";
import HexagonSpinner from "../General/Animations/Hexspinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrash, faEdit } from "@fortawesome/free-solid-svg-icons";
import { faHeart as faHeartSolid } from "@fortawesome/free-solid-svg-icons";
import { faHeart as faHeartRegular } from "@fortawesome/free-regular-svg-icons";
import { db } from "../../firebase";
import ProviderForm from "./ProviderForm";
import { useProviderContext } from "../../context/ProviderContext";
import {
  collection,
  getDocs,
  doc,
  updateDoc,
} from "firebase/firestore";
import POSCodeIcon from "./POSCodeIcon";
import useUID from "../General/useUID";
import Modal from "react-modal";
import { Button } from "react-bootstrap";
import { getFunctions, httpsCallable } from 'firebase/functions';

const functions = getFunctions();

function ProviderSelection(props) {
  const { onProviderSelect, selectedProviderIdexternal } = props;

  // Try to use providers from context, if available
  const context = useProviderContext();
  const isStandalone = !context || !context.providers;

  // Use local states
  const [localProviders, setLocalProviders] = useState([]);
  const providers = isStandalone ? localProviders : context.providers;
  const setProviders = isStandalone ? setLocalProviders : context.updateProviders;
  const [selectedProviderId, setSelectedProviderId] = useState(null);
  const [showProviderForm, setShowProviderForm] = useState(false);
  const [editingProvider, setEditingProvider] = useState(null);
  const [gettingProviders, setGettingProviders] = useState(false);
  const [uid] = useUID();
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [providerToDelete, setProviderToDelete] = useState(null);
  const [defaultProviderId, setDefaultProviderId] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);

  useEffect(() => {
    // Set the external ID when it arrives
    if (selectedProviderIdexternal !== null) {
      setSelectedProviderId(selectedProviderIdexternal);
    }
  }, [selectedProviderIdexternal]);

  useEffect(() => {
    if (!uid || !isStandalone) return; // Fetch only if standalone
    fetchProviders();
  }, [uid, isStandalone]);

  // Initialize Firestore database reference
  const providersRef = collection(db, `users/${uid}/providers`);

  useEffect(() => {
    if (!uid) return;
    fetchProviders();
  }, [uid]);

  const fetchProviders = async () => {
    setGettingProviders(true);
    const querySnapshot = await getDocs(providersRef);
    let providersData = [];
    let defaultProviderId = null;
  
    const decryptPromises = querySnapshot.docs.map(async (doc) => {
      const data = doc.data();
      const encryptedPayload = { ciphertext: data.ciphertext, iv: data.iv };
  
      try {
        const decrypt = httpsCallable(functions, 'decrypt');
        const result = await decrypt(encryptedPayload);
  
        if (result.data) {
          const provider = {
            id: doc.id,
            ...result.data,
            isDefault: data.isDefault,
          };
          return provider;
        } else {
          console.error(`Decryption result has no data for provider ID: ${doc.id}`);
        }
      } catch (error) {
        console.error(`Error decrypting provider data for provider ID: ${doc.id}`, error);
      }
      return null;
    });
  
    try {
      const decryptedProviders = await Promise.all(decryptPromises);
      providersData = decryptedProviders.filter(provider => provider && !provider.deleted);
      
      setProviders(providersData);
  
      if (providersData.length > 0) {
        defaultProviderId = providersData.find(provider => provider.isDefault)?.id || null;
        setSelectedProviderId(defaultProviderId);
        const defaultProvider = providersData.find(p => p.id === defaultProviderId);
        if (defaultProvider) {
          onProviderSelect(defaultProvider);
        }
      } else {
        setSelectedProviderId(null);
      }
    } catch (error) {
      console.error('Error processing providers:', error);
    }
  
    setGettingProviders(false);
  };
  
  

  // Function to initiate editing of a provider
  const handleEdit = (e, provider) => {
    e.stopPropagation();
    setEditingProvider(provider);
    setShowProviderForm(true);
  };

  const handleAddNewProvider = () => {
    setEditingProvider(null); // No provider means it's a new provider
    setShowProviderForm(true);
  };

  // Handle selection of existing provider
  const handleSelect = (providerId) => {
    if (!selectedProviderId || selectedProviderId !== providerId) {
      setSelectedProviderId(providerId);
      // Find the selected provider from the providers array
      const provider = providers.find((provider) => provider.id === providerId);
      // Call the parent component's function with the selected provider's data
      const flattenedProviderData = {
        providerId: provider.id,
        organizationName: provider.organizationName,
        firstName: provider.firstName,
        lastName: provider.lastName,
        npi: provider.npi,
        ein: provider.ein,
        address1: provider.address1,
        address2: provider.address2,
        city: provider.city,
        state: provider.state,
        zip: provider.zip,
        posCode: provider.posCode,
        cliaNumber: provider.cliaNumber,
        medicareNumber: provider.medicareNumber,
        medicaidNumber: provider.medicaidNumber,
        phone: provider.phone,
      };
      onProviderSelect(flattenedProviderData);
      return;
    }

    // Deselect
    setSelectedProviderId(null);
    onProviderSelect(null);
  };

  const handleDelete = async (e, provider) => {
    e.stopPropagation();
    setProviderToDelete(provider);
    setShowConfirmModal(true);
  };

  const confirmDelete = async () => {
    if (!providerToDelete || !providerToDelete.id) {
      return;
    }
  
    setIsDeleting(true);  // Disable the delete button
  
    try {
      const encrypt = httpsCallable(functions, 'encrypt');
      const encryptedDeleteFlag = await encrypt({ deleted: true });
  
      if (!encryptedDeleteFlag.data) {
        console.error('Failed to encrypt delete flag.');
        return;
      }
  
      const encryptedData = {
        deleted: encryptedDeleteFlag.data.ciphertext,
        iv: encryptedDeleteFlag.data.iv
      };
  
      await updateDoc(doc(db, `users/${uid}/providers`, providerToDelete.id), encryptedData);
    } catch (error) {
      console.error('Error updating delete flag:', error);
    } finally {
      setIsDeleting(false);  // Re-enable the delete button
    }
  
    setShowConfirmModal(false);
    fetchProviders();
    setProviderToDelete(null);
  };
  

  const cancelDelete = () => {
    setShowConfirmModal(false);
    setProviderToDelete(null);
  };

  const handleProviderFormClose = (isNewProviderAdded) => {
    setShowProviderForm(false);
    if (isNewProviderAdded) {
      if (isStandalone) {
        fetchProviders(); // For standalone, re-fetch providers
      } else {
        context.updateProviders(); // For context, call the context's update function
      }
    }
  };
  

  const handleDefaultProviderChange = async (e, providerId) => {
    e.preventDefault(); // Prevent form submission
    e.stopPropagation(); // Stop event propagation

    try {
      // Fetch all providers to update the default status
      const querySnapshot = await getDocs(providersRef);

      // Perform updates individually for better error handling
      for (const docSnapshot of querySnapshot.docs) {
        const isDefault = docSnapshot.id === providerId;
        const providerDocRef = doc(db, `users/${uid}/providers`, docSnapshot.id);
        await updateDoc(providerDocRef, { isDefault });
      }
      await fetchProviders();
      setDefaultProviderId(providerId); // Set the new default provider in the state
    } catch (error) {
      console.error("Error updating default provider:", error);
    } finally {
      setGettingProviders(false); // Reset UI state
    }
  };

  return (
    <>
      <Modal
        isOpen={showProviderForm}
        onRequestClose={cancelDelete}
        className="AddProviderModal"
        overlayClassName="ProviderAddModalOverlay"
      >
        <ProviderForm
          provider={editingProvider}
          onClose={(isNewProviderAdded) =>
            handleProviderFormClose(isNewProviderAdded)
          }
        />
      </Modal>
      <div className="grid-container w-100">
        {gettingProviders ? (
          <div className="grey-card  w-100 content-center py-4">
            <HexagonSpinner />
            <h4>Decrypting...</h4>
          </div>
        ) : (
          providers.map((provider) => (
            <div
              className={
                (provider.id === selectedProviderId
                  ? "blue-card card-selected"
                  : "white-card") + " card w-100 content-center py-4"
              }
              onClick={() => handleSelect(provider.id)}
              key={provider.id}
            >
              <h5>
                <POSCodeIcon posCode={provider.posCode} />{" "}
                {/* Use the POSCodeIcon component */}
              </h5>
              <h5>{provider.organizationName}</h5>
              <h5>
                {provider.firstName} {provider.lastName}
              </h5>
              <h5>NPI: {provider.npi}</h5>
              <h5>Tax ID: {provider.ein}</h5>
              <h5>Phone: {provider.phone}</h5>
              <h5>
                {provider.address1}
                {provider.address2 ? ` ${provider.address2}` : ""}
                {provider.city ? `, ${provider.city}` : ""}
                {provider.state ? `, ${provider.state}` : ""}
                {provider.zip ? ` ${provider.zip}` : ""}
              </h5>
              <div className="button-group">
                <button
                  onClick={(e) => handleDefaultProviderChange(e, provider.id)}
                  className={provider.id === defaultProviderId ? "primary" : "secondary"}
                >
                  <FontAwesomeIcon
                    icon={provider.id === defaultProviderId ? faHeartSolid : faHeartRegular}
                  />
                </button>

                <button
                  onClick={(e) => handleEdit(e, provider)}
                  className={
                    provider.id === selectedProviderId ? "primary" : "secondary"
                  }
                >
                  <FontAwesomeIcon icon={faEdit} />
                </button>
                <button
                  onClick={(e) => handleDelete(e, provider)}
                  className={
                    provider.id === selectedProviderId ? "primary" : "secondary"
                  }
                  disabled={isDeleting}  // Disable the delete button if deletion is in progress
                >
                  <FontAwesomeIcon icon={faTrash} />
                </button>
              </div>
            </div>
          ))
        )}

        <div
          className="white-card w-100 h-250px content-center py-4"
          onClick={handleAddNewProvider}
        >
          <FontAwesomeIcon icon={faPlus} style={{ height: "32px" }} />
          <h4>Add a Provider</h4>
        </div>
      </div>

      <Modal
        isOpen={showConfirmModal}
        onRequestClose={cancelDelete}
        className="confirmModal"
        overlayClassName="confirmModalOverlay"
      >
        <h2>
          Delete "{providerToDelete?.firstName} {providerToDelete?.lastName}"?
        </h2>
        <p>Are you sure you want to delete this provider?</p>
        <div className="confirmButtons">
          <Button variant="secondary" onClick={cancelDelete}>
            Cancel
          </Button>
          <Button variant="danger" onClick={confirmDelete}>
            Delete
          </Button>
        </div>
      </Modal>
    </>
  );
}

export default React.memo(ProviderSelection);
