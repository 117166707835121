import { auth, db } from "../../../firebase";
import { useState, useEffect } from "react";
import { collection, query, where, getDocs, getDoc, onSnapshot, doc, deleteDoc } from "firebase/firestore";
import { getFunctions, httpsCallable } from 'firebase/functions';
import Topbar from "../Topbar";
import HexagonSpinner from "../Animations/Hexspinner";
import useUID from '../useUID';

function Users() {
    const [subusers, setSubusers] = useState([]);
    const [showAddForm, setShowAddForm] = useState(false);
    const [inviteEmail, setInviteEmail] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [roleType, setRoleType] = useState("Nurse"); // default value
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [userTier, setUserTier] = useState(''); // Add user's tier here
    const [isLoading, setIsLoading] = useState(false); // Added for loading state
    const [uid, subUserUID, error] = useUID();

    useEffect(() => {
        if (!uid) {
            console.log("No UID found");
            return;
        }

        console.log("UID found:", uid);

        const subusersQuery = query(
            collection(db, "users", uid, "subusers"),
            where("parentUID", "==", uid)
        );

        const unsubscribe = onSnapshot(subusersQuery, (snapshot) => {
            const fetchedSubusers = snapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
            console.log("Subusers fetched:", fetchedSubusers);
            setSubusers(fetchedSubusers);
        });

        return () => unsubscribe();
    }, [uid]);

    const sendInvite = async (email, firstName, lastName, role) => {
        setErrorMessage('');
        setSuccessMessage('');
        setIsLoading(true);

        console.log("sendInvite called with params:", { email, firstName, lastName, role, uid });

        if (!email || !firstName || !lastName) {
            console.error('Validation failed: Missing fields');
            setErrorMessage('All fields are required.');
            setIsLoading(false);
            return;
        }

        if (userTier === 'Professional' && subusers.length >= 1) {
            console.error('User tier limit exceeded');
            setErrorMessage('You can only add 1 additional user with your current tier.');
            setIsLoading(false);
            return;
        }

        const functions = getFunctions();
        const sendInviteFunc = httpsCallable(functions, 'sendInvite');

        try {
            console.log("Sending invite via Firebase Function...");
            const result = await sendInviteFunc({
                email,
                firstName,
                lastName,
                role: role.toLowerCase(),
                uid // Explicitly pass the current user's UID
            });
            console.log("Invite sent successfully:", result);
            setSuccessMessage('Invitation sent successfully! Please check your spam.');
            setInviteEmail('');
            setFirstName('');
            setLastName('');
        } catch (error) {
            console.error('Error sending invitation:', error);
            setErrorMessage('Error sending invitation.');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div>
            <div className='background'>
                <Topbar />
                <div style={{ justifyContent: "normal" }} className="settings-content contentContainer">
                    <h2>Manage Users</h2>

                    {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
                    {successMessage && <p style={{ color: 'green' }}>{successMessage}</p>}

                    {isLoading ? (
                        <HexagonSpinner /> // Show spinner when loading
                    ) : (
                        <>
                            <button onClick={() => setShowAddForm(true)}>Add User</button>
                            
                            {showAddForm && (
                                <div>
                                    <input
                                        type="text"
                                        placeholder="First Name"
                                        value={firstName}
                                        onChange={(e) => setFirstName(e.target.value)}
                                    />
                                    <input
                                        type="text"
                                        placeholder="Last Name"
                                        value={lastName}
                                        onChange={(e) => setLastName(e.target.value)}
                                    />
                                    <input
                                        type="email"
                                        placeholder="Enter Email for Invite"
                                        value={inviteEmail}
                                        onChange={(e) => setInviteEmail(e.target.value)}
                                    />
                                    <select value={roleType} onChange={(e) => setRoleType(e.target.value)}>
                                        <option value="Nurse">Nurse</option>
                                        <option value="Provider">Provider</option>
                                        <option value="Biller">Biller</option>
                                    </select>
                                    <button onClick={() => sendInvite(inviteEmail, firstName, lastName, roleType)}>Send Invite</button>
                                    <button onClick={() => setShowAddForm(false)}>Cancel</button>
                                </div>
                            )}

                            <table>
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Email</th>
                                        <th>Role</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {subusers.map(subuser => (
                                        <tr key={subuser.id}>
                                            <td>{subuser.firstName} {' '} {subuser.lastName}</td>
                                            <td>{subuser.email}</td>
                                            <td>{subuser.role}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
}

export default Users;
