import React, { useState, useEffect } from 'react';
import { getFirestore, doc, getDoc, getDocs, collection, query, where, limit, orderBy, onSnapshot } from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlassDollar } from '@fortawesome/free-solid-svg-icons';
import useUID from '../../General/useUID';
import { storage, auth, db } from '../../../firebase';
import QuicksearchData from '../../Eligibility/EligibilityResultsViewData'

function ClaimsViewEligibility({ claimId, patientId }) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [requestData, setRequestData] = useState(null);
  const [hasRemainingRequests, setHasRemainingRequests] = useState(true);
  const [uid, subUserUID, isLoading] = useUID();
  const [patientNotFound, setPatientNotFound] = useState(false);
  const [patientData, setPatientData] = useState(null);

  function formatDate(dateStr) {
    // Check if the dateStr is exactly 8 characters (YYYYMMDD)
    if (dateStr && dateStr.length === 8) {
      const year = dateStr.substring(0, 4);
      const month = dateStr.substring(4, 6);
      const day = dateStr.substring(6, 8);
  
      // Reformat to MM/DD/YYYY
      return `${month}/${day}/${year}`;
    }
    return '';
  }

  const handleRunEligibility = async () => {
    setLoading(true);
    setError(null);
  
    const db = getFirestore();
  
    const claimDocRef = doc(db, `patients/${uid}/patientData/${patientId}/claims/${claimId}`);
  
    try {
      const claimDocSnap = await getDoc(claimDocRef);
  
      if (claimDocSnap.exists()) {
        const claimData = claimDocSnap.data().requestData;
        setRequestData(claimData);
  
        const { subscriber, claimInformation, providers, tradingPartnerName } = claimData;
        const { firstName, lastName, gender, memberId } = subscriber;
        const providerOrganizationName = providers.find(provider => provider.providerType === "BillingProvider")?.organizationName;
        const npi = providers.find(provider => provider.providerType === "RenderingProvider" || provider.providerType === "ReferringProvider")?.npi;
        
        const convertedtradingPartnerServiceId = await getConvertedTradingID(tradingPartnerName);
  
        const formattedDob = formatDate(subscriber.dateOfBirth);
        const formattedDos = formatDate(claimInformation.serviceLines[0].serviceDate);

              // Create an array with a single payer object
        const payersArray = [{
          tradingPartnerId: convertedtradingPartnerServiceId
        }];
  
        if (!hasRemainingRequests) {
          setError("You have reached the limit of 25 API requests for the free trial.");
          return;
        }
  
        const functions = getFunctions();
        const eligibilitySingleAPI = httpsCallable(functions, 'eligibilitySingleAPI');

        try {
          const result = await eligibilitySingleAPI({
            firstName,
            lastName,
            dob: formattedDob,
            dos: formattedDos,
            gender,
            memberId,
            payers: payersArray, 
            uid,
            organizationName: providerOrganizationName,
            npi,
            patientId
          });

  
          if (result && result.data && result.data.success) {
      
            if (result.data.eligibilityData) {
              setPatientData(result.data);

            } else {
              setPatientNotFound(true);
            }
          } else {
            setPatientNotFound(true);
          }
        } catch (error) {
          console.error("Error calling eligibilitySingleAPI function: ", error);
          setError(error.message);
        }
      } else {
        setError('No claim data available.');
      }
    } catch (err) {
      setError(err.message || 'An error occurred while fetching claim data.');
    } finally {
      setLoading(false);
    }
  };
  
  

  async function getConvertedTradingID(tradingPartnerName) {
    try {
      // Create a query against the Payers collection using the tradingPartnerName
      const payersRef = collection(db, 'Payers');
      const q = query(payersRef, where('payerName', '==', tradingPartnerName));

      const snapshot = await getDocs(q);
      
      if (!snapshot.empty) {
          const realtimePayerID = snapshot.docs[0].data().RealtimePayerID;
          
          if (realtimePayerID) {
              return realtimePayerID;
          } else {
              throw new Error('This payer does not support single click claim status at this time.');
          }
      } else {
          return null;
      }
  } catch (error) {
      console.error('Error fetching Realtime Payer ID:', error);
      throw error;
  }
}

  
useEffect(() => {
  const fetchAccountTierAndCheckRemainingRequests = async () => {
    const uid = auth.currentUser.uid;
    const userDocRef = doc(db, "users", uid);
    const userDocSnap = await getDoc(userDocRef);

    const AccountTier = userDocSnap.data().AccountTier || '';

    const requestsRef = collection(db, "users", uid, "API");
    const q = query(requestsRef, orderBy("timestamp", "desc"));

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      let total = 0;
      querySnapshot.forEach((doc) => {
        const requestData = doc.data();

        if (requestData.APIRequests) {
          const apiRequestsCount = parseInt(requestData.APIRequests, 10);
          total += apiRequestsCount;
        }
      });

      if (AccountTier === 'Freebie' && total >= 25) {
        setHasRemainingRequests(false);
      } else {
        setHasRemainingRequests(true);
      }
    });

    return () => unsubscribe();
  };

  if (auth.currentUser) {
    fetchAccountTierAndCheckRemainingRequests();
  }
}, [auth.currentUser]);

  
return (
  <>
  <button 
    onClick={handleRunEligibility}
    className="btn btn-outline-primary mb-2" 
    data-toggle="tooltip" 
    data-placement="top" 
    title="Run Eligibility on this claim"
    disabled={loading}
  >
<FontAwesomeIcon icon={faMagnifyingGlassDollar} style={{ marginRight: '5px' }} />
    {loading && "Checking..."}
    {
      patientNotFound && <div className='patientNotFoundMessage'>Insurance not found</div>
    }
        Run Eligibility

  </button>
  <div className='patientDataPopupContainer' style={{ display: patientData ? 'block' : 'none' }}>
    {patientData && (
      <div className='patientDataPopupContainer'>
        <QuicksearchData patientData={patientData} onClose={() => setPatientData(null)} />
      </div>
    )}
    </div>
  </>
);
}

export default ClaimsViewEligibility;


