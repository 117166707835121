import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import useUID from './useUID';
import Topbar from './Topbar';
import styles from './MainStyles.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons';
import { httpsCallable, getFunctions } from 'firebase/functions';

function Messages() {
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [uid, subUserUID] = useUID();
  const [Name, setName] = useState('');
  const messagesEndRef = useRef(null);
  const [activeSenderId, setActiveSenderId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const functions = getFunctions();
  const socketRef = useRef(null);

  useEffect(() => {
    // Determine and set the active sender ID based on whether a subUserUID is present
    setActiveSenderId(subUserUID || uid);
  }, [uid, subUserUID]);

  useEffect(() => {
    // Establish WebSocket connection
    socketRef.current = new WebSocket('ws://localhost:8080');

    socketRef.current.onopen = () => {
      console.log('WebSocket connected');
    };

    socketRef.current.onmessage = async (event) => {
      const data = JSON.parse(event.data);
      const decryptedMessage = await handleDecryptMessage(data.ciphertext, data.iv);
      if (decryptedMessage) {
        setMessages(prevMessages => [
          ...prevMessages,
          {
            id: data.id,
            message: decryptedMessage,
            senderId: data.senderId,
            senderName: data.senderName,
            timestamp: new Date(data.timestamp)
          }
        ]);
      }
    };

    socketRef.current.onclose = () => {
      console.log('WebSocket disconnected');
    };

    return () => {
      socketRef.current.close();
    };
  }, [uid]);

  const handleDecryptMessage = async (ciphertext, iv) => {
    // Decrypt the message using the provided ciphertext and iv
    const encryptedData = { ciphertext, iv };
    try {
      const decryptFunction = httpsCallable(functions, 'decrypt');
      const response = await decryptFunction(encryptedData);
      const decryptedMessage = response.data.text;
      return decryptedMessage;
    } catch (error) {
      console.error("Error decrypting message:", error);
      return null;
    }
  };

  const handleSendMessage = async (e) => {
    e.preventDefault();

    let message = newMessage;
    setNewMessage('');

    if (!message.trim() || !Name) return;
    setIsLoading(true);

    const encryptFunction = httpsCallable(functions, 'encrypt');

    try {
      const response = await encryptFunction({ text: message });
      const { ciphertext, iv } = response.data;

      const messageData = {
        ciphertext,
        iv,
        senderId: activeSenderId,
        senderName: Name,
        timestamp: Date.now(),
      };

      // Send the encrypted message through WebSocket
      socketRef.current.send(JSON.stringify(messageData));

      setIsLoading(false);
    } catch (error) {
      console.error("Error sending encrypted message: ", error);
      setIsLoading(false);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSendMessage(e);
    }
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const renderMessage = (message) => (
    <div key={message.id} className={`message ${message.senderId === activeSenderId ? 'sent' : 'received'}`}>
      {message.senderName && <div className="senderName">{message.senderName}</div>}
      <div className="messageText">{message.message}</div>
      <div className="timestamp">{message.timestamp?.toLocaleString()}</div>
    </div>
  );

  return (
    <div>
      <Topbar />
      <div className='MessageContainer'>
        <div className="messagesList">
          {messages.length === 0 ? (
            <div className="noMessages">Welcome to messages. These are HIPAA protected, encrypted messages within your organization. Start my sending a message below.</div>
          ) : (
            messages.map(renderMessage)
          )}
          <div ref={messagesEndRef} />
        </div>
        <form onSubmit={handleSendMessage} className="inputArea">
          <textarea
            className='textarea'
            value={newMessage}
            disabled={isLoading}
            onChange={(e) => setNewMessage(e.target.value)}
            placeholder="Send a message..."
            onKeyDown={handleKeyDown}
          />
          <button onClick={handleSendMessage} disabled={isLoading} className="sendButton">
            <FontAwesomeIcon icon={faArrowUp} />
          </button>
        </form>
      </div>
    </div>
  );
}

export default Messages;
