import React, { useState, useEffect } from "react";
import "../../AddPatient/AddPatient.modules.css";
import InputMask from "react-input-mask";
import StateSelector from "../../../Payers/StateSelector";

function PersonalInformation({
  requiredFields,
  UserData = {},
  onUpdateUserData,
  errors,
}) {
  const [userData, setUserData] = useState({
    firstName: "",
    middleName: "",
    lastName: "",
    gender: "",
    dob: "",
    phone: "",
    email: "",
    address: {
      address1: "",
      address2: "",
      city: "",
      state: "",
      zip: "",
    },
  });

  useEffect(() => {
    if (UserData) {
      setUserData({
        firstName: UserData.firstName || "",
        middleName: UserData.middleName || "",
        lastName: UserData.lastName || "",
        gender: UserData.gender || "",
        dob: UserData.dob || "",
        phone: UserData.phone || "",
        email: UserData.email || "",
        address: {
          address1: UserData.address?.address1 || "",
          address2: UserData.address?.address2 || "",
          city: UserData.address?.city || "",
          state: UserData.address?.state || "",
          zip: UserData.address?.zip || "",
        },
      });
    }
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const updatedUserData = { ...userData };

    if (
      name === "address1" ||
      name === "address2" ||
      name === "city" ||
      name === "state" ||
      name === "zip"
    ) {
      updatedUserData.address = {
        ...updatedUserData.address,
        [name]: value,
      };
    } else {
      updatedUserData[name] = value;
    }

    setUserData(updatedUserData);
    onUpdateUserData(updatedUserData);
  };

  const handleGenderChange = (gender) => {
    const updatedUserData = { ...userData, gender };
    setUserData(updatedUserData);
    onUpdateUserData(updatedUserData);
  };

  return (
    <>
      {/* Personal Information */}
      <div className="centerHeader mt-3">
        <h3>Patient</h3>
      </div>
      <div className="input-group-row">
        <div className="input-field">
          <label htmlFor="firstName">
            First Name
            {errors?.firstName && requiredFields.firstName && (
              <span className="RequiredMark"> *</span>
            )}
          </label>
          <input
            type="text"
            name="firstName"
            value={userData.firstName}
            onChange={handleInputChange}
            required={requiredFields.firstName}
          />
        </div>

        <div className="input-field">
          <label htmlFor="middleName">
            Middle Name
          </label>
          <input
            type="text"
            name="middleName"
            value={userData.middleName}
            onChange={handleInputChange}
          />
        </div>

        <div className="input-field">
          <label htmlFor="lastName">
            Last Name
            {errors?.lastName && requiredFields.lastName && (
              <span className="RequiredMark"> *</span>
            )}
          </label>
          <input
            type="text"
            name="lastName"
            value={userData.lastName}
            onChange={handleInputChange}
            required={requiredFields.lastName}
          />
        </div>
      </div>
      <div className="input-group">
        <label htmlFor="dob">
          Date of Birth
          {errors?.dob && requiredFields.dob && (
            <span className="RequiredMark">*</span>
          )}
        </label>
        <input
          type="date"
          name="dob"
          value={userData.dob}
          onChange={handleInputChange}
          required={requiredFields.dob}
        />

        <div className="gender-buttons">
          <label>
            Gender
            {errors?.gender && requiredFields.gender && (
              <span className="RequiredMark"> *</span>
            )}
          </label>
          <button
            type="button"
            className={`gender-button ${
              userData.gender === "M" ? "selected" : ""
            }`}
            onClick={() => handleGenderChange("M")}
          >
            M
          </button>
          <button
            type="button"
            className={`gender-button ${
              userData.gender === "F" ? "selected" : ""
            }`}
            onClick={() => handleGenderChange("F")}
          >
            F
          </button>
        </div>
      </div>
      {/* Address */}
      <div className="input-group-row">
        {/* Address 1 and 2 */}
        <div className="input-field">
          <label htmlFor="address1">
            Address line 1
            {errors?.addressLine1 && requiredFields.address && (
              <span className="RequiredMark"> *</span>
            )}
          </label>
          <input
            type="text"
            name="address1"
            value={userData.address.address1}
            onChange={handleInputChange}
            required={requiredFields.address}
          />
        </div>

        <div className="input-field">
          <label htmlFor="address2">Address line 2</label>
          <input
            type="text"
            name="address2"
            value={userData.address.address2}
            onChange={handleInputChange}
          />
        </div>
      </div>
      <div className="input-group-row">
        <div className="input-field">
          <label htmlFor="city">
            City
            {errors?.city && requiredFields.address && (
              <span className="RequiredMark"> *</span>
            )}
          </label>
          <input
            type="text"
            name="city"
            value={userData.address.city}
            onChange={handleInputChange}
            required={requiredFields.address}
          />
        </div>

        <div className="input-field">
          <label htmlFor="state">
            State
            {errors?.state && requiredFields.address && (
              <span className="RequiredMark"> *</span>
            )}
          </label>
          <StateSelector
            className="w-100"
            selectedState={userData.address.state}
            setSelectedState={(state) => {
              setUserData((prevState) => ({
                ...prevState,
                address: {
                  ...prevState.address,
                  state,
                },
              }));
            }}
          />
        </div>

        <div className="input-field">
          <label htmlFor="zip">
            Zip
            {errors?.zip && requiredFields.address && (
              <span className="RequiredMark"> *</span>
            )}
          </label>
          <input
            type="text"
            name="zip"
            maxLength="5"
            pattern="^\d{5}$"
            value={userData.address.zip}
            onChange={handleInputChange}
            className="zipInput"
            required={requiredFields.address}
          />
        </div>
      </div>

      <div className="input-group-row">
        <div className="input-field">
          <label htmlFor="email">
            Email
            {errors?.email && requiredFields.email && (
              <span className="RequiredMark"> *</span>
            )}
          </label>
          <input
            type="email"
            name="email"
            value={userData.email}
            onChange={handleInputChange}
            required={requiredFields.email}
          />
        </div>
        <div className="input-field">
          <label htmlFor="phone">
            Phone
            {errors?.phone && requiredFields.phone && (
              <span className="RequiredMark"> *</span>
            )}
          </label>
          <InputMask
            mask="(999) 999-9999"
            value={userData.phone}
            onChange={handleInputChange}
            placeholder="(000)-000-0000"
            required={requiredFields.phone}
          >
            {(inputProps) => <input {...inputProps} type="text" name="phone" />}
          </InputMask>
        </div>
      </div>
    </>
  );
}

export default PersonalInformation;