import React, { useState } from "react";
import styles from "../PatientView.module.css";
import { doc, collection, addDoc, serverTimestamp } from "firebase/firestore";
import { db } from "../../../firebase";
import useUID from "../../General/useUID";
import { getFunctions, httpsCallable } from "firebase/functions";
import HexagonSpinner from "../../General/Animations/Hexspinner";

const functions = getFunctions();
const encryptFunction = httpsCallable(functions, "encrypt");

function PatientHistory({ patientId, onClose }) {
  const [uid, subUserUID, isLoading, error] = useUID();
  const [historyRecord, setHistoryRecord] = useState({
    condition: "",
    treatment: "",
    treatmentStartDate: "",
    treatmentEndDate: "",
    doctor: "",
    notes: "",
  });
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    setHistoryRecord({
      ...historyRecord,
      [e.target.name]: e.target.value,
    });
  };

  const isFormEmpty = () => {
    return !historyRecord.condition || !historyRecord.treatment;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (isFormEmpty()) {
      alert("Please fill in at least the condition and treatment fields.");
      return;
    }

    setLoading(true);

    // Encrypt the data before sending it to Firestore
    try {
      const encryptedResponse = await encryptFunction(historyRecord);
      const encryptedData = encryptedResponse.data;

      const medicalHistoryRef = collection(
        doc(db, "patients", uid, "patientData", patientId),
        "medicalHistory"
      );

      await addDoc(medicalHistoryRef, {
        type: "PatientHistory",
        timestamp: serverTimestamp(),
        ciphertext: encryptedData.ciphertext,
        iv: encryptedData.iv,
      });

      const logData = {
        timestamp: Date.now(),
        patientId: patientId,
        activity: "Medical history record added",
        activityType: "medicalHistory",
        uid: uid,
      };

      await addDoc(collection(db, "users", uid, "activityLogs"), logData);

      setHistoryRecord({
        condition: "",
        treatment: "",
        treatmentStartDate: "",
        treatmentEndDate: "",
        doctor: "",
        notes: "",
      });
      onClose();
    } catch (error) {
      console.error("Error during encryption or adding document: ", error);
    } finally {
      setLoading(false); // Stop loading after the async operation
    }
  };

  return (
    <div className={styles.medicalHistoryForm}>
      <form onSubmit={handleSubmit}>
        <div className="input-group-row">
          <div className="input-field">
            <label htmlFor="condition">Condition:</label>
            <input
              type="text"
              id="condition"
              name="condition"
              value={historyRecord.condition}
              onChange={handleChange}
              placeholder="Enter medical condition"
            />
          </div>
          <div className="input-field">
            <label htmlFor="treatment">Treatment:</label>
            <input
              type="text"
              id="treatment"
              name="treatment"
              value={historyRecord.treatment}
              onChange={handleChange}
              placeholder="Enter treatment details"
            />
          </div>
        </div>

        <div className="input-group-row">
          <div className="input-field">
            <label htmlFor="treatmentStartDate">Treatment Start Date:</label>
            <input
              type="date"
              id="treatmentStartDate"
              name="treatmentStartDate"
              value={historyRecord.treatmentStartDate}
              onChange={handleChange}
            />
          </div>
          <div className="input-field">
            <label htmlFor="treatmentEndDate">Treatment End Date:</label>
            <input
              type="date"
              id="treatmentEndDate"
              name="treatmentEndDate"
              value={historyRecord.treatmentEndDate}
              onChange={handleChange}
            />
          </div>
        </div>

        <div className="input-group-row">
          {/* <div className='input-field'>
            <label htmlFor="doctor">Doctor:</label>
            <input
              type="text"
              id="doctor"
              name="doctor"
              value={historyRecord.doctor}
              onChange={handleChange}
              placeholder="Doctor's name"
            />
          </div> */}
          <div className="input-field">
            <label htmlFor="notes">Notes:</label>
            <textarea
              id="notes"
              name="notes"
              value={historyRecord.notes}
              onChange={handleChange}
              placeholder="Additional information"
            />
          </div>
        </div>
        {loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <HexagonSpinner />
          </div>
        ) : (
          <button
            type="submit"
            className={styles.submitButton}
            disabled={isFormEmpty()}
          >
            Add Record
          </button>
        )}
      </form>
    </div>
  );
}

export default PatientHistory;
