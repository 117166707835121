import { useState, useEffect } from "react";
import { getDatabase, ref, onValue } from "firebase/database";
import { getFunctions, httpsCallable } from "firebase/functions";
import HexagonSpinner from "../../General/Animations/Hexspinner";
import useUID from "../../General/useUID";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "../Claims.css";
import { Tooltip } from "react-tooltip";
import ClaimsStatusCards from "../Subcomponents/ClaimStatusView";
import { Navigate } from "react-router-dom";

const localizer = momentLocalizer(moment);

const ClaimsCalendar = ({ onClose }) => {
  const [loading, setLoading] = useState(true);
  const [claimsData, setClaimsData] = useState([]);
  const [uid, subUserUID] = useUID();
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [dateType, setDateType] = useState("dateSent");
  const [navigateTo, setNavigateTo] = useState(null);

  useEffect(() => {
    if (!uid) return;

    const fetchClaims = () => {
      const db = getDatabase();
      const claimsRef = ref(db, `fetchClaimsData/${uid}`); // Adjust the path as necessary

      const unsubscribe = onValue(
        claimsRef,
        (snapshot) => {
          const data = snapshot.val();
          if (data) {
            // Assuming the data is an object where keys are claim IDs and values are claim details
            const claimsArray = Object.keys(data).map((key) => ({
              ...data[key],
              id: key, // Optionally add the Firebase key as an id for each claim
            }));
            setClaimsData(claimsArray);
          } else {
            // Handle case where there is no data
            setClaimsData([]);
          }
          setLoading(false);
        },
        {
          onlyOnce: true, // Use true to fetch data only once, or remove for continuous syncing
        }
      );

      return () => unsubscribe(); // Cleanup function to unsubscribe from the listener
    };

    fetchClaims();
  }, [uid]);

  const formatClaimsForCalendar = () => {
    return claimsData.map((claim) => {
      let startDate;
      if (dateType === "serviceDate") {
        startDate = moment(
          claim.serviceLines[0]?.serviceDate,
          "YYYYMMDD"
        ).toDate();
      } else if (dateType === "dateSent") {
        startDate = new Date(claim.timestamp._seconds * 1000);
      }

      // Create an end date that is 5 minutes after the start date
      let endDate = new Date(startDate.getTime() + 5 * 60000);

      return {
        title: `${claim.firstName} ${claim.lastName} - ${claim.receiver}`,
        start: startDate,
        end: endDate,
        allDay: false,
        resource: claim,
      };
    });
  };

  const handleSelectEvent = (event) => {
    setSelectedEvent(event.resource);
  };

  const CustomEvent = ({ event }) => (
    <div>
      <span data-tip data-for={`tooltip-${event.resource.id}`}>
        {event.title}
      </span>
      <Tooltip c id={`tooltip-${event.resource.id}`} effect="solid">
        <div>
          <p>
            <strong>Receiver:</strong> {event.resource.receiver}
          </p>
          {/* Add more claim details as needed */}
        </div>
      </Tooltip>
    </div>
  );

  const handleNavigate = () => {
    if (selectedEvent && selectedEvent.claimId) {
      setNavigateTo(`/claims/${selectedEvent.claimId}`);
    }
  };

  const convertDate = (dateString) => {
    if (!dateString || dateString.length !== 8) return dateString;
    return `${dateString.substring(4, 6)}/${dateString.substring(
      6,
      8
    )}/${dateString.substring(0, 4)}`;
  };

  const EventDetails = () => {
    if (!selectedEvent) return <div>Select an event to see details.</div>;

    if (navigateTo) {
      return <Navigate to={navigateTo} />;
    }

    const {
      firstName,
      lastName,
      dateOfBirth,
      gender,
      receiver,
      provider,
      memberId,
      claimId,
      serviceLines,
      diagnosisCodes,
    } = selectedEvent;
    const formattedDateOfBirth = convertDate(dateOfBirth);
    const formattedDiagnosisCodes = diagnosisCodes?.join(", ") || "N/A";

    return (
      <div style={{ alignItems: "flex-start" }}>
        <h2>
          <strong>
            {firstName} {lastName}
          </strong>
        </h2>
        <h4>
          {formattedDateOfBirth} {"|"} {gender}
        </h4>
        <p>
          Payer: <strong>{receiver}</strong>
        </p>
        <p>
          Provider: <strong>{provider}</strong>
        </p>
        <p>
          Member ID: <strong>{memberId}</strong>
        </p>
        <p>
          Diagnosis Codes: <strong>{formattedDiagnosisCodes}</strong>
        </p>

        {serviceLines?.length > 0 && (
          <div>
            <h4>Service Lines:</h4>
            <table>
              <thead>
                <tr>
                  <th>Procedure Code</th>
                  <th>Date of Service</th>
                  <th>Charge Amount</th>
                </tr>
              </thead>
              <tbody>
                {serviceLines.map((line, index) => (
                  <tr key={index}>
                    <td>{line.professionalService.procedureCode}</td>
                    <td>{convertDate(line.serviceDate)}</td>
                    <td>${line.professionalService.lineItemChargeAmount}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}

        <button onClick={handleNavigate}>Show more details</button>
        <ClaimsStatusCards
          claimId={claimId}
          patientId={selectedEvent.patientId}
        />
      </div>
    );
  };

  if (loading) {
    return (
      <div>
        <HexagonSpinner />
        <h4>Loading claims calendar...</h4>
      </div>
    );
  }

  return (
    <div className="calendarContainer">
      <button className="filesCloseButton" onClick={onClose}>
        X
      </button>
      <div className="centerHeader">
            <h3>Claims Calendar</h3>
            <p>View claims by service or send date.</p>
          </div>
        <div className="showByDropdown">
        <label>
          Show by:
          <select
            value={dateType}
            onChange={(e) => setDateType(e.target.value)}
          >
            <option value="serviceDate">Date of Service</option>
            <option value="dateSent">Date Sent</option>
          </select>
        </label>
      </div>
      <div style={{ display: "flex" }}>
        <div>
          <Calendar
            localizer={localizer}
            events={formatClaimsForCalendar()}
            onSelectEvent={handleSelectEvent} // Attach the event handler
            startAccessor="start"
            endAccessor="end"
            style={{ height: "70vh" }}
            components={{ event: CustomEvent }}
          />
        </div>
        <div className="EventDetailsContainer">
          <EventDetails />
        </div>
      </div>
    </div>
  );
};

export default ClaimsCalendar;
