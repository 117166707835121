import React, { useState, useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from "react-router-dom";
import Header from '../subcomponents/Header';
import Footer from '../subcomponents/Footer';
import Hero from '../assets/finalHero2.mp4';
import { faHeart, faFileInvoiceDollar, faFileExcel, faStaffSnake, faLock, faHeadset, faDesktop, faClock, faCheckCircle, faPlug, faClipboardList, faGlobeAmericas, faServer, faNotesMedical } from '@fortawesome/free-solid-svg-icons'; // Importing necessary icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, useLocation } from 'react-router-dom';
import doc1 from '../assets/doc1.png';
import doc2 from '../assets/doc4.jpg';
import doc3 from '../assets/doc3.png';
import Static from '../assets/heroStatic.png';
import AllInOneSection from '../subcomponents/AllInOneSection';
import AmericanFlag from '../assets/americanFlag.png';
import ArticleCarousel from '../subcomponents/ArticlesCarousel';

const testimonials = [
  {
    image: doc1, // Use doc1 image here
    quote: "Popularis is easy to use and has reduced training times, while increasing productivity.",
    name: "Dr. James Mandelbrot"
  },
  {
    image: doc2, // Use doc2 image here
    quote: "The claims process and eligibility checks have saved us countless hours. We used to send claims one by one.",
    name: "Dr. Corey Carson"
  },
  {
    image: doc3, // Use doc3 image here
    quote: "I use the encounters system every day. I gave feedback to their team and they implimented the changes right away.",
    name: "Nurse Practitioner Alexis Crews"
  },
];


const LandingPage = () => {
  const navigate = useNavigate();
  const [isVisible, setIsVisible] = useState({});
  const sectionRefs = useRef({});
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isButtonVisible, setIsButtonVisible] = useState(false);
  const [email, setEmail] = useState(
    localStorage.getItem('email') ||
    new URLSearchParams(window.location.search).get('email') ||
    ''
  );
  const [stats, setStats] = useState([
    { value: 0, target: 99, label: 'Claims Acceptance Rate', suffix: '%' },
    { value: 60, target: 104, label: 'Claims Sent', prefix: '', suffix: 'k+' },
    { value: 30, target: 50, label: 'Eligibility Requests Made', suffix: 'k+' },
    { value: 0, target: 25, label: 'Patients Warehoused', prefix: '', suffix: 'k+' },
    { value: 0, target: 10, label: 'Charges Sent', prefix: '$', suffix: 'M+' },
    { value: 0, target: 100, label: 'Clinics Serviced', prefix: '', suffix: '+' },
  ]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsButtonVisible(true);
    }, 500);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          setIsVisible((prev) => ({ ...prev, [entry.target.id]: entry.isIntersecting }));
        });
      },
      { threshold: 0.1 }
    );

    Object.values(sectionRefs.current).forEach((ref) => {
      if (ref) observer.observe(ref);
    });

    return () => observer.disconnect();
  }, []);

  useEffect(() => {
    localStorage.setItem('email', email);
  }, [email]);

  useEffect(() => {
    if (isVisible['stats']) {
      const interval = setInterval(() => {
        setStats(prevStats =>
          prevStats.map(stat => ({
            ...stat,
            value: stat.value < stat.target ? stat.value + 1 : stat.target
          }))
        );
      }, 30);

      return () => clearInterval(interval);
    }
  }, [isVisible['stats']]);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex(prevIndex => (prevIndex + 1) % testimonials.length);
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  const handleNavigation = (page) => {
    if (email) {
      if (!validateEmail(email)) {
        alert('Please enter a valid email address.');
      } else {
        navigate(`/${page}?email=${encodeURIComponent(email)}`);
      }
    } else {
      navigate(`/${page}`);
    }
  };

  const validateEmail = (email) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  return (
    <div className="landing-page">
      <Helmet>
        <title>Popularis</title>
        <meta name="description" content="Your one-stop-shop for patient care, billing, and clinic management." />
        <meta
          name="keywords"
          content="Popularis, Popularis Health, EMR, Medical Claims, Eligibility, patient eligibility, encrypted medical records, encrypted EMR, claim status, Medical billing, Patient management software, Cloud-based EMR solutions, Medical coding, Claims, Patient records, PHI, Popularis, Popularis Health, Healthcare, Intake forms, Patient eligibility, Insurance eligibility"
        />
        <meta property="og:title" content="Popularis" />
        <meta
          property="og:description"
          content=" Your one-stop-shop for patient care, billing, and clinic management."
        />
        <meta property="og:url" content="https://www.popularishealth.com" />
      </Helmet>

      <Header />
      <main className="landing-content">
        {/* Hero Section */}



        <section className="hero-section" ref={(el) => (sectionRefs.current['hero'] = el)} id="hero">
  {/* Font imports */}
  <link href="https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@400;500;600;700&display=swap" rel="stylesheet" />

  {/* Angled background */}
  <div className="hero-background">
    <div className="hero-angle"></div>
  </div>

      {/* Content container */}
      <div className="hero-wrapper">
        {/* Left side content */}
        <div className="hero-content-left">
          <div className='hero-text-left'>
            <h1 className="hero-title">Clinical Software to grow your practice</h1>
            <p className={`hero-description ${isVisible['hero'] ? 'animate' : ''}`}>
              Join other providers that are using Popularis as their one-stop-shop for patient care, billing, and clinic management.
            </p>
      </div>
          {/* Email Input and Start Now Button */}
          <div className="email-input-container">
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Email address"
              className="email-input"
            />
            <button onClick={() => handleNavigation('signup')} className="start-now-button">
              Start now &gt;
            </button>
          </div>
        </div>


        {/* Right side content */}
        <div className="hero-content-right">
          <div className="animated-hero">
            <video
              src={Hero}
              autoPlay
              playsInline
              muted
              loop
              x-webkit-airplay="deny"
              title="Animation showing different user types such as doctor, nurse, tech, or lab worker surrounded by the icons of the apps the user uses most."
            ></video>
          </div>
        </div>
      </div>
    </section>

        <AllInOneSection />
        <section className="process-section bg-white" ref={(el) => (sectionRefs.current['process'] = el)} id="process">
          <div className="golden-container">
            <h2 className={`section-title ${isVisible['process'] ? 'animate' : ''}`}>
              Simply, we do three things...
            </h2>
            <div className="process-steps">
              {[
                {
                  title: 'Manage Patients',
                  description: "User-friendly and clinically approved patient app lets providers focus on patient care.",
                  icon: faHeart, // Heart icon for patients
                },
                {
                  title: 'Get You Paid',
                  description: "Send batches of claims and ensure you're going to get paid with insurance verification with every payor.",
                  icon: faFileInvoiceDollar, // Invoice icon for payments
                },
                {
                  title: 'Secure your Data',
                  description: 'We are the first EMR to offer encryption for all data, ensuring top security and confidentiality.',
                  icon: faLock, // Lock icon for data safety
                },
              ].map((step, index) => (
                <div
                  className={`step-3 ${isVisible['process'] ? 'animate' : ''}`}
                  key={index}
                  style={{ animationDelay: `${index * 0.2}s` }}
                >
                  <FontAwesomeIcon icon={step.icon} className="step-icon" /> {/* Use the updated icons */}
                  <h3 className="step-title">{step.title}</h3>
                  <p className="step-description">{step.description}</p>
                </div>
              ))}
            </div>
            <div className='cta-container-center'>
              <div className={`cta-buttons px-0 mt-4 ${isVisible['hero'] ? 'animate' : ''} ${isButtonVisible ? 'visible' : 'hidden'}`}>
                {/* <input
                  type="email"
                  placeholder="Email"
                  value={email}
                  className="email"
                  onChange={(e) => setEmail(e.target.value)}
                /> */}
                <button className="cta-button" onClick={() => handleNavigation('signup')}>
                  Try for Free
                </button>
                <button className="cta-button" onClick={() => handleNavigation('demo')}>
                  Book a Demo
                </button>
              </div>
              <div className="hero-disclaimer-container">
                <p className={`hero-disclaimer ${isVisible['hero'] ? 'animate' : ''}`}>
                  Immediate access - No credit card required
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className="bg-dark" id="security">
          <div className="golden-container">
            <h2 className={`security-section ${isVisible['security'] ? 'animate' : ''}`}>
              The World's First Encrypted EMR
            </h2>
            <div className="process-steps">
              {[
                {
                  title: 'Encryption',
                  description: "Your data is encrypted to the highest standards set by NIST: AES-256 with multiple keys needed to decrypt.",
                  icon: faLock, // Lock icon for data safety
                },
                {
                  title: 'Geofencing',
                  description: "We only allow access from the United States and the EU. Any VPN or dark web connections are strictly rejected.",
                  icon: faGlobeAmericas, // Globe icon for geofencing
                },
                {
                  title: 'Disaster Recovery',
                  description: 'Daily backups of all your data to three separate data centers within the United States for disaster recovery.',
                  icon: faServer, // Server icon for backups
                },
              ].map((step, index) => (
                <div
                  className={`step-3 ${isVisible['process'] ? 'animate' : ''}`}
                  key={index}
                  style={{ animationDelay: `${index * 0.2}s` }}
                >
                  <FontAwesomeIcon icon={step.icon} className="step-icon-dark" /> {/* Updated icons */}
                  <h3 className="step-title-dark">{step.title}</h3>
                  <p className="step-description-dark">{step.description}</p>
                </div>
              ))}
            </div>
          </div>
        </section>

      


        <section className="stats-section bg-white" ref={(el) => (sectionRefs.current['stats'] = el)} id="stats">
          <div className="golden-container">
            <h2 className={`section-title ${isVisible['stats'] ? 'animate' : ''}`}>
              Our Impact in Numbers
            </h2>
            <div className="stats-grid">
              {stats.map((stat, index) => (
                <div
                  className={`stat-item ${isVisible['stats'] ? 'animate' : ''}`}
                  key={index}
                  style={{ animationDelay: `${index * 0.2}s` }}
                >
                  <h3 className="stat-value">
                    {stat.prefix}{stat.value}{stat.suffix}
                  </h3>
                  <p className="stat-label">{stat.label}</p>
                </div>
              ))}
            </div>
          </div>
        </section>

        {/* Made in USA Section */}
        <section
          className="made-in-usa-section"
          ref={(el) => (sectionRefs.current['madeInUsa'] = el)}
          id="made-in-usa"
          style={{
            backgroundImage: `url(${AmericanFlag})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            color: 'white',
          }}
        >
          <div className="golden-container">
            <h2 className={`security-section ${isVisible['madeInUsa'] ? 'animate' : ''}`}>
              We are proud to be Made in the USA
            </h2>
            <div className="process-steps">
              {[
                {
                  title: 'We connect to 3,500 US-based Insurance Payers',
                  icon: faFileInvoiceDollar,
                },
                {
                  title: 'Only US-based Customer Support',
                  icon: faHeadset,
                },
                {
                  title: 'Servers and Infrastructure Only in USA',
                  icon: faServer,
                }
              ].map((item, index) => (
                <div
                  className={`step-3 ${isVisible['madeInUsa'] ? 'animate' : ''}`}
                  key={index}
                  style={{ animationDelay: `${index * 0.2}s` }}
                >
                  <FontAwesomeIcon icon={item.icon} className="step-icon-dark" />
                  <h3 className="step-title-dark">{item.title}</h3>
                </div>
              ))}
            </div>
          </div>
        </section>

        {/* Easy Upgrade Section */}
<section className="bg-white" id="easy-upgrade" ref={(el) => (sectionRefs.current['easy-upgrade'] = el)}>
  <div className="golden-container">
    <h2 className={`section-title ${isVisible['easy-upgrade'] ? 'animate' : ''}`}>
      Easy Upgrade to Popularis
    </h2>
    <div className="process-steps">
      {[
        {
          title: 'Batch Imports via XLSX',
          description: "Quickly migrate your data with our easy-to-use batch import tool supporting XLSX files.",
          icon: faFileExcel,
        },
        {
          title: 'Intuitive Design',
          description: "Our user-friendly interface ensures minimal training time for your staff.",
          icon: faDesktop,
        },
        {
          title: 'Advanced Migration Assistance',
          description: 'Our customer service team is ready to provide advanced support for a seamless transition.',
          icon: faHeadset,
        },
      ].map((step, index) => (
        <div
          className={`step-3 ${isVisible['easy-upgrade'] ? 'animate' : ''}`}
          key={index}
          style={{ animationDelay: `${index * 0.2}s` }}
        >
          <FontAwesomeIcon icon={step.icon} className="step-icon" />
          <h3 className="step-title">{step.title}</h3>
          <p className="step-description">{step.description}</p>
        </div>
      ))}
    </div>
  </div>
</section>



{/* API Integrations Section */}
<section className="bg-dark" id="api-integrations">
  <div className="golden-container">
    <h2 className={`security-section ${isVisible['api-integrations'] ? 'animate' : ''}`}>
      API Integrations Made Easy
    </h2>
    <div className="process-steps">
      {[
        {
          title: 'Seamless Integration',
          description: "Effortlessly integrate with external systems to enhance your workflows.",
          icon: faPlug,
        },
        {
          title: 'Custom Solutions',
          description: "Our team is ready to assist with custom integrations tailored to your needs.",
          icon: faClipboardList,
        },
        {
          title: 'Enterprise Support',
          description: 'Enterprise users enjoy free integrations as part of our commitment to your success.',
          icon: faNotesMedical,
        },
      ].map((step, index) => (
        <div
          className={`step-3 ${isVisible['api-integrations'] ? 'animate' : ''}`}
          key={index}
          style={{ animationDelay: `${index * 0.2}s` }}
        >
          <FontAwesomeIcon icon={step.icon} className="step-icon-dark" />
          <h3 className="step-title-dark">{step.title}</h3>
          <p className="step-description-dark">{step.description}</p>
        </div>
      ))}
    </div>
    <div className="cta-container-center">
      <button className="cta-button">Read Docs</button>
    </div>
    <div className="subdescription">
      <p className="step-description-dark">
        We have a team ready to do custom integrations. For enterprise users, we will do integrations for free.
      </p>
    </div>
  </div>
</section>

<ArticleCarousel />


        {/* Why Popularis Section */}
        <section className="why-popularis-section bg-grey" ref={(el) => (sectionRefs.current['whyPopularis'] = el)} id="why-popularis">
          <div className="golden-container">
            <h2 className={`section-title ${isVisible['whyPopularis'] ? 'animate' : ''}`}>
              Why Popularis?
            </h2>
            <div className="popularis-features-grid">
              {[
                {
                  title: 'Intuitive Design',
                  description: 'Our interface is simple and easy to use, making your workflow seamless.',
                  icon: faDesktop, // Example icon for intuitive design
                },
                {
                  title: 'Saves Hours Daily',
                  description: 'Automated and batched workflows save you hours daily on administrative tasks.',
                  icon: faClock, // Example icon for time-saving
                },
                {
                  title: 'Instant Eligibility',
                  description: 'Check patient eligibility in real-time, reducing delays and improving efficiency.',
                  icon: faCheckCircle, // Example icon for instant eligibility
                },
                {
                  title: 'Batch Claims',
                  description: 'Send claims in bulk with ease, reducing the manual workload for billing staff.',
                  icon: faFileInvoiceDollar, // Example icon for batch claims
                },
                {
                  title: 'Easy API Integrations',
                  description: 'Integrate with external systems effortlessly using our powerful API.',
                  icon: faPlug, // Example icon for API integrations
                },
                {
                  title: 'Custom Intake Forms',
                  description: 'Create and customize patient intake forms tailored to your clinic’s needs.',
                  icon: faClipboardList, // Example icon for custom forms
                },
              ].map((feature, index) => (
                <div
                  className={`feature-card ${isVisible['whyPopularis'] ? 'animate' : ''}`}
                  key={index}
                  style={{ animationDelay: `${index * 0.2}s` }}
                >
                  <FontAwesomeIcon icon={feature.icon} className="feature-icon" />
                  <h3 className="feature-title">{feature.title}</h3>
                  <p className="feature-description">{feature.description}</p>
                </div>
              ))}
            </div>
          </div>
        </section>



        <section className="bg-white">
          <div className="golden-container">
            <h2 className={`section-title ${isVisible['demo'] ? 'animate' : ''}`}>
              A demo of Popularis
            </h2>
            <div className="video-container">
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/DKn5ix1xaPc?start=1"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
          </div>
        </section>


        <div
          className={`tile-support tile bg-grey`}
          data-id="support"
        >
          <a href='/support'>
            <div className="golden-container">

              <div className="tile-support">
                <div className="support-wrapper">
                  <div className="support-icon-features">
                    <FontAwesomeIcon style={{ height: "100px" }} icon={faHeadset} />
                  </div>
                  <div>
                    <h1 className="section-title">The Popularis Team is always by your side.</h1>
                    <p className="typography-label-alt-dark support-text">US-based support is always just a call, email, or text away.</p>
                  </div>
                </div>
              </div>

            </div>
          </a>
        </div>
      </main>
      <Footer />

      <style jsx>{`
        body {
          overflow-x: hidden;
          max-width: 100%;
          margin: 0px;
        }

    
        .animate {
          animation: fadeInUp 0.6s ease-out forwards;
        }

        @keyframes fadeInUp {
          from {
            opacity: 0;
            transform: translateY(20px);
          }
          to {
            opacity: 1;
            transform: translateY(0);
          }
        }

        .stats-section {
          background-color: #f1f1f1;
          color: #00316f;
          text-align: center;
        }

        .stats-grid {
          display: grid;
          grid-template-columns: repeat(auto-fit, minmax(200px, 1fr)); /* Allows 1, 2, or 3 columns */
          gap: 40px;
          max-width: 1200px;
          margin: 0 auto;
        }        

        .stat-item {
          opacity: 0;
          animation: fadeInUp 0.6s ease-out forwards;
        }

        .stat-value {
          font-size: 48px;
          font-weight: bold;
          margin-bottom: 10px;
          color: #00316f;
        }

        .stat-label {
          font-size: 24px;
          color: #1d1d1f;
        }


        .px-5vw {
            margin-left: 5vw; 
            margin-right: 5vw; 
        }
        .stat-value {
          font-size: 48px;
          font-weight: bold;
          margin-bottom: 10px;
          color: #00316f;
        }

        .stat-label {
          font-size: 18px;
          color: #1d1d1f;
        }

        /* Updated Parallax Section Styles */
        .parallax-section {
          background-color: #f1f1f1;
          color: #00316f;
          overflow: hidden;
        }

        .parallax-content {
          display: flex;
          align-items: center;
          gap: 40px;
          flex-direction: row-reverse; /* Reverses the order of flex items */
        }

        .parallax-image-container {
          flex: 1;
          height: 400px;
          overflow: hidden;
          border-radius: 8px;
        }

        .parallax-image {
          width: 100%;
          height: 100%;
          object-fit: cover;
          transition: transform 0.3s ease-in-out;
        }

        .parallax-image-container:hover .parallax-image {
          transform: scale(1.05);
        }

        .data-points {
          flex: 1;
          display: flex;
          flex-direction: column;
          gap: 30px;
        }

        .data-point {
          opacity: 0;
          animation: fadeInUp 0.6s ease-out forwards;
        }

        @media only screen and (max-width: 734px) {
          .hero-title {
            font-size: 56px;
          }

          .heroImageContainer {
            display: none;
          }

          .hero-description {
            font-size: 24px;
            line-height: 36px;
            margin-left: 5%;
            margin-right: 5%;
          }

          .support-text {
          text-align: center;
          }

          .tile-content {
            padding: 0px;
          }

          .support-icon-features {
            margin-right: 0rem;
          }

          .support-wrapper {
            flex-direction: column;
            text-align: center;
          }

          .cta-button {
            font-size: 16px;
            padding: 10px 20px;
          }

          .process-steps {
            flex-direction: column;
            align-items: center;
          }

          .step-3 {
            flex: 1 1 100%;
            max-width: 400px;
          }

          .stats-grid {
            grid-template-columns: repeat(2, 1fr);
          }

          .stat-value {
            font-size: 36px;
          }

          .stat-label {
            font-size: 16px;
          }

          .parallax-content {
            flex-direction: column-reverse;
          }

          .parallax-image-container {
            width: 100%;
            height: 300px;
            margin-bottom: 30px;
          }

          .data-points {
            width: 100%;
          }
        }

        .bg-grey {
          background: #f1f1f1;
        }

        .bg-white {
          background: #fefefe;
        }
      `}</style>
    </div>
  );
};

export default LandingPage;
