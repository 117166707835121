import React from "react";

function SidebarAPI({ onToggleCallbacks }) {
  return (
    <div className="sidebar">
      <ul className="sidebar-list">
        <li className="sidebar-item" onClick={onToggleCallbacks}>Callbacks</li>
        {/* Add more tabs here as needed */}
      </ul>
    </div>
  );
}

export default SidebarAPI;
