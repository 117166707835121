import React, { useState } from "react";
import styles from "../PatientView.module.css";
import { doc, collection, addDoc, serverTimestamp, Timestamp } from "firebase/firestore";
import { db } from "../../../firebase";
import useUID from "../../General/useUID";
import { getFunctions, httpsCallable } from "firebase/functions";
import HexagonSpinner from "../../General/Animations/Hexspinner";

const functions = getFunctions();
const encryptFunction = httpsCallable(functions, "encrypt");

function Vitals({ patientId, onClose }) {
  const [uid, subUserUID, isLoading, error] = useUID();
  const [vitals, setVitals] = useState({
    heartRate: "",
    bloodPressure: "",
    bmi: "",
    weight: "",
    temperature: "",
    respiratoryRate: "",
    oxygenSaturation: "",
  });
  const [loading, setLoading] = useState(false);
  const [overrideTimestamp, setOverrideTimestamp] = useState(false);
  const [customDate, setCustomDate] = useState("");
  const [customTime, setCustomTime] = useState("");

  const handleChange = (e) => {
    setVitals({
      ...vitals,
      [e.target.name]: e.target.value,
    });
  };

  const handleDateChange = (e) => {
    setCustomDate(e.target.value);
  };

  const handleTimeChange = (e) => {
    setCustomTime(e.target.value);
  };

  const handleOverrideChange = () => {
    setOverrideTimestamp(!overrideTimestamp);
  };

  const isFormEmpty = () => {
    return Object.values(vitals).every((x) => x === "");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (isFormEmpty()) {
      alert("Please fill in at least one field.");
      return;
    }

    if (overrideTimestamp && (!customDate || !customTime)) {
      alert("Please provide both date and time for the override.");
      return;
    }

    setLoading(true);

    try {
      // Encrypt the vitals data before sending it to Firestore
      const encryptedResponse = await encryptFunction(vitals);
      const encryptedData = encryptedResponse.data; // Assuming this is the structure of your encrypted data

      const medicalHistoryRef = collection(
        doc(db, "patients", uid, "patientData", patientId),
        "medicalHistory"
      );

      // Determine the timestamp to use
      let timestamp = serverTimestamp();
      if (overrideTimestamp && customDate && customTime) {
        const [year, month, day] = customDate.split("-");
        const [hours, minutes] = customTime.split(":");
        timestamp = Timestamp.fromDate(new Date(year, month - 1, day, hours, minutes));
      }

      // Storing encrypted data along with the encryption IV
      await addDoc(medicalHistoryRef, {
        type: "Vitals",
        timestamp: timestamp,
        ciphertext: encryptedData.ciphertext, // Store the encrypted ciphertext
        iv: encryptedData.iv, // Store the IV needed for decryption
      });

      const logData = {
        timestamp: Date.now(),
        patientId: patientId,
        activity: "Medical history added",
        activityType: "medicalHistory",
        uid: uid,
      };

      await addDoc(collection(db, "users", uid, "activityLogs"), logData);

      // Reset the form
      setVitals({
        heartRate: "",
        bloodPressure: "",
        bmi: "",
        weight: "",
        temperature: "",
        respiratoryRate: "",
        oxygenSaturation: "",
      });
      onClose();
    } catch (error) {
      console.error("Error adding document: ", error);
    } finally {
      setLoading(false); // Stop loading after the async operation
    }
  };

  return (
    <div className={styles.vitalsForm}>
      <form onSubmit={handleSubmit}>
        <div className="input-group-row">
          <div className="input-field">
            <label htmlFor="heartRate">Heart Rate (HR):</label>
            <input
              type="number"
              id="heartRate"
              name="heartRate"
              value={vitals.heartRate}
              onChange={handleChange}
            />
          </div>

          <div className="input-field">
            <label htmlFor="bloodPressure">Blood Pressure (BP):</label>
            <input
              type="text"
              id="bloodPressure"
              name="bloodPressure"
              value={vitals.bloodPressure}
              onChange={handleChange}
              placeholder="e.g., 120/80"
            />
          </div>
        </div>
        <div className="input-group-row">
          <div className="input-field">
            <label htmlFor="bmi">Body Mass Index (BMI):</label>
            <input
              type="number"
              step="0.01"
              id="bmi"
              name="bmi"
              value={vitals.bmi}
              onChange={handleChange}
            />
          </div>
          <div className="input-field">
            <label htmlFor="weight">Weight:</label>
            <input
              type="number"
              step="0.1"
              id="weight"
              name="weight"
              value={vitals.weight}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="input-group-row">
          <div className="input-field">
            <label htmlFor="temperature">Temperature:</label>
            <input
              type="number"
              step="0.1"
              id="temperature"
              name="temperature"
              value={vitals.temperature}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="input-group-row">
          <div className="input-field">
            <label htmlFor="respiratoryRate">Respiratory Rate:</label>
            <input
              type="number"
              id="respiratoryRate"
              name="respiratoryRate"
              value={vitals.respiratoryRate}
              onChange={handleChange}
            />
          </div>
          <div className="input-field">
            <label htmlFor="oxygenSaturation">Oxygen Saturation (%):</label>
            <input
              type="number"
              step="0.1"
              id="oxygenSaturation"
              name="oxygenSaturation"
              value={vitals.oxygenSaturation}
              onChange={handleChange}
            />
          </div>
        </div>

        <div className="input-group-row">
          <div className="input-field">
            <label>
              <input
                type="checkbox"
                className="custom-checkbox"
                checked={overrideTimestamp}
                onChange={handleOverrideChange}
              />
              Override Timestamp
            </label>
          </div>
          {overrideTimestamp && (
            <>
              <div className="input-field">
                <label htmlFor="customDate">Date:</label>
                <input
                  type="date"
                  id="customDate"
                  value={customDate}
                  onChange={handleDateChange}
                />
              </div>
              <div className="input-field">
                <label htmlFor="customTime">Time:</label>
                <input
                  type="time"
                  id="customTime"
                  value={customTime}
                  onChange={handleTimeChange}
                />
              </div>
            </>
          )}
        </div>

        {loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <HexagonSpinner />
          </div>
        ) : (
          <button
            type="submit"
            className={styles.submitButton}
            disabled={loading}
          >
            Submit Vitals
          </button>
        )}
      </form>
    </div>
  );
}

export default Vitals;
