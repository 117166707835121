import React, { useState, useEffect } from "react";
import { db } from "../../../../../firebase";
import { doc, getDoc } from "firebase/firestore";
import AppointmentSlots from "./AppointmentSlots";

const Appointments = ({
  clinicId,
  onNext,
  onAppointmentSelected
}) => {
  const [clinicData, setClinicData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchClinicData = async () => {
      setLoading(true);
      try {
        const docRef = doc(db, "clinics", clinicId);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setClinicData(docSnap.data());
        } else {
          console.log("No such document!");
        }
      } catch (error) {
        console.error("Error fetching clinic data:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchClinicData();
  }, [clinicId]);

  return (
    <div>
      {loading ? (
        <p>Loading...</p>
      ) : clinicData ? (
        <AppointmentSlots
          workingHours={clinicData.workingHours}
          hoursBefore={clinicData.hoursBefore}
          appointmentTypes={clinicData.appointmentTypes}
          clinicTimeZone={clinicData.timeZone}
          clinicId={clinicId}
          onNext={onNext}
          onAppointmentSelected={onAppointmentSelected}
        />
      ) : (
        <p>Clinic data not found.</p>
      )}
    </div>
  );
};

export default Appointments;
