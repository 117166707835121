import {
    faCapsules, // For Pharmacy
    faLaptopMedical, // For Telehealth
    faSchool, // For School
    faHouseUser, // For Homeless Shelter, Home
    faClinicMedical, // For health facilities
    faUserMd, // For Individual Provider, Office
    faTooth, // For Dentistry
    faEye, // For Ophthalmology
    faBone, // For Orthopedics
    faSyringe, // For Pharmacy, vaccinations
    faUsers, // For Group Home, Family Care
    faRadiation, // For Radiology
    faAllergies, // For Allergology
    faVirus, // For Infectious Diseases
    faXRay, // For Diagnostic Imaging
    faMicroscope, // For Laboratory Sciences
    faBrain, // For Neurology, Mental Health
    faLungs, // For Pulmonology
    faDna, // For Genetics
    faKeyboard, // For Manual Input, Telehealth Provided in Patients Home
    faBabyCarriage, // For Pediatrics
    faUserNurse, // For Nursing Home, Assisted Living Facility
    faAmbulance, // For Emergency Services
    faHeartbeat, // For Cardiology
    faBuilding, // For Organization, Urgent Care Facility, and similar institutions
    faPray, // For places like Hospice
    faPlane, // For Ambulance - Air
    faShip, // For Ambulance - Water
    faWheelchair, // For Disability services, Rehabilitation
    faBed, // For Inpatient Hospital, Skilled Nursing Facility
    faStethoscope, // For a generic medical representation
    faQuestionCircle,
    faHospitalSymbol  
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
  
  export const posCodesWithIcons = [
    { code: "01", description: "Pharmacy", icon: faCapsules },
    { code: "02", description: "Telehealth", icon: faLaptopMedical },
    { code: "03", description: "School", icon: faSchool },
    { code: "04", description: "Homeless shelter", icon: faHouseUser },
    { code: "05", description: "Indian Health Free-standing Facility", icon: faClinicMedical },
    { code: "06", description: "Indian Health Provider-based Facility", icon: faClinicMedical },
    { code: "07", description: "Tribal 638 Free-standing Facility", icon: faClinicMedical },
    { code: "08", description: "Tribal 638 Provider-based Facility", icon: faClinicMedical },
    { code: "09", description: "Prison / Correctional Facility", icon: faBuilding },
    { code: "10", description: "Telehealth Provided in Patients Home", icon: faKeyboard },
    { code: "11", description: "Office", icon: faUserMd },
    { code: "12", description: "Home", icon: faHouseUser },
    { code: "13", description: "Assisted Living Facility", icon: faUserNurse },
    { code: "14", description: "Group Home", icon: faUsers },
    { code: "15", description: "Mobile Unit", icon: faAmbulance },
    { code: "16", description: "Temporary Lodging", icon: faBed },
    { code: "17", description: "Walk-in Retail Health Clinic", icon: faBuilding },
    { code: "18", description: "Place of Employment / Worksite", icon: faBuilding },
    { code: "19", description: "Off Campus-Outpatient Hospital", icon: faClinicMedical },
    { code: "20", description: "Urgent Care Facility", icon: faBuilding },
    { code: "21", description: "Inpatient Hospital", icon: faBed },
    { code: "22", description: "On Campus-Outpatient Hospital", icon: faClinicMedical },
    { code: "23", description: "Emergency Room Hospital", icon: faAmbulance },
    { code: "24", description: "Ambulatory Surgical Center", icon: faStethoscope },
    { code: "25", description: "Birthing Center", icon: faBabyCarriage },
    { code: "26", description: "Military Treatment Facility", icon: faBuilding },
    { code: "31", description: "Skilled Nursing Facility", icon: faBed },
    { code: "32", description: "Nursing Facility", icon: faBed },
    { code: "33", description: "Custodial Care Facility", icon: faWheelchair },
    { code: "34", description: "Hospice", icon: faPray },
    { code: "41", description: "Ambulance - Land", icon: faAmbulance },
    { code: "42", description: "Ambulance - Air or Water", icon: faPlane }, // Consider separating if needed
    { code: "49", description: "Independent Clinic", icon: faClinicMedical },
    { code: "50", description: "Federally Qualified Health Center", icon: faClinicMedical },
    { code: "51", description: "Inpatient Psychiatric Facility", icon: faBrain },
    { code: "52", description: "Psychiatric Facility-Partial Hospitalization", icon: faBrain },
    { code: "53", description: "Community Mental Health Center", icon: faBrain },
    { code: "54", description: "Intermediate Care Facility / Intellectual Disabilities", icon: faWheelchair },
    { code: "55", description: "Residential Substance Abuse Treatment Facility", icon: faHouseUser },
    { code: "56", description: "Psychiatric Residential Treatment Center", icon: faBrain },
    { code: "57", description: "Non-residential Substance Abuse Treatment Facility", icon: faHouseUser },
    { code: "58", description: "Non-residential Opioid Treatment Facility", icon: faSyringe },
    { code: "60", description: "Mass Immunization Center", icon: faSyringe },
    { code: "61", description: "Comprehensive Inpatient Rehabilitation Facility", icon: faWheelchair },
    { code: "62", description: "Comprehensive Outpatient Rehabilitation Facility", icon: faWheelchair },
    { code: "65", description: "End-Stage Renal Disease Treatment Facility", icon: faHospitalSymbol },
    { code: "71", description: "Public Health Clinic", icon: faClinicMedical },
    { code: "72", description: "Rural Health Clinic", icon: faClinicMedical },
    { code: "81", description: "Independent Laboratory", icon: faMicroscope },
    { code: "99", description: "Other Place of Service", icon: faQuestionCircle },
    // You may need to import faQuestionCircle for the 'Other' category
  ]
  
  export default posCodesWithIcons;
  