import React, { useState, useEffect } from "react";
import QRCode from "qrcode.react";
import HexagonSpinner from "../../General/Animations/Hexspinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHospital,
  faEdit,
  faTrash,
  faCopy,
  faCalendar // Import the calendar icon
} from "@fortawesome/free-solid-svg-icons";

import useUID from "../../General/useUID";
import {
  collection,
  deleteDoc,
  doc,
  onSnapshot,
  query,
  where,
} from "firebase/firestore";
import { db } from "../../../firebase";
import ClinicEditModal from "./ClinicEditModal";
import ClinicIntakeModal from "./ClinicIntakeModal";
import HelpArticleLink from "../../Articles/HelpArticleLink";
import AddClinic from "./AddClinic";
import { useNavigate } from 'react-router-dom';

function Clinics() {
  const [isLoading, setIsLoading] = useState(false);
  const [popupOpen, setPopupOpen] = useState(false);
  const [clinics, setClinics] = useState([]);
  const [uid, subUserUID] = useUID();
  const [selectedClinic, setSelectedClinic] = useState(null);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [clinicToDelete, setClinicToDelete] = useState(null);
  const [modalType, setModalType] = useState(null); 
  const navigate = useNavigate(); // Add navigate

  const getClinics = () => {
    const clinicsSnapshot = collection(db, "clinics");
    const q = query(clinicsSnapshot, where("ownerId", "==", uid));

    const unsubscribe = onSnapshot(
      q,
      (snapshot) => {
        const clinicsList = [];
        snapshot.forEach((doc) => {
          const clinicData = doc.data();
          const createdDate = clinicData.created
            ? clinicData.created.toDate()
            : null;
          clinicsList.push({
            id: doc.id,
            name: clinicData.clinicName,
            link: clinicData.link,
            created: createdDate,
            formData: clinicData.formData,
            appointmentsEnabled: clinicData.appointmentsEnabled // Assuming this is the field for appointments
          });
        });
        setClinics(clinicsList);
      },
      (error) => {
        console.error("Error fetching clinics:", error);
      }
    );

    return unsubscribe;
  };

  useEffect(() => {
    const unsubscribe = getClinics();
    return unsubscribe;
  }, [uid]);

  const togglePopup = () => {
    setPopupOpen(!popupOpen);
  };

  const downloadQRCode = (id) => {
    const canvas = document.getElementById(id);
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    const downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = "clinic_qrcode.png";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  const handleDeleteConfirmation = (clinicId) => {
    setClinicToDelete(clinicId);
    setShowDeleteConfirmation(true);
  };

  const handleCancelDelete = () => {
    setClinicToDelete(null);
    setShowDeleteConfirmation(false);
  };

  const handleConfirmDelete = async () => {
    if (clinicToDelete) {
      try {
        const clinicRef = doc(db, "clinics", clinicToDelete);
        await deleteDoc(clinicRef);
        setClinics(clinics.filter((clinic) => clinic.id !== clinicToDelete));
      } catch (error) {
        console.error("Error deleting clinic:", error);
      }
      setClinicToDelete(null);
      setShowDeleteConfirmation(false);
    }
  };

  const handleEditClinic = (clinic) => {
    setSelectedClinic(clinic);
    setModalType('edit');
  };
  
  const handleEditIntake = (clinic) => {
    setSelectedClinic(clinic);
    setModalType('intake');
  };

  const handleViewAppointments = (clinicId) => {
    navigate(`/appointmentscalendar/${clinicId}`);
  };

  return (
    <div className="addClinic">
    {modalType === 'intake' && (
      <ClinicIntakeModal
        selectedClinic={selectedClinic}
        setSelectedClinic={setSelectedClinic}
        onClose={() => setModalType(null)}
      />
    )}
    {modalType === 'edit' && (
      <ClinicEditModal
        selectedClinic={selectedClinic}
        setSelectedClinic={setSelectedClinic}
        onClinicsUpdated={() => {}}
        onClose={() => setModalType(null)}
      />
    )}
      <div>
        <button
          id="AddClinicButton"
          onClick={togglePopup}
          className="addClinicButton"
        >
          <FontAwesomeIcon icon={faHospital} style={{ marginRight: "10px" }} />
          <div className="patientsActionButtonText">Clinics</div>
        </button>
      </div>

      {popupOpen && (
        <div className="popupContainer">
          <div className="clinicWindow">
            <div className="cancelButton">
              <button onClick={togglePopup} className="filesCloseButton">
                X
              </button>
            </div>
            <div className="newClinic">
              <div className="centerHeader">
                <h3>Clinics</h3>
                <p>Create a clinic to allow patients to fill out intake forms and stay organized.</p>
              </div>

              <AddClinic />

              {isLoading && <HexagonSpinner />}
            </div>

            <div className="clinicsList">
              <table>
                <thead>
                  <tr>
                    <th>Clinic Name</th>
                    <th>Clinic Link</th>
                    <th>QR Code</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {clinics.map((clinic) => (
                    <tr key={clinic.id}>
                      <td style={{ textAlign: "center" }}>{clinic.name}</td>
                      <td style={{ textAlign: "center" }}>
                        {clinic.link ? (
                          <button
                            onClick={() =>
                              navigator.clipboard.writeText(clinic.link)
                            }
                          >
                            <FontAwesomeIcon className="mr-3" icon={faCopy} />
                            Copy Link
                          </button>
                        ) : (
                          <span>Private Clinic</span>
                        )}
                      </td>
                      <td style={{ textAlign: "center" }}>
                        {clinic.link && (
                          <QRCode
                            id={`clinicQRCode-${clinic.id}`}
                            value={clinic.link}
                            size={64}
                            onClick={() =>
                              downloadQRCode(`clinicQRCode-${clinic.id}`)
                            }
                          />
                        )}
                      </td>
                      <td style={{ textAlign: "center" }}>
                        {showDeleteConfirmation &&
                        clinic.id === clinicToDelete ? (
                          <div className="confirmationDialog">
                            <p>Are you sure you want to delete this clinic?</p>
                            <button onClick={handleConfirmDelete}>Yes</button>
                            <button onClick={handleCancelDelete}>No</button>
                          </div>
                        ) : (
                          <div className="display-flex m-auto">
                              {clinic.appointmentsEnabled && (
                              <button
                                onClick={() => handleViewAppointments(clinic.id)}
                                className="primary"
                              >
                                <FontAwesomeIcon className="mr-3" icon={faCalendar} />{" "}
                                View Appointments Calendar
                              </button>
                            )}
                            <button
                              onClick={() => handleEditClinic(clinic)}
                              className="primary"
                            >
                              <FontAwesomeIcon className="mr-3" icon={faEdit} />{" "}
                              Edit Clinic
                            </button>
                            <button
                              onClick={() => handleEditIntake(clinic)}
                              className="primary"
                            >
                              <FontAwesomeIcon className="mr-3" icon={faEdit} />{" "}
                              Edit Intake
                            </button>
                            <button
                              onClick={() =>
                                handleDeleteConfirmation(clinic.id)
                              }
                              className="danger"
                            >
                              <FontAwesomeIcon
                                className="mr-3"
                                icon={faTrash}
                              />{" "}
                              Delete Clinic
                            </button>
                          </div>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="help-article-wide">
                <HelpArticleLink
                  article={{
                    title: "Clinics",
                    link: "https://popularishealth.com/article/Clinics",
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Clinics;
