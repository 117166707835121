import React, { useState, useRef } from "react";
import { useParams } from "react-router-dom";
import PatientList from "./PatientList";
import "./PatientFiles.modules.css";
import Topbar from "../General/Topbar";
import PatientView from "./PatientView";
import FolderControls from "./FolderControls";

function PatientFiles() {
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [selectedFolder, setSelectedFolder] = useState("All Patients");
  const [dataUpdated, setDataUpdated] = useState(false);
  const [resetView, setResetView] = useState(false); // Reset flag state
  const { patientIdfromURL } = useParams();
  const scrollingTableRef = useRef(null);

  const handleSelectPatient = (patient) => {
    setSelectedPatient(patient);
    setResetView((prev) => !prev); // Toggle the reset flag when a new patient is selected
  };

  const handleUpdateData = () => {
    setDataUpdated((prev) => !prev);
  };

  return (
    <div className="background">
      <Topbar />
      <div className="content">
        <div className="patientWindow">
          <PatientView
            patientSelected={selectedPatient}
            onDataUpdated={handleUpdateData}
            resetView={resetView} // Pass the reset flag to PatientView
          />
        </div>
        <div className="folderControlsContainer">
          <FolderControls
            onFolderChange={setSelectedFolder}
            selectedFolder={selectedFolder}
          />
        </div>
        <div className="patientViewBar" ref={scrollingTableRef}>
          <PatientList
            onSelectPatient={handleSelectPatient}
            selectedFolder={selectedFolder}
            selectedPatient={selectedPatient}
            patientIdfromURL={patientIdfromURL}
            scrollingTableRef={scrollingTableRef}
            DataUpdated={dataUpdated}
          />
        </div>
      </div>
    </div>
  );
}

export default PatientFiles;
