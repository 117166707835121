import React, { useState, useEffect } from "react";
import { doc, onSnapshot } from "firebase/firestore";
import { useNavigate } from 'react-router-dom';
import { auth, db } from "../../../firebase";
import Hex from '../../General/Hex';
import '../Website.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuilding, faThumbsUp, faFileCircleCheck, faHeart, faBrain, faFileInvoiceDollar } from '@fortawesome/free-solid-svg-icons';


function createBoard() {
  const rowLengthList = [2, 1];
  return rowLengthList.map(length => new Array(length).fill(0));
}

function put(board, rowIndex, cellIndex, side) {
  const newBoard = board.map(row => [...row]);
  newBoard[rowIndex][cellIndex] = side;
  return newBoard;
}

function changeSide(side) {
  return side === "A" ? "B" : "A";
}

function reducer(state, action) {
  switch (action.type) {
    case "put":
      return {
        ...state,
        board: put(
          state.board,
          action.payload.rowIndex,
          action.payload.cellIndex,
          state.currentSide
        ),
        currentSide: changeSide(state.currentSide)
      };
    default:
      return state;
  }
}

const Hextials = ({ onToggleTheme }) => {
  const [state] = React.useReducer(reducer, {
    board: createBoard(),
    currentSide: "A"
  });
  const navigate = useNavigate();
  const user = auth.currentUser;
  const now = new Date();
  const currentHour = now.getHours();
  let greeting;
  if (currentHour < 12) {
    greeting = "Good morning";
  } else if (currentHour < 18) {
    greeting = "Good afternoon";
  } else {
    greeting = "Good evening";
  }

  const [userDetails, setUserDetails] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (user) {
      const userDetailsRef = doc(db, "users", user.uid);
      const unsubscribe = onSnapshot(userDetailsRef, (doc) => {
        if (doc.exists()) {
          setUserDetails(doc.data());
          setLoading(false);
        } else {
        }
      });
      return () => unsubscribe();
    }
  }, [user]);

  return (
    <div style={{marginBottom: "1rem"}} className='containerHex'>
      <div className='mainContent'>
        {state.board.map((row, rowIndex) => {
          return (
            <div
              style={{
                marginTop: "0px",
                display: "flex",
                justifyContent: "center"
              }}
              key={`row-${rowIndex}`}
            >
              {row.map((side, cellIndex) => (
                <Hex
                  key={`${rowIndex}-${cellIndex}`}
                  side={side}
                >
                  {rowIndex === 1 && cellIndex === 0 && (
                    <div>
                      <FontAwesomeIcon
                        icon={faBrain}
                        style={{ height: "40px" }}
                      />
                      <h2>AI</h2>
                    </div>
                  )}
                  {rowIndex === 0 && cellIndex === 0 && (
                    <div>
                    <FontAwesomeIcon
                      icon={faFileInvoiceDollar}
                      style={{ height: "40px" }}
                    />
                    <h2>Claims</h2>
                  </div>
                    
                  )}
                  {rowIndex === 0 && cellIndex === 1 && (
                    <div>
                      <FontAwesomeIcon
                        icon={faHeart}
                        style={{ height: "40px" }}
                      />
                      <h2>Patients</h2>
                    </div>
                  )}
  
                </Hex>
              ))}
            </div>
          );
        })}
      </div>
    </div>
  );
};

  
  export default Hextials;
