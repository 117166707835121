import React from "react";
import { Droppable } from "react-beautiful-dnd";
import InputTemplate from "../FormElements/InputTemplate";

const FormCanvas = ({
  formElementsData,
  setFormElementsData,
  fieldDefinitions,
  isDroppable,
  preview = false
}) => (
  <Droppable droppableId="form-canvas-droppable" isDropDisabled={false}>
    {(provided, snapshot) => (
      <div
        className={`${preview ? `FormCanvasDroppablePreview` : `FormCanvasDroppable`} ${
          snapshot.isDraggingOver ? "dragOver" : ""
        } ${formElementsData.length === 0 ? "emptyCanvas" : ""}`}
        {...provided.droppableProps}
        ref={provided.innerRef}
      >
        {formElementsData.length === 0 && (
          <div className="emptyCanvasMessage">
            Drag your form items here
          </div>
        )}
        {formElementsData.map((item, index) => {
          return (
            <InputTemplate
              key={item.id}
              item={item}
              index={index}
              formElementsData={formElementsData}
              setFormElementsData={setFormElementsData}
              formElementSubTypes={
                fieldDefinitions[item.type]?.formElementSubTypes
              }
            />
          );
        })}
        {provided.placeholder}
      </div>
    )}
  </Droppable>
);

export default FormCanvas;
