import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleUser, faMailReply, faLock, faHeadset } from '@fortawesome/free-solid-svg-icons';
import Modal from 'react-modal';
import Footer from '../subcomponents/Footer';
import SearchBar from '../../Articles/SearchBar';
import Header from '../subcomponents/Header';
import { Link, useNavigate } from 'react-router-dom';
import SupportFeatured from '../subcomponents/supportFeatured';
import { Helmet } from 'react-helmet';
import BirdsFlocking from '../subcomponents/BirdsFlocking';
import '../Website.css'; // Import your CSS here

Modal.setAppElement('#root'); // Assuming your root element has the ID 'root'

const SupportPage = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState('');
  const navigate = useNavigate();

  const openModal = (content) => {
    setModalContent(content);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const goToForgotPassword = () => {
    navigate('/forgot-password'); // Navigates to the Forgot Password page
  };

  return (
    <>
      <Helmet>
        <title>Support</title>
        <meta name="description" content="The Popularis Team is always by your side." />
        <meta name="keywords" content="support, contact, help" />
        <meta property="og:title" content="Support" />
        <meta property="og:description" content="The Popularis Team is always by your side." />
        <meta property="og:image" content="https://firebasestorage.googleapis.com/v0/b/popularishealth.appspot.com/o/Popularis_logo_single.png?alt=media&token=e079bdf2-360b-42da-9ef2-1cdbdeb474cf" />
      </Helmet>
      <Header />
      <div className="background-container">
      <Modal isOpen={isModalOpen} onRequestClose={closeModal} contentLabel="Support Modal" className='confirmModal'>
        <button onClick={closeModal} className='filesCloseButton'> X </button>
        {modalContent === 'supportEmail' && (
          <div>
            <h2>Need Help?</h2>
            <p>If you can't sign in or have any questions, please contact us at: <a href="mailto:support@popularishealth.com">support@popularishealth.com</a></p>
          </div>
        )}
        {modalContent === 'forgotPassword' && (
          <div>
            <h2>Forgot Your Password?</h2>
            <button onClick={goToForgotPassword} className='forgotPasswordButton'>Go to Forgot Password</button>
          </div>
        )}
      </Modal>
        <section className="websiteblock">
          <h2 className='typography-headline'>Search for blogs or support articles.</h2>
          <SearchBar category="support" />
          <SupportFeatured />      
          <div style={{ padding: "1rem", flexDirection: "column", alignItems: "normal", marginTop: "4rem"}} className='tile-content tile-white tile'>
            <div className='support-wrapper'>
              <div className='support-icon-features'>
                  <FontAwesomeIcon style={{ height: '100px' }} icon={faHeadset}/>
              </div>
              <div>
                  <h1 className='typography-headline'>Contact Support</h1>
                  <p className='typography-label-alt-dark'>Sign in to your account and we’ll be able to help out a bit quicker.</p>
              </div>
            </div>
            <div className='sign-in-button sign-in-button-support mt-3'>
              <Link to="/signin/support" className="login-btn">
                <div className='SignInIconButton'>
                  <FontAwesomeIcon icon={faCircleUser} />
                </div>
                <>
                  Sign In
                </>
              </Link>
            </div>
            <div className='support-modals'>
              <span style={{color: "#0147DF"}} className="support-button-text" onClick={() => openModal('supportEmail')}>
                <FontAwesomeIcon style={{ marginRight: "1rem"}} icon={faMailReply} />
                Can't sign in or have a question?
              </span>
              <span style={{color: "#0147DF"}} className="support-button-text" onClick={() => openModal('forgotPassword')}>
                <FontAwesomeIcon style={{ marginRight: "1rem"}} icon={faLock} />
                Forgot your password?
              </span>
            </div>
          </div>
        </section>
      </div>

   

      <Footer />
    </>
  );
};

export default SupportPage;
