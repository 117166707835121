import { forwardRef } from "react";
import styles from "../Claims.css";

const CollapsibleSection = forwardRef(
  ({ title, number, children, activeSection, setActiveSection }, ref) => {
    const isOpen = activeSection === number;

    const handleSectionClick = () => {
      if (isOpen) {
        setActiveSection(null); // Close the current section
      } else {
        setActiveSection(number); // Open the clicked section
      }
    };
    

    return (
      <div className="collapsibleSection" ref={ref}>
        <div className="sectionHeader" onClick={handleSectionClick}>
          <span className="sectionNumber">{number}.</span>
          <span className="sectionTitle">{title}</span>
          <span className="indicator">{isOpen ? "-" : "+"}</span>
        </div>
        {isOpen && <div className="sectionContent">{children}</div>}
      </div>
    );
  }
);

export default CollapsibleSection;
