import React, { useState } from "react";
import ProviderSelection from "../../Providers/ProviderSelection";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserMd } from "@fortawesome/free-solid-svg-icons";
import HelpArticleLink from "../../Articles/HelpArticleLink";

const ProvidersMenu = ({ onClose }) => {
  const [showProviderSelection, setShowProviderSelection] = useState(false);

  const handleProviderSelectionClick = () => {
    setShowProviderSelection(true);
  };

  const handleProviderSelectionClose = () => {
    setShowProviderSelection(false);
  };

  const handleUnifiedProviderSelection = () => {};

  // Determine the button class
  const buttonClass = `MainActionButton ${
    showProviderSelection ? "selected" : ""
  }`;

  return (
    <>
   

      {!showProviderSelection && (
        <button
          id="ClaimStatusButton"
          onClick={handleProviderSelectionClick}
          className={buttonClass}
        >
          <FontAwesomeIcon icon={faUserMd} style={{ marginRight: "10px" }} />
          My Providers
        </button>
      )}

      {showProviderSelection && (
        <div className="Claims-inner-container">
          <div className="">
          <button
            className="filesCloseButton"
            onClick={handleProviderSelectionClose}
          >
            X
          </button>
          <div className="claimsMenus">
          <div className="centerHeader">
            <h3>Providers</h3>
            <p>Configure your providers.</p>
          </div>
            <ProviderSelection
              type="unified"
              onProviderSelect={handleUnifiedProviderSelection}
            />
            <div className="help-article-wide">
              <HelpArticleLink
                article={{
                  title: "Adding Providers",
                  link: "https://popularishealth.com/article/Adding-Providers",
                }}
              />
            </div>
          </div>
        </div>
        </div>
      )}
    </>
  );
};

export default ProvidersMenu;
