import React, { useState, useEffect } from "react";
import { db } from "../../../../firebase";
import { collection, getDocs, where, query, doc, getDoc, addDoc, setDoc } from "firebase/firestore";
import { useParams, useNavigate } from "react-router-dom";
import "../../AddPatient/AddPatient.modules.css";
import HexagonSpinner from "../../../General/Animations/Hexspinner";
import { getFunctions, httpsCallable } from "firebase/functions";
import ValidateSubform from "../../../PatientFiles/FormEditor/FormSelection/ValidateSubform";
import PersonalInformation from "./PersonalInformation";
import InsuranceInformation from "./InsuranceInformation";
import IntakeSuccess from "./IntakeSuccess";
import Appointments from "./Appointments/Appointments";
import FormDisplay from "../../../PatientFiles/FormEditor/FormSelection/FormDisplay";
import { Stepper, Step, StepLabel, Button, styled } from "@mui/material";
import ScrollToTop from "../../../../ScrollToTop";

const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

const functions = getFunctions();
const verifyRecaptcha = httpsCallable(functions, 'verifyRecaptcha');

function AddPatientIntakeForm() {
  let { clinic } = useParams() || {};
  const navigate = useNavigate(); // Hook for navigation

  const [success, setSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [token, setToken] = useState(null);
  const [activeStep, setActiveStep] = useState(0);
  const [enableAppointments, setEnableAppointments] = useState(false);
  const [clinicId, setClinicId] = useState(null);
  const [clinicData, setClinicData] = useState({});
  const [clinicExists, setClinicExists] = useState(false);
  const [error, setError] = useState([]);
  const [requiredFields, setRequiredFields] = useState({});
  const [formInputValues, setFormInputValues] = useState([]);
  const [appointmentData, setAppointmentData] = useState(null); // Changed to null initially
  const [insuranceData, setInsuranceData] = useState({
    memberId: "",
    selectedPayer: null,
    insuranceCardImage: null,
  });
  const [userData, setUserData] = useState({
    firstName: "",
    middleName: "",
    lastName: "",
    gender: "",
    dob: "",
    phone: "",
    email: "",
    address: {
      address1: "",
      address2: "",
      city: "",
      state: "",
      zip: "",
    },
  });
  const [companyLogoUrl, setCompanyLogoUrl] = useState(null);
  const [uid, setUid] = useState(null);

  const steps = [
    "Personal Information",
    "Insurance Information",
    enableAppointments ? "Appointments" : null,
    clinicData.formData?.length > 0 ? "Patient Intake" : null,
    "Complete"
  ].filter(Boolean); // Filter out null values if appointments are not enabled

  useEffect(() => {
    setError([]);
    setIsLoading(true);
    const fetchOwnerIdAndToken = async () => {
      try {
        // Search for the clinic document where the clinicNameURL matches the 'clinic' from useParams
        const clinicQuery = query(
          collection(db, "clinics"),
          where("clinicNameURL", "==", clinic)
        );
        const querySnapshot = await getDocs(clinicQuery);

        if (!querySnapshot.empty) {
          const clinicDocData = querySnapshot.docs[0].data();
          const uid = clinicDocData.ownerId;
          const clinicToken = querySnapshot.docs[0].id;

          setUid(uid)
          setClinicId(clinicToken);
          setClinicData(clinicDocData);

          setRequiredFields({
            firstName: true,
            lastName: true,
            gender: true,
            dob: true,
            address: clinicDocData.requireAddress ?? false,
            addInsuranceCardCamera:
              clinicDocData.addInsuranceCardCamera ?? false,
            payer: clinicDocData.requireInsuranceInfo ?? false,
            memberId: clinicDocData.requireInsuranceInfo ?? false,
            phone: clinicDocData.requirePhone ?? false,
          });

          setEnableAppointments(clinicDocData.appointmentsEnabled ?? false);

          setToken(clinicToken);
          setClinicExists(true);

          // Fetch company logo URL if it exists  
          const companyImageDocRef = doc(db, "customerCompanyImage", uid);
          const companyImageDoc = await getDoc(companyImageDocRef);

          if (companyImageDoc.exists() && companyImageDoc.data().imageUrl) {
            setCompanyLogoUrl(companyImageDoc.data().imageUrl);
          }
        } else {
          navigate('/404'); // Redirect to 404 if clinic does not exist
        }
      } catch (error) {
        console.error("Error getting document:", error);
        navigate('/404'); // Redirect to 404 on error
      }
      setIsLoading(false);
    };

    fetchOwnerIdAndToken();
  }, [clinic, navigate]);

  useEffect(() => {
    // Scroll to top whenever the activeStep changes
    window.scrollTo(0, 0);
  }, [activeStep]);

  const MobileStepperRoot = styled(Stepper)(({ theme }) => ({
    [theme.breakpoints.down("sm")]: {
      padding: 0,
      "& .MuiStepLabel-root": {
        padding: 0,
        "& .MuiStepLabel-label": {
          fontSize: "0.75rem", // Reduce font size for step labels
          display: "none"
        },
      },
      "& .MuiStepIcon-root": {
        fontSize: "1rem", // Reduce font size for step icons
      },
    },
  }));

  const handleUpdateUserData = (updatedUserData) => {
    setUserData(updatedUserData);
  };

  const validatePersonalInformation = () => {
    const {
      firstName,
      lastName,
      gender,
      dob,
      phone,
      email,
      address: { address1, city, state, zip } = {},
    } = userData || {};

    const minDate = new Date();
    minDate.setFullYear(minDate.getFullYear() - 200);

    const errors = [];

    if (requiredFields.firstName && !firstName) errors.push("First Name is required");
    if (requiredFields.lastName && !lastName) errors.push("Last Name is required");
    if (requiredFields.gender && !gender) errors.push("Gender is required");
    if (requiredFields.dob && (!dob || new Date(dob) >= new Date() || new Date(dob) < minDate)) errors.push("Invalid Date of Birth");
    if (requiredFields.phone && (!phone || !/^\(\d{3}\)\s\d{3}-\d{4}$/.test(phone))) errors.push("Invalid Phone Number");
    if (email && !/^\S+@\S+\.\S+$/.test(email)) errors.push("Invalid Email Address");
    if (requiredFields.address && !address1) errors.push("Address Line 1 is required");
    if (requiredFields.address && !city) errors.push("City is required");
    if (requiredFields.address && (!state || !/^[A-Z]{2}$/.test(state))) errors.push("Invalid State");
    if (requiredFields.address && (!zip || !/^\d{5}$/.test(zip))) errors.push("Invalid Zip Code");

    setError(errors);
    return errors.length === 0;
  };

  const handleAppointmentSelected = async (appointmentData) => {
    setAppointmentData(appointmentData);
    if (activeStep === 2) {
      await handleNext(true); // Pass a flag to indicate that the appointment was selected
    }
  };

  const saveAppointmentData = async (patientId) => {
    try {
      await addDoc(collection(db, `clinics/${clinicId}/appointments`), {
        ...appointmentData,
        patientId: patientId,
      });
    } catch (error) {
      console.error("Error saving the appointment: ", error);
      throw error; // Rethrow error to handle it in the saveIntake function
    }
  };

  const saveIntake = async () => {
    const encryptFunction = httpsCallable(functions, 'encrypt');

    const combinedData = {
      patient: {
        ...userData,
      },
      payers: {
        memberId: insuranceData?.memberId,
        name: insuranceData?.selectedPayer?.name,
        ...(insuranceData?.selectedPayer?.RealtimePayerID && { RealtimePayerID: insuranceData.selectedPayer.RealtimePayerID }),
        ...(insuranceData?.selectedPayer?.ClaimStatusPayerID && { ClaimStatusPayerID: insuranceData.selectedPayer.ClaimStatusPayerID }),
        ...(insuranceData?.selectedPayer?.CPID && { CPID: insuranceData.selectedPayer.CPID }),
      }
    };

    try {
      // Encrypting the combined data
      const encryptedData = await encryptFunction(combinedData);

      // Generating a new patient ID
      const patientId = doc(collection(db, `patients/${uid}/patientData`)).id;

      // Saving the encrypted data to Firebase under patients collection
      await setDoc(doc(db, `patients/${uid}/patientData/${patientId}`), {
        patient: {
          ciphertext: encryptedData.data.ciphertext,
          iv: encryptedData.data.iv,
        },
        clinicToken: token,
        deleted: false,
        flag: "intake",
      });

      // Encrypting form input values separately for intakeForm
      const encryptedFormData = await encryptFunction({ data: formInputValues });

      // Save appointment data if appointments are enabled
      if (enableAppointments) {
        await saveAppointmentData(patientId);
      }

      if (clinicData.formData?.length > 0) {
        await addDoc(collection(db, `clinics/${clinicId}/intakeForms`), {
          ciphertext: encryptedFormData.data.ciphertext,
          iv: encryptedFormData.data.iv,
          patientId: patientId,
        });
      }

      // Save insurance card image URL if it exists
      if (insuranceData.insuranceCardImage) {
        const insuranceCardFile = {
          url: insuranceData.insuranceCardImage,
          name: "Insurance Card",
          timestamp: new Date().toISOString(),
        };
        await setDoc(doc(db, `patients/${uid}/patientData/${patientId}/files`, "insuranceCardImage"), insuranceCardFile);
      }

      // Updating the state to show success
      setSuccess(true);
      return true; // Return true to indicate success
    } catch (error) {
      setError(['Error saving intake data.'], error);
      setSuccess(false); // Ensure success is set to false in case of error
      return false; // Return false to indicate failure
    } finally {
      setIsLoading(false);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleNext = async () => {
    if (activeStep === 0) {
        const isValid = validatePersonalInformation();
        if (!isValid) {
            return;
        }

        // Add reCAPTCHA verification on the first step
        setIsLoading(true);
        setError([]); // Clear errors
        try {
            const recaptchaToken = await window.grecaptcha.execute(process.env.REACT_APP_RECAPTCHA_SITE_KEY, { action: 'login' });
            const recaptchaResult = await verifyRecaptcha({ recaptchaToken });

            if (!recaptchaResult.data.success) {
                setError(['reCAPTCHA verification failed.']);
                setIsLoading(false);
                return;
            }
        } catch (error) {
            setError(['Error verifying reCAPTCHA.']);
            setIsLoading(false);
            return;
        }
        setIsLoading(false);
    }

    if (activeStep === 1) {
        const isValid = validateInsuranceInformation();
        if (!isValid) {
            return;
        }
    }

    if (activeStep === 3 || (activeStep === 2 && !clinicData.formData?.length)) {
        let subFormValidation = ValidateSubform(clinicData.formData, formInputValues);

        if (!subFormValidation.isValid) {
            setError([subFormValidation.message]);
            return;
        }

        // Trigger saveIntake on form submission
        setIsLoading(true);
        try {
            await saveIntake(); // Ensure saveIntake is awaited
        } catch (error) {
            setError(['Error during saving process.']); // Handle potential errors from saveIntake
        }
        setIsLoading(false); // This will execute after saveIntake completes
        return; // This prevents further execution as saveIntake should handle navigation
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setError([]);
};

const validateInsuranceInformation = () => {
    const { memberId, selectedPayer } = insuranceData;
    const errors = [];

    if (requiredFields.memberId && !memberId) errors.push("Member ID is required");
    if (requiredFields.payer && !selectedPayer) errors.push("Payer is required");

    setError(errors);
    return errors.length === 0;
};


  useEffect(() => {
    if (success) {
      setActiveStep(steps.length - 1); // Move to the confirmation step
    }
  }, [success, steps.length]);


  const getStepContent = (stepIndex) => {
    switch (stepIndex) {
      case 0:
        return (
          <div className="pt-5">
            {companyLogoUrl && (
              <img src={companyLogoUrl} alt="Company Logo" className="company-logo" style={{ maxWidth: "200px", marginBottom: "20px" }} />
            )}
            <PersonalInformation
              requiredFields={requiredFields}
              UserData={userData || {}}
              onUpdateUserData={handleUpdateUserData}
              errors={error}
            />
          </div>
        );
      case 1:
        return (
          <div className="pt-5">
            <InsuranceInformation               
              requiredFields={requiredFields}
              onInsuranceInfoChange={setInsuranceData} 
            />
          </div>
        );
      case 2:
        return (
          enableAppointments && (
            <div className="pt-5">
              <Appointments
                clinicId={clinicId}
                onAppointmentSelected={handleAppointmentSelected}
              />
            </div>
          )
        );
      case 3:
        return (
          clinicData.formData?.length > 0 ? (
            <div className="pt-5">
              <FormDisplay
                formData={clinicData.formData}
                onFormInputChange={setFormInputValues}
                formInputValuesInit={formInputValues}
              />
            </div>
          ) : (
            <IntakeSuccess appointmentData={appointmentData} location={clinicData.location} />
          )
        );
      case 4:
        return <IntakeSuccess appointmentData={appointmentData} location={clinicData.location} />;
      default:
        return "Unknown Step";
    }
  };



  return (
    <div className="IPA-background">
      <ScrollToTop />
      <div className="grid-container-lg">
        <div className="main-form">
          {/* Stepper UI */}
          <MobileStepperRoot activeStep={activeStep} alternativeLabel>
            {steps.map((label, index) => (
              <Step key={index}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </MobileStepperRoot>

          {/* Current Step Content */}
          {getStepContent(activeStep)}

          <div>
            {Array.isArray(error) && error.length > 0 && (
              <div className="error-message mt-4">
                {error.map((errorMessage, index) => (
                  <p key={index}>{errorMessage}</p>
                ))}
              </div>
            )}
          </div>

          {activeStep < steps.length - 1 && (
            <div className="display-flex mt-4 pt-4 px-3">
        
              {activeStep > 0 && (
                <Button onClick={handleBack}>
                  Back
                </Button>
              )}
              {isLoading ? (
                <div className="w-100 m-auto">
                  <HexagonSpinner />
                </div>
              ) : (
                !(enableAppointments && activeStep === 2) && (
                  <Button variant="contained" onClick={handleNext} disabled={isLoading}>
                    Next
                  </Button>
                )
              )}
            </div>
          )}

        </div>
      </div>
    </div>
  );
}

export default AddPatientIntakeForm;
