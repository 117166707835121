import React, { useState, useEffect } from "react";
import { db } from "../../../firebase";
import { getDoc, doc, updateDoc } from "firebase/firestore";
import ReactModal from "react-modal";
import FormEditor from "../FormEditor/FormEditor/FormEditor";

const ClinicEditModal = ({ selectedClinic, setSelectedClinic, onClose }) => {
  const [formData, setFormData] = useState(selectedClinic.formData);

  const handleClose = () => {
    setSelectedClinic(null);
    setFormData(null);
    onClose();
  };

  const handleFormDataChange = (newFormData) => {
    setFormData(newFormData);
  };

  const handleSaveData = async () => {
    try {
      const clinicRef = doc(db, "clinics", selectedClinic.id);
      await updateDoc(clinicRef, {
        formData: formData,
      });
      onClose();
    } catch (error) {
      console.error("Error updating clinic:", error);
    }
  };

  return (
    <ReactModal
      isOpen={!!selectedClinic}
      onRequestClose={() => setSelectedClinic(null)}
      className="clear"
      onClick={() => handleClose()}
    >
      <div className="overlay">
        <div className="modalBox">
          <div className="modalContainer intake">
            <button className="filesCloseButton" onClick={() => handleClose()}>
              X
            </button>

            <h3 className="mt-4">Edit Clinic Intake</h3>
            <FormEditor
            className="intake"
              saveLocation="0"
              initialFormElementsData={formData}
              onFormElementsDataChange={handleFormDataChange}
              handleSave={handleSaveData}
            />
          </div>
        </div>
      </div>
    </ReactModal>
  );
};

export default ClinicEditModal;
