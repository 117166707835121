import React, { useEffect, useState, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/free-solid-svg-icons';

// Predefined hashes for the messages
const initialMessages = [
  {
    id: 1,
    hash: 'e3b0c44298fc1c149afbf4c8996fb92427ae41e4649b934fbf4c8996fb92427ae41e4649b934ca4912sADs1ca4959!@91b7852b8fbf4c8996',
    decryptedMessage: "John Doe is showing symptoms that suggests an investigation might be needed.",
    timestamp: new Date('2024-03-01T08:00:00'),
    senderName: "Jane Doe, NP"
  },
  {
    id: 2,
    hash: '5d41402abc4b2a76b9719d911017c592e3b0c44298fc1c149afbf4c8996fb92427ae41e4649b934ca4fbf4c8995991b7852b855',
    decryptedMessage: "Agreed. His last CT scan hinted at something we couldn't fully diagnose.",
    timestamp: new Date('2024-03-01T08:05:00'),
    senderName: "Clementine Churchill, MD"
  },
  {
    id: 3,
    hash: '7b0f357',
    decryptedMessage: "Thanks.",
    timestamp: new Date('2024-03-01T08:10:00'),
    senderName: "Jane Doe, NP"
  },
  {
    id: 4,
    hash: '3c59dc048e8850243be8C079a#!fbf4c8996fb92427ae42j1e4634ca4d079',
    decryptedMessage: "Also, ask them to get a functional resting state scan as well.",
    timestamp: new Date('2024-03-01T08:15:00'),
    senderName: "Clementine Churchill, MD"
  },
  {
    id: 5,
    hash: '5eb63bbbe01eeed093cb22bb8fbf4c8995acdc3',
    decryptedMessage: "Okay, I'll suggest Lenox Hill for that.",
    timestamp: new Date('2024-03-01T08:20:00'),
    senderName: "Jane Doe, NP"
  }
];

// Function to pad the hash to the same length as the decrypted message
const padMessage = (message, length) => {
  if (message.length < length) {
    return message.padEnd(length, ' ');
  }
  return message;
};

function Messages({ triggerAnimation }) {
  const [messages, setMessages] = useState([]);
  const [showDecrypted, setShowDecrypted] = useState({});
  const [animating, setAnimating] = useState({});

  const handleTransitionEnd = (id) => {
    setShowDecrypted((prev) => ({
      ...prev,
      [id]: true
    }));

    setAnimating((prev) => ({
      ...prev,
      [id]: false
    }));
  };

  // Function to add messages with a delay
  const simulateConversation = () => {
    // Function to add a message with a delay
    const addMessageWithDelay = (message, delay) => {
      setTimeout(() => {
        setMessages((prevMessages) => [...prevMessages, message]);

        // Start decrypt cycle
        setTimeout(() => startDecryptCycle(message.id), delay + 1000);
      }, delay);
    };

    // Loop through initialMessages and schedule them with increasing delay
    initialMessages.forEach((message, index) => {
      // For example, adding a 1000ms delay between each message
      addMessageWithDelay(message, index * 1000);
    });
  };

  const startDecryptCycle = (id) => {
    setAnimating((prevState) => ({
      ...prevState,
      [id]: true
    }));

    // Set timeout for the animation duration
    setTimeout(() => {
      setShowDecrypted((prevState) => ({
        ...prevState,
        [id]: true
      }));
      setAnimating((prevState) => ({
        ...prevState,
        [id]: false
      }));
    }, 500); // Duration of the fade-out animation
  };

  useEffect(() => {
    if (triggerAnimation) {
      setMessages([]); // Clear existing messages before starting
      simulateConversation();
    }
  }, [triggerAnimation]);

  const messagesEndRef = useRef(null);

  const renderMessage = (message) => {
    const isCurrentUser = message.senderName === "Jane Doe, NP"; // Example condition
    const messageClass = isCurrentUser ? 'message sent' : 'message received';
    const messageTextClass = animating[message.id] ? 'message-fadeOut' : showDecrypted[message.id] ? 'message-visible' : 'message-hidden';
    const displayText = showDecrypted[message.id] ? message.decryptedMessage : padMessage(message.hash, message.decryptedMessage.length);

    return (
      <div key={message.id} className={messageClass}>
        <div className="message-senderName">{message.senderName}</div>
        <div
          className={`message-messageText ${messageTextClass}`}
          onAnimationEnd={() => handleTransitionEnd(message.id)}
        >
          {!showDecrypted[message.id] ? (
            <span className="message-wipe" data-text={displayText}>
              {displayText}
              <FontAwesomeIcon icon={faLock} className="message-lockIcon" />
            </span>
          ) : (
            <span className="message-wipe" data-text={displayText}>{displayText}</span>
          )}
        </div>
        <div className="message-timestamp">{message.timestamp.toLocaleString()}</div>
      </div>
    );
  };

  return (
    <div className="MessageContainer">
      <div className="messagesList">
        {messages.map(renderMessage)}
        <div ref={messagesEndRef} /> {/* Scroll target */}
      </div>
    </div>
  );
}

export default Messages;
