import React, { useEffect, useState } from "react";
import InputTypeText from "../FormElements/SubInputTypes/InputTypeText";
import InputTypeTextArea from "../FormElements/SubInputTypes/InputTypeTextArea";
import InputTypeHeader from "../FormElements/SubInputTypes/InputTypeHeader";
import InputTypeParagraph from "../FormElements/SubInputTypes/InputTypeParagraph";
import InputTypeSelectState from "../FormElements/SubInputTypes/InputTypeSelectState";
import InputTypeChoice from "../FormElements/SubInputTypes/InputTypeChoice";
import InputTypeSignature from "../FormElements/SubInputTypes/InputTypeSignature";
import InputTypeLine from "../FormElements/SubInputTypes/InputTypeLine";

import InputTypeSpacer from "../FormElements/SubInputTypes/InputTypeSpacer";

const FormDisplay = ({
  formData,
  onFormInputChange = () => {},
  formInputValuesInit = [],
}) => {
  const [formInputValues, setFormInputValues] = useState(
    formInputValuesInit.length === 0
      ? JSON.parse(JSON.stringify(formData))
      : formInputValuesInit
  );
  
  useEffect(()=>{
    setFormInputValues(JSON.parse(JSON.stringify(formData)));
  }, [formData])

  const handleInputChange = (event, formElementIndex, fieldIndex) => {
    const { name, value } = event.target;
    setFormInputValues((prevValues) => {
      const updatedValues = JSON.parse(JSON.stringify(prevValues));
      updatedValues[formElementIndex].fields[fieldIndex].value = value;
      onFormInputChange(updatedValues);
      return updatedValues;
    });
  };

  const handleOptionChange = (formElementIndex, fieldIndex, option) => {
    setFormInputValues((prevValues) => {
      const updatedValues = JSON.parse(JSON.stringify(prevValues));
      const fieldItem = updatedValues[formElementIndex].fields[fieldIndex];

      if (fieldItem.multiple) {
        //Multiple choice:
        const selectedOptions = fieldItem.value
          ? fieldItem.value.split(", ").filter(Boolean)
          : [];

        if (selectedOptions.includes(option)) {
          updatedValues[formElementIndex].fields[fieldIndex].value =
            selectedOptions.filter((opt) => opt !== option).join(", ");
        } else {
          updatedValues[formElementIndex].fields[fieldIndex].value = [
            ...selectedOptions,
            option,
          ].join(", ");
        }
      } else {
        // Single choice: Enforce only one option selected
        if (fieldItem.value === option) {
          updatedValues[formElementIndex].fields[fieldIndex].value = "";
        } else {
          updatedValues[formElementIndex].fields[fieldIndex].value = option;
        }
      }

      onFormInputChange(updatedValues);
      return updatedValues;
    });
  };

  return (
    <div className="">
      <form>
        {formInputValues.map((formElement, formElementIndex) => (
          <div
            className="input-group-row w-100 mb-0 FormRow"
            key={formElement.id}
          >
            {formElement.fields.map((fieldItem, fieldIndex) => (
              <React.Fragment key={fieldIndex}>
                <div className="input-field pt-2 FormElement">
                  {(() => {
                    const components = {
                      textarea: InputTypeTextArea,
                      state: InputTypeSelectState,
                      header: InputTypeHeader,
                      paragraph: InputTypeParagraph,
                      choice: InputTypeChoice,
                      signature: InputTypeSignature,
                      line: InputTypeLine,
                      Spacer: InputTypeSpacer,
                    };
                    const Component =
                      components[fieldItem.type] || InputTypeText;
                    return (
                      <Component
                        fieldItem={fieldItem}
                        handleInputChange={(event) =>
                          handleInputChange(event, formElementIndex, fieldIndex)
                        }
                        handleOptionChange={handleOptionChange}
                        formElementIndex={formElementIndex}
                        fieldIndex={fieldIndex}
                        preview={false}
                        formElementId={formElement.id}
                        value={fieldItem.value || ""}
                      />
                    );
                  })()}
                </div>
              </React.Fragment>
            ))}
          </div>
        ))}
      </form>
    </div>
  );
};

export default FormDisplay;
