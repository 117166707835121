import React, { useState, useEffect } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { Link } from 'react-router-dom';
import { db } from '../../firebase';
import { htmlToText } from 'html-to-text';
import Header from '../Website/subcomponents/Header';
import Footer from '../Website/subcomponents/Footer';

const Blog = () => {
  const [articles, setArticles] = useState([]);

  useEffect(() => {
    const fetchArticles = async () => {
      const articlesCollection = collection(db, 'articles');
      const articleSnapshot = await getDocs(articlesCollection);
      const articleList = articleSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      })).filter(article => article.category === 'blog');

      setArticles(articleList);
    };

    fetchArticles();
  }, []);

  return (
    <div className="claims-page">
      <Header />
      <div className="claims-content">
        <div className="hero-section-sub-page">
          <div className="golden-container">
            <h1 className="hero-title">Our Blog</h1>
            <p className="hero-subtitle">Explore our latest articles and insights</p>
          </div>
        </div>
        <div className="golden-container">
          <div className="features-section">
            {articles.map((article) => (
              <div key={article.id} className="feature">
                <div className="feature-content">
                  <h2 className="feature-title">{article.title}</h2>
                  <p className="feature-description">
                    {htmlToText(article.article.match(/<p>(.*?)<\/p>/)[0], {
                      wordwrap: null,
                      ignoreHref: true,
                      ignoreImage: true,
                      hideLinkHrefIfSameAsText: true,
                      uppercaseHeadings: false,
                      singleNewLineParagraphs: true,
                      ignoreTags: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'p', 'div']
                    }).slice(0, 150)}...
                  </p>
                  <Link to={`/article/${article.id}`} className="cta-button">Read More</Link>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Blog;