import React, { useState } from "react";
import Switch from "react-switch";

const InputTypeChoice = ({
  fieldItem,
  isVisible = true,
  preview = true,
  handleInputChange,
  formElementId,
  handleOptionChange,
  formElementIndex,
  fieldIndex,
}) => {
  const [selectedOptions, setSelectedOptions] = useState(
    fieldItem.value ? fieldItem.value.split(", ").filter(Boolean) : []
  );

  const handleOptionClick = (option) => {
    handleOptionChange(formElementIndex, fieldIndex, option);
    setSelectedOptions((prevSelectedOptions) => {
      if (fieldItem.multiple) {
        if (prevSelectedOptions.includes(option)) {
          return prevSelectedOptions.filter((opt) => opt !== option);
        } else {
          return [...prevSelectedOptions, option];
        }
      } else {
        if (prevSelectedOptions.includes(option)) {
          return [];
        } else {
          return [option];
        }
      }
    });
  };

  return (
    <>
      {isVisible && (
        <div>
          <label>
            {fieldItem?.label}
            <span className="RequiredMark">
              {fieldItem?.required && " *"}
            </span>
          </label>
          <div>
            {Array.isArray(fieldItem?.options) && fieldItem?.options.length > 0 ? (
              fieldItem?.options.map((option, index) => (
                <div key={index} className="ChoiceOption">
                  <Switch
                    disabled={preview}
                    checked={selectedOptions.includes(option)}
                    onChange={() => handleOptionClick(option)}
                    offColor="#ccc"
                    onColor="#00316f"
                    uncheckedIcon={false}
                    checkedIcon={false}
                  />
                  <label className="pt-3 ml-3">{option}</label>
                </div>
              ))
            ) : (
              <div>No options available</div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default InputTypeChoice;