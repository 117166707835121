import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from "react-ga4";

// Initialize Google Analytics
ReactGA.initialize(process.env.REACT_APP_REACTGA);

const GoogleAnalytics = () => {
  const location = useLocation();

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: location.pathname + location.search });
  }, [location]);

  return null;
};

export default GoogleAnalytics;
