import React from 'react';

const InputTypeLine = ({ fieldItem, isVisible = true, preview = true }) => {

    return (
        <>
            {isVisible && (
                <div className='m-auto w-100'>
                    <hr className='formLine'
                        style={{
                            height: `${fieldItem?.default}px`,
                        }}
                        color={fieldItem.color} />
                </div>
            )}
        </>
    );
};

export default InputTypeLine;