import React, { useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCalendarAlt,
  faClipboardCheck,
  faNotesMedical,
  faFileMedicalAlt,
  faFileInvoiceDollar,
} from '@fortawesome/free-solid-svg-icons';
import DemoScrollVideo from '../assets/ScrollDemo.mp4';
import DemoScrollVideo720 from '../assets/ScrollDemo720.mp4';

const AllInOneSection = () => {
  const sectionRef = useRef(null);
  const videoRef = useRef(null);
  const [isVisible, setIsVisible] = useState({});
  const [isMobile, setIsMobile] = useState(window.innerWidth < 600);
  const [activeIndex, setActiveIndex] = useState(0);
  const [blurIntensity, setBlurIntensity] = useState(0);
  
  const points = [
    {
      number: "01",
      capsule: "PREVISIT",
      title: "Appointment Created",
      description: "Our custom intake forms integrate with appointments to streamline patient onboarding.",
      featureList: [
        "Appointment and scheduling system",
        "Templates customizable for any specialty",
        "Mobile-friendly design for easy access",
        "Branding with your company’s logo"
      ],
      icon: faCalendarAlt,
    },
    {
      number: "02",
      capsule: "PREVISIT",
      title: "Clinical staff intakes them",
      description: "Instant eligibility verification ensures that patients meet criteria before their visit.",
      featureList: [
        "Run 1,500 patients at a time through batch eligibility",
        "One-click eligibility verification from the patient's file",
        "Check multiple insurance companies simultaneously for discovery"
      ],
      icon: faClipboardCheck,
    },
    {
      number: "03",
      capsule: "DURING VISIT",
      title: "Encounter is created",
      description: "The main method for clinical staff to document their interactions with patients.",
      featureList: [
        "Custom forms allow a diverse set of encounters",
        "Integration with billing codes for easy follow-up billing",
        "API callbacks allow encounters to be sent to labs, reporting systems, or other third parties"
      ],
      icon: faNotesMedical,
    },
    {
      number: "04",
      capsule: "DURING VISIT",
      title: "Notes and Medical History",
      description: "Our AI doctor’s note generator and intuitive medical history entry streamline documentation.",
      featureList: [
        "Generate traditional or AI-generated notes with simple prompting",
        "Securely store a wide range of medical file types",
        "Medical history, family history, immunizations and allergies, lab and test results, medications, vitals"
      ],
      icon: faFileMedicalAlt,
    },
    {
      number: "05",
      capsule: "POST VISIT",
      title: "Send Claims",
      description: "Simplify the claims process with automated submission and tracking.",
      featureList: [
        "Batch similar claims with ease",
        "Real-time claim status updates",
        "Real-time billing dashboard"
      ],
      icon: faFileInvoiceDollar,
    },
  ];
 
  useEffect(() => {
    const handleResize = () => {
        setIsMobile(window.innerWidth < 600);
    };

    let scrollTimeout = null;
    let isScrolling = false;
    let currentScrollPosition = 0;

    const sigmoidEase = (t) => 1 / (1 + Math.exp(-15 * (t - 0.5)));
    const lerp = (start, end, factor) => (1 - factor) * start + factor * end;

    const applyFrameBlending = (currentTime, targetTime) => {
        const blendedTime = lerp(currentTime, targetTime, 0.2); // Higher blending for smoother transitions
        videoRef.current.currentTime = blendedTime;
    };

    const handleScroll = () => {
        if (!isScrolling) {
            requestAnimationFrame(updateVideoScroll);
            isScrolling = true;
        }
    };

    const updateVideoScroll = () => {
        const section = sectionRef.current;
        const video = videoRef.current;

        if (!section || !video) return;

        const { top, bottom, height } = section.getBoundingClientRect();
        const windowHeight = window.innerHeight;
        const scrollStartOffset = 850;
        const endOffset = -200;

        const inView = top < windowHeight && bottom > 0;
        setIsVisible((prev) => ({ ...prev, parallax: inView }));

        const adjustedTop = top + scrollStartOffset;
        const adjustedHeight = height + endOffset;

        if (inView) {
            let targetScrollPosition = Math.min(Math.max((windowHeight - adjustedTop) / adjustedHeight, 0), 1);

            targetScrollPosition = sigmoidEase(targetScrollPosition);

            // Adjust smoothness factor for mobile vs desktop
            const smoothFactor = isMobile ? 0.15 : 0.2;
            currentScrollPosition = lerp(currentScrollPosition, targetScrollPosition, smoothFactor);

            applyFrameBlending(video.currentTime, currentScrollPosition * video.duration);

            const index = Math.floor(currentScrollPosition * points.length);
            setActiveIndex(Math.min(index, points.length - 1));

            if (video.paused) video.play();
        } else {
            video.pause();
        }

        isScrolling = false;

        clearTimeout(scrollTimeout);
        scrollTimeout = setTimeout(() => {
            video.pause();
        }, 100);
    };

    window.addEventListener('resize', handleResize);
    window.addEventListener('scroll', handleScroll);

    return () => {
        window.removeEventListener('resize', handleResize);
        window.removeEventListener('scroll', handleScroll);
        clearTimeout(scrollTimeout);
    };
}, []);
    

  return (
    <section className="allinone-section bg-white" ref={sectionRef} id="all-in-one">
      <div className="allinone-container">
        {isMobile ? (
          <div className="mobile-only">
            <div className={`mobile-only-title ${activeIndex >= 4 ? 'paddedTitle' : ''}`}>
              <FontAwesomeIcon icon={points[activeIndex].icon} className="mobile-title-icon" />
              <span>{points[activeIndex].title}</span>
            </div>
            <p className={`mobile-description ${activeIndex >= 4 ? 'paddedDescription' : ''}`}>
              {points[activeIndex].description}
            </p>
                <video
                ref={videoRef}
                src={isMobile ? DemoScrollVideo720 : DemoScrollVideo}
                muted
                playsInline
                preload="auto"
                className={`mobile-only-video ${activeIndex >= 4 ? 'paddedVideo' : ''}`}
                ></video>
            <ul className={`mobile-feature-list ${activeIndex >= 4 ? 'paddedFeatures' : ''}`}>
              {points[activeIndex].featureList.map((feature, idx) => (
                <li key={idx}>{feature}</li>
              ))}
            </ul>
          </div>
        ) : (
          <>
            <h2 className={`section-title ${isVisible.parallax ? 'animate' : ''}`}>
              All-in-One Patient Management
            </h2>
            <div className="allinone-content">
              <div className="allinone-list">
                {points.map((point, index) => (
                  <div
                    key={index}
                    className={`all-in-one-item ${isVisible.parallax ? 'animate' : ''}`}
                    style={{ animationDelay: `${index * 0.2}s` }}
                  >
                    <div className="number-container">
                      <span className="large-number">{point.number}</span>
                    </div>
                    <div className="capsule-container">
                      <div className="capsule-label">{point.capsule}</div>
                      <div className="section-content">
                        <h3 className="AIO-section-title">
                          <FontAwesomeIcon icon={point.icon} className="icon-gradient" />
                          {point.title}
                        </h3>
                        <p className="section-description-left">{point.description}</p>
                        <ul className="feature-list">
                          {point.featureList.map((feature, idx) => (
                            <li key={idx}>{feature}</li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div className="allinone-video">
                <video ref={videoRef} muted playsInline preload="auto" className="allinone-sticky-video">
                  <source src={isMobile ? DemoScrollVideo720 : DemoScrollVideo} type="video/mp4" />
                </video>
              </div>
            </div>
          </>
        )}
      </div>
    </section>
  );
};

export default AllInOneSection;