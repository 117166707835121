import React, { useState, useEffect } from "react";
import { getDatabase, ref, onValue, off } from "firebase/database";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import HexagonSpinner from "../../General/Animations/Hexspinner"; // Assuming this is used elsewhere or removed if not necessary
import useUID from "../../General/useUID";

const ClaimHistogram = () => {
  const [loading, setLoading] = useState(true);
  const [claimHistogramData, setClaimHistogramData] = useState([]);
  const [timeFrame, setTimeFrame] = useState("1w");
  const [uid] = useUID();

  useEffect(() => {
    if (!uid) return;

    setLoading(true);
    const database = getDatabase();
    const histogramRef = ref(database, `aggregateClaimsHistogram/${uid}`);

    const unsubscribe = onValue(
      histogramRef,
      (snapshot) => {
        const rawData = snapshot.val();

        if (rawData && Array.isArray(rawData)) {
          // Format and sort data
          const formattedAndSortedData = rawData
            .map((item) => ({
              ...item,
              date: formatDate(item.date), // Convert date format
            }))
            .sort((a, b) => new Date(a.date) - new Date(b.date)); // Sort by date

          setClaimHistogramData(formattedAndSortedData);
        } else {
          console.error("Unexpected data format from Realtime Database");
          setClaimHistogramData([]);
        }
        setLoading(false);
      },
      (error) => {
        // console.error(
        //   "Error fetching histogram data from Realtime Database:",
        //   error
        // );
        setLoading(false);
      }
    );

    return () => off(histogramRef, "value", unsubscribe);
  }, [uid]);

  const formatDate = (dateString) => {
    const [year, month, day] = dateString.split("-");
    return `${month}/${day}/${year}`;
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div
          className="custom-tooltip"
          style={{
            backgroundColor: "#fff",
            padding: "5px",
            border: "1px solid #ccc",
          }}
        >
          <p>{label}</p>
          <p>Number of Claims Submitted: {payload[0].payload.numberOfClaims}</p>
          <p>
            Total Amount Submitted: ${payload[0].payload.totalAmount.toFixed(2)}
          </p>
        </div>
      );
    }

    return null;
  };

  if (loading) {
    return <div></div>; // You can use HexagonSpinner here if it's intended for loading indication
  }

  return (
    <div className="SingleChart">
      <h3 style={{ textAlign: "center" }}>Claims Sent</h3>
      <div
        style={{ display: "flex", justifyContent: "center", margin: "10px 0" }}
      >
        <select
          value={timeFrame}
          onChange={(e) => setTimeFrame(e.target.value)}
        >
          <option value="1w">1 Week</option>
          <option value="1m">1 Month</option>
          <option value="1y">1 Year</option>
        </select>
      </div>
      <ResponsiveContainer width="100%" height={300}>
        <BarChart
          data={claimHistogramData}
          margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" />
          <YAxis />
          <Tooltip content={<CustomTooltip />} />
          <Bar dataKey="numberOfClaims" fill="#00316f" />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default ClaimHistogram;
