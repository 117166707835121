import React, { useState, useEffect, useRef } from 'react';
import '../Website.css';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { Helmet } from 'react-helmet';
import { faHeart, faFileInvoiceDollar, faLock, faHeadset, faDesktop, faClock, faCheckCircle, faPlug, faClipboardList, faGlobeAmericas, faServer } from '@fortawesome/free-solid-svg-icons'; // Importing necessary icons

import logo from '../../../popularis_logos/Popularis_logo_wide.png';
import heroImage from '../assets/heroImage.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import hipaa from '../assets/hipaa-compliance.png';
import FeaturesList from '../subcomponents/FeaturesList';
import doc1 from '../assets/doc1.png';
import doc2 from '../assets/doc2.png';
import doc3 from '../assets/doc3.png';
import Hero from '../assets/finalHero2.mp4';
import { Link, useLocation } from 'react-router-dom';
import Patients from '../assets/iPad_patients.png'

const SalesPage = ({ onClose, onSubmitted }) => {
  const [name, setName] = useState(localStorage.getItem('name') || '');
  const [email, setEmail] = useState(localStorage.getItem('email') || '');
  const [date, setDate] = useState(localStorage.getItem('date') || '');
  const [time, setTime] = useState(localStorage.getItem('time') || '');
  const [message, setMessage] = useState(localStorage.getItem('message') || '');
  const [preferredContact, setPreferredContact] = useState(localStorage.getItem('preferredContact') || '');
  const [errors, setErrors] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get('token') || localStorage.getItem('token');
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isVisible, setIsVisible] = useState({});
  const sectionRefs = useRef({});
  const [isButtonVisible, setIsButtonVisible] = useState(false);


  const testimonials = [
    {
      image: doc1, // Use doc1 image here
      quote: "With the API queue and encounters system, sending lab orders has never been easier. It's a must-have for any provider.",
      name: "Dr. James Mandelbrot"
    },
    {
      image: doc2, // Use doc2 image here
      quote: "The seamless claims process and real-time eligibility checks have saved us countless hours and brought in 20% more revenue.",
      name: "Dr. Alina Boran"
    },
    {
      image: doc3, // Use doc3 image here
      quote: "We have never had batch billing before, it is a game-changer.",
      name: "Nurse Practitioner Alexis Crews"
    },
  ];

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsButtonVisible(true);
    }, 500);
    return () => clearTimeout(timer);
  }, []);

  const handleNavigation = (page) => {
    if (email) {
      if (!validateEmail(email)) {
        alert('Please enter a valid email address.');
      } else {
        navigate(`/${page}?email=${encodeURIComponent(email)}`);
      }
    } else {
      navigate(`/${page}`);
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex(prevIndex => (prevIndex + 1) % testimonials.length);
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  const validateEmail = (email) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  return (
    <div className="landing-page">
      <Helmet>
        <title>Labs | Popularis</title>
        <meta name="description" content="Labs love Popularis" />
        <meta name="keywords" content="labs, Popularis" />
        <meta property="og:title" content="labs Request" />
        <meta property="og:description" content="Labs love Popularis." />
        <meta property="og:image" content="https://firebasestorage.googleapis.com/v0/b/popularishealth.appspot.com/o/Popularis_logo_single.png?alt=media&token=e079bdf2-360b-42da-9ef2-1cdbdeb474cf" />
      </Helmet>
      <div>
        <main className="landing-content">

              <div className="signup-text-container">
            <a href="/" className="logoWrapper">
            <img src={logo} alt="Logo" className="logo" />
            </a>
        
            </div>

            <section className="hero-section" ref={(el) => (sectionRefs.current['hero'] = el)} id="hero">
          <div className="hero-wrapper">
            <div className='animated-hero'>
              <video
                src={Hero}
                autoPlay
                playsInline
                muted
                loop
                x-webkit-airplay="deny"
                title="Animation showing different user types such as doctor, nurse, tech, or lab worker surrounded by the icons of the apps the user uses most."
                width="388"
                height="388"
              ></video>
            </div>
      
            <div className='hero-text'>
              <h1>An EMR Made for Working with Laboratories </h1>
            </div>
          </div>
          <div className='hero-signin'>
            <Link to="/signup" className="login-btn-hero">
              <>
                Sign Up
              </>
            </Link>
          </div>
          <div className="hero-text">
            <p className={`hero-description ${isVisible['hero'] ? 'animate' : ''}`}>
            Sign up for a paid plan, and we will make the integration free. We processes hundreds of lab orders every day. </p>
          </div>
        </section>

                <section>
                <div className={`hero-image-container ${isVisible['hero'] ? 'animate' : ''}`}>
                    <img src={Patients} alt="Comprehensive patient management interface" className="hero-image" />
                </div>

                </section>


     {/* Process Section */}
     <section className="process-section bg-white" ref={(el) => (sectionRefs.current['process'] = el)} id="process">
          <div className="golden-container">
            <h2 className={`section-title ${isVisible['process'] ? 'animate' : ''}`}>
              Simply, we do three things...
            </h2>
            <div className="process-steps">
              {[
                {
                  title: 'Manage Patients',
                  description: "User-friendly and clinically approved patient app lets providers focus on patient care.",
                  icon: faHeart, // Heart icon for patients
                },
                {
                  title: 'Get You Paid',
                  description: "Send batches of claims and ensure you're going to get paid with realtime eligibility with over 3,500 payers.",
                  icon: faFileInvoiceDollar, // Invoice icon for payments
                },
                {
                  title: 'Keep your data safe',
                  description: 'We are the first EMR to offer encryption for all data, ensuring top security and confidentiality.',
                  icon: faLock, // Lock icon for data safety
                },
              ].map((step, index) => (
                <div
                  className={`step-3 ${isVisible['process'] ? 'animate' : ''}`}
                  key={index}
                  style={{ animationDelay: `${index * 0.2}s` }}
                >
                  <FontAwesomeIcon icon={step.icon} className="step-icon" /> {/* Use the updated icons */}
                  <h3 className="step-title">{step.title}</h3>
                  <p className="step-description">{step.description}</p>
                </div>
              ))}
            </div>
            <div className='cta-container-center'>
              <div className={`cta-buttons px-0 mt-4 ${isVisible['hero'] ? 'animate' : ''} ${isButtonVisible ? 'visible' : 'hidden'}`}>
                <input
                  type="email"
                  placeholder="Email"
                  value={email}
                  className="email"
                  onChange={(e) => setEmail(e.target.value)}
                />
                <button className="cta-button" onClick={() => handleNavigation('signup')}>
                  Try for Free
                </button>
                <button className="cta-button" onClick={() => handleNavigation('demo')}>
                  Book a Demo
                </button>
              </div>
              <div className="hero-disclaimer-container">
                <p className={`hero-disclaimer ${isVisible['hero'] ? 'animate' : ''}`}>
                  Immediate access - No credit card required
                </p>
              </div>
            </div>
          </div>
        </section>

               {/* Testimonial Section */}
          <section className="testimonial-section bg-white" ref={(el) => (sectionRefs.current['testimonials'] = el)} id="testimonials">
            <div className="testimonial-container">
              <h2 className="section-title px-5vw">What Our Clients Say</h2>
              <div className="carousel">
                {testimonials.map((testimonial, index) => (
                  <div
                    className={`testimonial-card ${index === currentIndex ? 'active' : ''}`}
                    key={index}
                  >
                    <div className="testimonial-image-container">
                      <img src={testimonial.image} alt={testimonial.name} className="testimonial-image" />
                    </div>
                    <p className="testimonial-quote">"{testimonial.quote}"</p>
                    <p className="testimonial-name">- {testimonial.name}</p>
                  </div>
                ))}
              </div>
            </div>
          </section>
          
          <section className="bg-white">
              <div className="golden-container">
                <h2 className={`section-title ${isVisible['demo'] ? 'animate' : ''}`}>
                  A demo of Popularis
                </h2>
                <div className="video-container">
                  <iframe
                    width="560"
                    height="315"
                    src="https://www.youtube.com/embed/DKn5ix1xaPc?start=1"
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                </div>
              </div>
            </section>

            <section className="features-section-sales">
                <div className="golden-container">
                <  FeaturesList/>
                </div>
            </section>

            
              <section  style={{ paddingBottom: "3rem" }}className="bg-white-white" ref={el => sectionRefs.current['sale'] = el}>
                    <div className="golden-container Card">
                        <div
                            className={`tile-tools tile-dark pt-4 px-4`}
                            data-id="tools"
                            ref={el => sectionRefs.current['sale'] = el}
                        >
                            <h1 style={{ marginTop: "10px", marginBottom: "0px" }} className='typography-design-headline'>
                                 Try Popularis for free, today.
                            </h1>
                            <p style={{ marginTop: "10px", marginBottom: "20px" }} className="typography-label-alt-white">No credit card required. Popularis is latin for 'for the people.' Enjoy 25 free claims and eligibility requests on us. </p>
                            <div className={`cta-buttons`}>
                                <input
                                    type="email"
                                    placeholder="Email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                                <button style={{ marginTop: "0px", marginBottom: "0px" }} className="cta-button" onClick={() => handleNavigation('signup')}>
                                    Try for Free
                                </button>
                                <button style={{ marginTop: "0px", marginBottom: "0px" }} className="cta-button" onClick={() => handleNavigation('demo')}>
                                    Book a Demo
                                </button>
                            </div>
                            <div style={{ paddingBottom: "10px" }} className="hero-disclaimer-container" >
                                <p style={{ textAlign: "left", marginBottom: "10px" }} className={`hero-disclaimer ${isVisible['hero'] ? 'animate' : ''}`}>Immediate access - No credit card required</p>
                            </div>
                        </div>
                    </div>
                </section>

        </main>
      </div>
    </div>
  );
};

export default SalesPage;
