import React, { useState, useEffect } from 'react';
import HexagonSpinner from "../General/Animations/Hexspinner";

const AuthHexSpinner = () => {
  const [currentMessageIndex, setCurrentMessageIndex] = useState(0);

  // Define spinner messages array
  const spinnerMessages = [
    'Checking your geolocation...',
    'Verifying absence of VPN or TOR exit node...',
    'Performing reCAPTCHA verification...',
    'Verifying and logging IP address...'
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentMessageIndex((prevIndex) => (prevIndex + 1) % spinnerMessages.length);
    }, 2000); // Change message every 3 seconds

    return () => clearInterval(interval);
  }, []);

  return (
    <div>
      <HexagonSpinner /> {/* Render the HexagonSpinner component */}
      <div className="authmessageContainer">
      <p className="authMessage">{spinnerMessages[currentMessageIndex]}</p>
      </div>
    </div>
  );
};

export default AuthHexSpinner;
