import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { convertDateToMMDDYYYY } from "../../General/DateConversion";
import {
  faTrash,
  faEdit,
  faPrint,
  faClinicMedical,
  faHospital,
  faAmbulance,
  faLaptopMedical,
  faHome,
  faUserMd,
  faEye,
  faFileMedical,
  faHandshake,
  faShieldAlt,
  faHandHoldingMedical,
  faBuilding,
  faHeartbeat,
  faBed,
  faSmile,
  faUserTie,
} from "@fortawesome/free-solid-svg-icons";
import useUID from "../../General/useUID";
import { db } from "../../../firebase";
import { collection, doc, query, getDoc, onSnapshot } from "firebase/firestore";

const SingleEncounter = ({ encounter, onClose, patientId }) => {
  const [providerData, setProviderData] = useState(null);
  const [uid] = useUID();

  useEffect(() => {
    if (!uid) return;
    if (!patientId) {
      return; // Exit the useEffect if patientId isn't available
    }
    const encountersRef = collection(
      doc(db, "patients", uid, "patientData", patientId),
      "encounters"
    );

    // Create a query for the encounters
    const encountersQuery = query(encountersRef);

    // Set up the real-time listener
    const unsubscribe = onSnapshot(encountersQuery, async (snapshot) => {
      const loadedEncounters = [];
      for (const doc of snapshot.docs) {
        const encounter = doc.data();
        if (encounter.ciphertext && encounter.iv) {
          try {
            // Decrypt the encounter data
            const encryptedData = {
              ciphertext: encounter.ciphertext,
              iv: encounter.iv,
            };
            const decryptedResponse = await decryptFunction(encryptedData);
            const decryptedData = decryptedResponse.data.data;

            loadedEncounters.push({
              id: doc.id,
              ...decryptedData,
              // other properties you need to maintain from the original data that aren't encrypted
              timestamp: encounter.timestamp?.toDate().toLocaleString(),
            });
          } catch (error) {
            console.error("Error decrypting encounter data: ", error);
          }
        } else {
          // Push unencrypted data, or handle cases where data is not encrypted (if applicable)
          loadedEncounters.push({
            id: doc.id,
            ...encounter,
            timestamp: encounter.timestamp?.toDate().toLocaleString(),
          });
        }
      }
    });

    // Clean up the listener on component unmount
    return () => unsubscribe();
  }, [uid, patientId, db]);

  const fetchProviderData = async (providerId) => {
    const [uid] = useUID();

    if (!providerId) {
      return { organizationName: null, firstName: null, lastName: null };
    }

    try {
      const providerDocRef = doc(db, "users", uid, "providers", providerId);
      const providerSnapshot = await getDoc(providerDocRef);

      if (providerSnapshot.exists()) {
        return providerSnapshot.data();
      } else {
        return { organizationName: null, firstName: null, lastName: null };
      }
    } catch (error) {
      console.error("Error fetching provider data:", error);
      return { organizationName: null, firstName: null, lastName: null };
    }
  };

  useEffect(() => {
    fetchProviderData(encounter.providerId)
      .then((data) => setProviderData(data))
      .catch((error) => console.error("Error fetching provider data:", error));
  }, [encounter.providerId]);

  const faIcons = {
    "Outpatient Visit": faClinicMedical,
    "Inpatient Admission": faHospital,
    "Emergency Room Visit": faAmbulance,
    "Telemedicine Visit": faLaptopMedical,
    "Home Health Visit": faHome,
    "Ambulatory Care": faUserMd,
    "Day Surgery": faClinicMedical,
    "Observation": faEye,
    "Ancillary Services": faFileMedical,
    "Follow-up Visit": faHandshake,
    "Preventative Visit": faShieldAlt,
    "Rehabilitation Visit": faHandHoldingMedical,
    "Referral Visit": faBuilding,
    "Urgent Care Visit": faAmbulance,
    "Post-operative Visit": faHeartbeat,
    "Nursing Home Visit": faBed,
    "Routine Check-up": faSmile,
    "Maternity & Antenatal Visits": faUserTie,
  };

  return (
    <div className="actionContainer">
      <div className="popupContainer">
        <div className="popupContent">
          <button className="filesCloseButton" onClick={onClose}>
            <FontAwesomeIcon icon={faXmark} />
          </button>

          <div className="centerHeader">
            <h3>Encounter Details</h3>
          </div>

          <div className="m-5 pb-5">
  

            <div className="input-group-row info-card">
              <div className="input-field">
                <label>Date of Service:</label>
                <p>{convertDateToMMDDYYYY(encounter.dateOfService)}</p>
              </div>
            </div>

            {/* <div className="input-group-row info-card">
              <div className="input-field">
                <label>Diagnosis:</label>
                <ul>
                  {(encounter?.diagnosisCode)}
                  {encounter?.diagnosisCode.map((diagnosis) => (
                    <div className="m-2" key={diagnosis.code}>
                      {diagnosis.code} - {diagnosis.short_description}
                    </div>
                  ))}
                </ul>
              </div>
            </div> */}

            <div className="input-group-row info-card">
              <div className="input-field">
                <label>Reason for Visit:</label>
                <p>{encounter.reasonForVisit}</p>
              </div>
            </div>

            <div className="input-group-row info-card">
              <div className="input-field">
                <label>Service Provided:</label>
                <p>{encounter.service}</p>
              </div>
            </div>

            <div className="input-group-row info-card">
              <div className="input-field">
                <label>Encounter Type:</label>
                {faIcons[encounter.encounterType] && (
                  <p>
                    <FontAwesomeIcon icon={faIcons[encounter.encounterType]} />{" "}
                    {encounter.encounterType}
                  </p>
                )}
              </div>
            </div>

            <div className="input-group-row info-card">
              <div className="input-field">
                <label>Duration:</label>
                <p>{encounter.duration}</p>
              </div>
            </div>

            <div className="input-group-row info-card">
              <div className="input-field">
                <label>Provider Organization:</label>
                <p>{providerData?.organizationName}</p>
              </div>
            </div>

            <div className="input-group-row info-card">
              <div className="input-field">
                <label>Provider:</label>
                <p>
                  {providerData?.organizationName} | {providerData?.firstName}{" "}
                  {providerData?.lastName}
                </p>
              </div>
            </div>

            <div className="input-group-row info-card">
              <div className="input-field text-center">
                <label>Notes:</label>
                <p>{encounter.notes}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleEncounter;
