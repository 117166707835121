import React, { useState } from "react";
import styles from "../PatientView.module.css";
import { doc, collection, addDoc, serverTimestamp } from "firebase/firestore";
import { db } from "../../../firebase";
import useUID from "../../General/useUID";
import { getFunctions, httpsCallable } from "firebase/functions";
import HexagonSpinner from "../../General/Animations/Hexspinner";

const functions = getFunctions();

const medicalConditions = [
  "Diabetes",
  "Hypertension",
  "Heart Disease",
  "Cancer",
  "Stroke",
  "Asthma",
  "Other",
];

const familyMembers = [
  "Father",
  "Mother",
  "Siblings",
  "Children",
  "Grandparents",
  "Uncles/Aunts",
  "Cousins",
  "Other",
];

function FamilyHistory({ patientId, onClose }) {
  const [uid, subUserUID, error] = useUID();
  const [selectedFamilyMember, setSelectedFamilyMember] = useState(
    familyMembers[0].toLowerCase()
  );
  const [historyDetails, setHistoryDetails] = useState({});
  const [otherCondition, setOtherCondition] = useState("");
  const [isOtherConditionChecked, setIsOtherConditionChecked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleConditionChange = (condition, isChecked) => {
    if (condition === "Other") {
      setIsOtherConditionChecked(isChecked); // Control the rendering of the "Other" input field directly
      if (isChecked) {
        // When checking 'Other', add it without removing other conditions
        setHistoryDetails((prevDetails) => ({
          ...prevDetails,
          [selectedFamilyMember]: [
            ...(prevDetails[selectedFamilyMember] || []),
            "Other",
          ],
        }));
      } else {
        // When unchecking 'Other', remove it and 'Other:' related entries without removing other conditions
        setHistoryDetails((prevDetails) => ({
          ...prevDetails,
          [selectedFamilyMember]: (
            prevDetails[selectedFamilyMember] || []
          ).filter((c) => c !== "Other" && !c.startsWith("Other:")),
        }));
        // Clear the 'Other' input field since we're unchecking it
        setOtherCondition("");
      }
    } else {
      // Handle other conditions as before
      setHistoryDetails((prevDetails) => {
        const currentConditions = prevDetails[selectedFamilyMember] || [];
        if (isChecked) {
          // Add the condition to the list
          return {
            ...prevDetails,
            [selectedFamilyMember]: [...currentConditions, condition],
          };
        } else {
          // Remove the condition from the list
          return {
            ...prevDetails,
            [selectedFamilyMember]: currentConditions.filter(
              (c) => c !== condition
            ),
          };
        }
      });
    }
  };

  const handleOtherConditionChange = (e) => {
    const newValue = e.target.value;
    setOtherCondition(newValue); // Set the value for the 'Other' input field immediately

    // Defer updating the `historyDetails` until after the `otherCondition` state has been set
    if (newValue.trim() !== "") {
      setHistoryDetails((prevDetails) => {
        const updatedConditions = (
          prevDetails[selectedFamilyMember] || []
        ).filter((cond) => !cond.startsWith("Other"));
        return {
          ...prevDetails,
          [selectedFamilyMember]: [...updatedConditions, `Other: ${newValue}`],
        };
      });
    } else {
      // If the input is empty, we want to remove the 'Other' entry
      setHistoryDetails((prevDetails) => {
        const updatedConditions = (
          prevDetails[selectedFamilyMember] || []
        ).filter((cond) => !cond.startsWith("Other"));
        return updatedConditions.length > 0
          ? { ...prevDetails, [selectedFamilyMember]: updatedConditions }
          : Object.fromEntries(
              Object.entries(prevDetails).filter(
                ([key]) => key !== selectedFamilyMember
              )
            );
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    if (Object.keys(historyDetails).length === 0) {
      alert(
        "Please select a family member and fill in their medical conditions."
      );
      setIsLoading(false);
      return;
    }

    const encryptFunction = httpsCallable(functions, "encrypt"); // Reference to your cloud function

    try {
      // Call the encrypt function
      const encryptedResponse = await encryptFunction(historyDetails);
      const { ciphertext, iv } = encryptedResponse.data; // Destructure to extract ciphertext and iv directly

      await addDoc(
        collection(
          doc(db, "patients", uid, "patientData", patientId),
          "medicalHistory"
        ),
        {
          type: "FamilyHistory",
          timestamp: serverTimestamp(),
          ciphertext: ciphertext, // Use the extracted ciphertext
          iv: iv, // Use the extracted IV
        }
      );

      const logData = {
        timestamp: Date.now(),
        patientId: patientId,
        activity: "Medical history added",
        activityType: "medicalHistory",
        uid: uid,
      };

      await addDoc(collection(db, "users", uid, "activityLogs"), logData);

      setHistoryDetails({});
      setOtherCondition("");
      onClose();
    } catch (error) {
      console.error("Error during encryption or adding document: ", error);
    } finally {
      setIsLoading(false); // Stop loading after the async operation
    }
  };

  return (
    <div className={styles.familyHistoryForm}>
      <form onSubmit={handleSubmit}>
        <div className="input-group-row">
          <div className="input-field">
            <label htmlFor="familyMember">Select Family Member:</label>
            <select
              id="familyMember"
              value={selectedFamilyMember}
              onChange={(e) => setSelectedFamilyMember(e.target.value)}
            >
              {familyMembers.map((member) => (
                <option key={member} value={member.toLowerCase()}>
                  {member}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className="input-group-row">
          <div className="input-field">
            <label>Medical Conditions:</label>
            <div className="checkbox-group">
              {medicalConditions.map((condition) => (
                <label key={condition}>
                  <input
                    type="checkbox"
                    className="custom-checkbox"
                    value={condition}
                    checked={
                      historyDetails[selectedFamilyMember]?.includes(
                        condition
                      ) ||
                      (condition === "Other" &&
                        historyDetails[selectedFamilyMember]?.some((cond) =>
                          cond.startsWith("Other:")
                        )) ||
                      false
                    }
                    onChange={(e) =>
                      handleConditionChange(condition, e.target.checked)
                    }
                  />
                  {condition}
                </label>
              ))}
            </div>
          </div>
        </div>

        {isOtherConditionChecked && (
          <div className="input-group-row">
            <div className="input-field">
              <label htmlFor="otherCondition">Specify Other Condition:</label>
              <input
                type="text"
                id="otherCondition"
                value={otherCondition}
                onChange={handleOtherConditionChange}
                placeholder="Specify the condition"
              />
            </div>
          </div>
        )}

        {isLoading ? (
          <HexagonSpinner />
        ) : (
          <button
            type="submit"
            className={styles.submitButton}
            disabled={isLoading}
          >
            Submit Family History
          </button>
        )}
      </form>
    </div>
  );
}

export default FamilyHistory;
