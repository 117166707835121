const mapPatientDataForDownload = (patient) => {

    // Utility function to convert date format from 'YYYYMMDD' to 'MM/DD/YYYY'
    function formatDate(dateString) {
        if (dateString && dateString.length === 8) {
        const formattedDate = `${dateString.substring(4, 6)}/${dateString.substring(6, 8)}/${dateString.substring(0, 4)}`;
        return formattedDate;
        }
        return dateString;
    }

    const capitalizeName = (name) => {
        if (!name) return '';
        return name.charAt(0).toUpperCase() + name.slice(1).toLowerCase();
    };

       // Initialize flattenedData with basic patient info
    const flattenedData = {
        firstName: `${capitalizeName(patient.patient.firstName)}`,
        middleName: `${capitalizeName(patient.patient.middleName)} `,
        lastName: `${capitalizeName(patient.patient.lastName)}`,
        fullName: `${capitalizeName(patient.patient.firstName)} ${capitalizeName(patient.patient.middleName)} ${capitalizeName(patient.patient.lastName)}`,
        memberId: patient.payers?.memberId || '',
        insurance: patient.payers?.name || '',
        secondaryMemberId: patient.payers?.secondaryPayer?.memberId || '',
        secondaryInsurance: patient.payers?.secondaryPayer?.name || '',
        dob: patient.patient.dob || '',
        gender: patient.patient.gender || '',
        phone: patient.patient.phone || '',
        email: patient.patient.email || '',
        address1: `${patient.patient.address.address1 || ''}`,
        address2: `${patient.patient.address.address2 || ''}`,
        city: `${patient.patient.address.city || ''}`,
        state: `${patient.patient.address.state || ''}`,
        zip: `${patient.patient.address.zip}`,        
        flag: patient.flag || '',
    };
  
     
    const formatClaims = (claims) => {
        return claims.map(claim => {
            return {
                controlNumber: claim.requestData?.controlNumber || 'N/A',
                organizationName: claim.requestData?.receiver?.organizationName || 'N/A',
                serviceProvider: claim.requestData?.claimInformation?.serviceFacilityLocation?.organizationName || 'N/A',
                diagnosisCodes: claim.requestData?.claimInformation?.healthCareCodeInformation?.map(info => info.diagnosisCode) || ['N/A'],
                procedureCodes: claim.requestData?.claimInformation?.serviceLines?.map(line => line.professionalService?.procedureCode) || ['N/A'],
                lineItemChargeAmount: claim.requestData?.claimInformation?.serviceLines?.map(line => line.professionalService?.lineItemChargeAmount) || ['N/A'],
                serviceDates: claim.requestData?.claimInformation?.serviceLines?.map(line => formatDate(line.serviceDate)) || ['N/A']
            };
        });
    };
    
    flattenedData.claims = patient.claims ? formatClaims(patient.claims) : [];
    
    const formatClaimStatuses = (claimStatuses) => {
        return claimStatuses.map(status => {
            return {
                amountPaid: status.mainStatus?.amountPaid || 'N/A',
                statusCodeValue: status.mainStatus?.statusCodeValue || 'N/A',
                submittedAmount: status.mainStatus?.submittedAmount || 'N/A',
                trackingNumber: status.mainStatus?.trackingNumber || 'N/A',
                entity: status.mainStatus?.entity || 'N/A',
                statusCategoryCodeValue: status.mainStatus?.statusCategoryCodeValue || 'N/A',
                claimServiceDate: formatDate(status.mainStatus?.claimServiceDate) || 'N/A',
                controlNumber: status.mainStatus?.controlNumber || 'N/A',
                checkIssueDate: status.mainStatus?.checkIssueDate || 'N/A',
                checkNumber: status.mainStatus?.checkNumber || 'N/A',
                effectiveDate: status.mainStatus?.effectiveDate || 'N/A',
                paidDate: status.mainStatus?.paidDate || 'N/A',
                tradingPartnerClaimNumber: status.mainStatus?.tradingPartnerClaimNumber || 'N/A',
                // Additional fields for service details can be added here
                serviceDetails: status.serviceDetails ? status.serviceDetails.map(serviceDetail => {
                    return {
                        amountPaidService: serviceDetail.amountPaidService || 'N/A',
                        effectiveDateService: serviceDetail.effectiveDateService || 'N/A',
                        procedureId: serviceDetail.procedureId || 'N/A',
                        serviceIdQualifier: serviceDetail.serviceIdQualifier || 'N/A',
                        statusCategoryCodeValueService: serviceDetail.statusCategoryCodeValue || 'N/A',
                        statusCodeValueService: serviceDetail.statusCodeValue || 'N/A',
                        submittedAmountService: serviceDetail.submittedAmountService || 'N/A',
                        submittedUnits: serviceDetail.submittedUnits || 'N/A',
                        // Add more service detail fields here as needed
                    };
                }) : []
            };
        });
    };
    
    flattenedData.claimStatus = patient.claimStatus ? formatClaimStatuses(patient.claimStatus) : [];    
    
    const formatEligibility = (eligibilityData) => {
        return eligibilityData.map(eligibility => {
            return {
                insuranceStatus: eligibility.insuranceStatus || 'N/A',
                insuranceName: eligibility.insuranceName || 'N/A',
                coverageLevel: eligibility.coverageLevel || 'N/A',
                insuranceType: eligibility.insuranceType || 'N/A',
                payerID: eligibility.payerID || 'N/A',
                planDateInformation: eligibility.planDateInformation || 'N/A',
                planDetails: eligibility.planDetails || 'N/A',
                providerName: eligibility.providerName || 'N/A',
                serviceTypeCodes: eligibility.serviceTypeCodes || 'N/A',
                subentityIdentifier: eligibility.subentityIdentifier || 'N/A',
                subentityType: eligibility.subentityType || 'N/A',
                subfirstName: eligibility.subfirstName || 'N/A',
                sublastName: eligibility.sublastName || 'N/A',
                // Additional fields can be added here as needed
            };
        });
    };
    
    flattenedData.eligibility = patient.eligibility ? formatEligibility(patient.eligibility) : [];
    const formatEncounter = (encounterData) => {
        console.log(encounterData);
    
        return encounterData.map(encounter => {
            // Generate human-readable fields from selectedFormData and formInputs
            const formatSelectedData = (selectedData) => {
                return selectedData.map(data => ({
                    id: data.id || 'N/A',
                    order: data.order || 'N/A',
                    type: data.type || 'N/A',
                    fields: data.fields ? data.fields.map(field => ({
                        label: field.label || 'N/A',
                        required: field.required ? 'Yes' : 'No',
                        title: field.title || 'N/A',
                        value: field.value || ''
                    })) : []
                }));
            };
    
            const formattedFormInputs = formatSelectedData(encounter.formInputs || []);
            const formattedSelectedFormData = formatSelectedData(encounter.selectedFormData || []);
    
            return {
                diagnosisCode: encounter.diagnosisCode || 'N/A',
                encounterType: encounter.encounterType || 'N/A',
                reasonForVisit: encounter.reasonForVisit || 'N/A',
                service: encounter.service || 'N/A',
                dateOfService: encounter.dateOfService ? formatDate(encounter.dateOfService) : 'N/A',
                notes: encounter.notes || 'N/A',
                organizationName: encounter.organizationName || 'N/A',
                providerfirstName: encounter.providerfirstName || 'N/A',
                providerlastName: encounter.providerlastName || 'N/A',
                duration: encounter.duration || 'N/A',
                BillingStatus: encounter.BillingStatus || 'N/A',
                billingCode: encounter.billingCode ? encounter.billingCode.map(code => ({
                    id: code.id || 'N/A',
                    name: code.name || 'N/A',
                    code: code.code || 'N/A',
                    billAmount: code.billAmount || 'N/A',
                    diagnosisCode: code.diagnosisCode || ['N/A'],
                    modifierA: code.modifierA || 'N/A',
                    modifierB: code.modifierB || 'N/A'
                })) : [],
                controlNumber: encounter.controlNumber || 'N/A',
                
                // Add formatted form inputs and selected form data
                selectedData: formattedFormInputs.length > 0 ? formattedFormInputs : formattedSelectedFormData,
    
                selectedFormId: encounter.selectedFormId || 'N/A',
                selectedProviders: encounter.selectedProviders ? {
                    providerId: encounter.selectedProviders.providerId || 'N/A',
                    organizationName: encounter.selectedProviders.organizationName || 'N/A',
                    firstName: encounter.selectedProviders.firstName || 'N/A',
                    lastName: encounter.selectedProviders.lastName || 'N/A',
                    address1: encounter.selectedProviders.address1 || 'N/A',
                    address2: encounter.selectedProviders.address2 || 'N/A',
                    city: encounter.selectedProviders.city || 'N/A',
                    state: encounter.selectedProviders.state || 'N/A',
                    zip: encounter.selectedProviders.zip || 'N/A',
                    phone: encounter.selectedProviders.phone || 'N/A',
                    email: encounter.selectedProviders.email || 'N/A',
                    fax: encounter.selectedProviders.fax || 'N/A',
                    npi: encounter.selectedProviders.npi || 'N/A',
                    taxId: encounter.selectedProviders.taxId || 'N/A'
                } : {}
            };
        });
    };
    
    flattenedData.encounters = patient.encounters ? formatEncounter(patient.encounters) : [];
    

    const formatNotes = (notesData) => {
        return notesData.map(note => {
            // Convert Firestore timestamp to JavaScript Date object, if applicable
            const formattedTimestamp = note.timestamp
                ? (note.timestamp.toDate ? note.timestamp.toDate() : new Date(note.timestamp)).toLocaleString()
                : 'N/A';
    
            return {
                authorName: note.authorName || 'N/A',
                isAIGenerated: note.isAIGenerated ? 'Yes' : 'No',
                text: note.text || 'N/A',
                timestamp: formattedTimestamp,
                title: note.title || 'N/A'
            };
        });
    };
    
    
    flattenedData.notes = patient.notes ? formatNotes(patient.notes) : [];
    
    const formatMedicalHistory = (medicalHistory) => {
        return medicalHistory.map(record => {
            const formattedRecord = { 
                timestamp: record.timestamp || 'N/A',  // Add timestamp field to all records
                type: record.type
            };
            
            switch (record.type) {
    
                case 'PatientHistory':
                    return {
                        ...formattedRecord,
                        condition: record.condition || 'N/A',
                        treatment: record.treatment || 'N/A',
                        treatmentStartDate: record.treatmentStartDate || 'N/A',
                        treatmentEndDate: record.treatmentEndDate || 'N/A',
                        doctor: record.doctor || 'N/A',
                        notes: record.notes || 'N/A'
                    };
    
                case 'ImmunizationsAllergies':
                    return {
                        ...formattedRecord,
                        immunizations: record.immunizations || 'N/A',
                        allergies: record.allergies || 'N/A'
                    };
    
                case 'Vitals':
                    const vitals = Object.keys(record)
                        .filter(key => ['bloodPressure', 'bmi', 'heartRate', 'oxygenSaturation', 'respiratoryRate', 'temperature', 'weight'].includes(key))
                        .reduce((obj, key) => ({ ...obj, [key]: record[key] || 'N/A' }), {});
                    return { ...formattedRecord, ...vitals };
    
                case 'LabAndTest':
                    const labTests = ['notes', 'testDate', 'testName', 'testResults']
                        .reduce((obj, field) => ({ ...obj, [field]: record[field] || 'N/A' }), {});
                    return { ...formattedRecord, ...labTests };
    
                case 'Medications':
                    const medications = ['dosage', 'endDate', 'frequency', 'medicationName', 'notes', 'startDate']
                        .reduce((obj, field) => ({ ...obj, [field]: record[field] || 'N/A' }), {});
                    return { ...formattedRecord, ...medications };
    
                case 'FamilyHistory':
                    const familyHistoryFormatted = {};
                    Object.keys(record).forEach(key => {
                        if (key !== 'type' && key !== 'timestamp') {
                            familyHistoryFormatted[key] = record[key].join(', ') || 'N/A';
                        }
                    });
                    return {
                        ...formattedRecord,
                        ...familyHistoryFormatted
                    };
    
                default:
                    return { ...formattedRecord, details: 'Unknown Medical History Record' };
            }
        });
    };
    
       
    
    flattenedData.medicalHistory = patient.medicalHistory ? formatMedicalHistory(patient.medicalHistory) : [];

// Add any additional transformations or data aggregations here
return flattenedData;

};

export default mapPatientDataForDownload;