import React, { useState, useEffect } from 'react';
import { db } from "../../../firebase";
import { collection, onSnapshot, doc } from 'firebase/firestore';
import useUID from '../../General/useUID';
import styles from "../PatientView.module.css";
import QuicksearchData from '../../Eligibility/EligibilityResultsViewData';
import { getFunctions, httpsCallable } from "firebase/functions";

const functions = getFunctions();

function EligibilityCards({ patientId }) {
  const [eligibilityData, setEligibilityData] = useState([]);
  const [expandedId, setExpandedId] = useState(null);
  const [selectedEligibilityData, setSelectedEligibilityData] = useState(null); // State to hold selected eligibility data
  const [uid, subUserUID, isLoading, error] = useUID();

  useEffect(() => {
    if (!uid || !patientId) return; // Exit if uid or patientId is not available
  
    // Define a reference to the eligibility documents in Firestore
    const eligibilityRef = collection(doc(db, 'patients', uid, 'patientData', patientId), 'eligibility');
  
    // Define the function to decrypt data
    const decryptFunction = httpsCallable(functions, "decrypt");
  
    // Listen to real-time updates of eligibility data
    const unsubscribe = onSnapshot(eligibilityRef, async (querySnapshot) => {
      const docs = querySnapshot.docs;
  
      // Map over each document, decrypt data, and format it
      const eligibilityDocs = await Promise.all(docs.map(async doc => {
        const encryptedData = doc.data(); // Assume data is encrypted
        try {
          const decryptedResponse = await decryptFunction(encryptedData);
          const decryptedData = decryptedResponse.data; // Assuming the decrypted data is directly usable
          
          // Return the structured document with decrypted and formatted data
          return {
            id: doc.id,
            ...decryptedData,
            timestamp: doc.data().timestamp
          };
        } catch (error) {
          console.error('Error decrypting data:', error);
          return {
            id: doc.id,
            error: "Failed to decrypt",
            formattedUpdatedAt: 'Error',
          };
        }
      }));
  
      // Sort the documents by updatedAt in descending order
      eligibilityDocs.sort((a, b) => (b.updatedAt?.seconds || 0) - (a.updatedAt?.seconds || 0));
      setEligibilityData(eligibilityDocs);
    }, (error) => {
      console.error('Error fetching eligibility data:', error);
    });
  
    return () => unsubscribe();
  }, [uid, patientId]);  

  const handleOpenQuicksearchData = (data) => {
    setSelectedEligibilityData(data);
  };

  const renderDataField = (label, data) => {
    return data ? <p>{label}: {data}</p> : null;
  };

  return (
    <div className='eligibilityContainer'>
      {eligibilityData.length > 0 ? (
        <>
          <h3>Eligibility</h3>
          <div style={{ height: '1px', backgroundColor: 'black', marginTop: '10px', marginBottom: '10px' }}></div>
          {eligibilityData.map(data => (
            <div key={data.id} className={`patientViewCard ${styles.patientViewCard}`}>
              {renderDataField('Insurance', data.payer?.name ?? 'Unknown')}
              {renderDataField('Status', data.planStatus?.[0]?.status ?? 'Unknown')}
              {renderDataField('Updated At', data.timestamp?.toDate().toLocaleString())}
              <button onClick={() => handleOpenQuicksearchData(data)}>View Details</button>
            </div>
          ))}
        </>
      ) : null}
      {selectedEligibilityData && (
        <QuicksearchData patientData={{eligibilityData: selectedEligibilityData}} onClose={() => setSelectedEligibilityData(null)} />
      )}
    </div>
  );
}

export default EligibilityCards;
