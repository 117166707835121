import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { collection, query, getDocs, where, doc, getDoc } from 'firebase/firestore';
import { db } from "../../../firebase";
import { getFunctions, httpsCallable } from "firebase/functions";
import HexagonSpinner from '../../General/Animations/Hexspinner';
import useUID from '../../General/useUID';

function AppointmentDetails({ appointment }) {
  const [intakeFormData, setIntakeFormData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [patientData, setPatientData] = useState(null);
  const [uid, subUserUID] = useUID();

  const navigate = useNavigate();
  const functions = getFunctions();
  const decryptFunction = httpsCallable(functions, 'decrypt');

  const fetchPatientData = async (uid) => {
    if (!appointment) return;

    const patientDocRef = doc(db, `patients/${uid}/patientData/${appointment.patientId}`);

    const patientDoc = await getDoc(patientDocRef);

    if (patientDoc.exists()) {
      try {
        const encryptedPatientData = patientDoc.data().patient;
        const encryptedPayload = {ciphertext: encryptedPatientData.ciphertext, iv: encryptedPatientData.iv}
        const decryptedResponse = await decryptFunction(encryptedPayload);
        const patientData = decryptedResponse.data;
        setPatientData(patientData);
      } catch (decryptionError) {
        console.error("Error decrypting patient data:", decryptionError);
      }
    }
  };

  const fetchIntakeForm = async () => {
    if (!appointment) return;

    const intakeFormsRef = collection(db, `clinics/${appointment.clinicId}/intakeForms`);
    const intakeFormsQuery = query(intakeFormsRef, where("patientId", "==", appointment.patientId));
    const intakeFormsSnapshot = await getDocs(intakeFormsQuery);

    if (!intakeFormsSnapshot.empty) {
      const intakeFormDoc = intakeFormsSnapshot.docs[0];
      const encryptedIntakeFormData = intakeFormDoc.data();
      try {
        const decryptedResponse = await decryptFunction({ encryptedData: encryptedIntakeFormData });
        setIntakeFormData(decryptedResponse.data);
      } catch (decryptionError) {
        console.error("Error decrypting intake form data:", decryptionError);
        setIntakeFormData({});
      }
    } else {
      setIntakeFormData({});
    }
    setLoading(false);
  };

  useEffect(() => {
    if (!uid || !appointment) return;
    setLoading(true);
    fetchPatientData(uid).then(() => fetchIntakeForm()).finally(() => setLoading(false));
  }, [appointment, uid]);

  const handleViewPatient = (patientId) => {
    navigate(`/patients/${patientId}`);
  };

  if (loading) {
    return (
      <div>
        <HexagonSpinner />
        <p className='authMessage'>Decrypting...</p>
      </div>
    );
  }

  if (!appointment) {
    return <div>Select an appointment to see the details.</div>;
  }

  const { patient } = patientData || {};
  const { payers } = patientData || {};

  return (
    <div style={{ alignItems: "flex-start", marginTop: "2rem" }}>
      <h3>Appointment: <strong>{appointment.appointmentType}</strong></h3>
      <h4>{appointment.start.toLocaleDateString()} | {appointment.start.toLocaleTimeString()} - {appointment.end.toLocaleTimeString()} {appointment.timeZone}</h4>
      <span
        onClick={() => handleViewPatient(appointment.patientId)}
        style={{ color: "#0147DF", cursor: "pointer" }}
      >
        View Patient's File {">"}
      </span>
      {patient && (
        <>
          <p><strong>Name:</strong> {patient.firstName} {patient.middleName} {patient.lastName}</p>
          <p><strong>Date of Birth / Gender:</strong> {patient.dob} | {patient.gender}</p>
          {patient.phone && <p><strong>Phone:</strong> {patient.phone}</p>}
          {patient.email && <p>{patient.email}</p>}
          {patient.address?.address1 && (
            <p>
              <strong>Address:</strong> 
              {patient.address.address1 && `${patient.address.address1}, `}
              {patient.address.city && `${patient.address.city}, `}
              {patient.address.state && `${patient.address.state} `}
              {patient.address.zip && patient.address.zip}
            </p>
          )}
        </>
      )}

      {payers && (
        <>
          {payers.name && <p><strong>Insurance Information:</strong> {payers.name} | {payers.memberId}</p>}
        </>
      )}

      {/* {intakeFormData && (
        <>
          <h4>Intake Form Data</h4>
          <pre>{JSON.stringify(intakeFormData, null, 2)}</pre>
        </>
      )} */}
    </div>
  );
}

export default AppointmentDetails;
