import React, { useState } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import styles from '../MainStyles.css';
import { getFunctions, httpsCallable } from 'firebase/functions';
import useUID from "../useUID";
import HexagonSpinner from "../Animations/Hexspinner";

const functions = getFunctions();

const CardUpdateForm = ({ handleSubmit }) => {
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const stripe = useStripe();
  const elements = useElements();
  const [uid, subUserUID, isLoading, error] = useUID();

  const handleUpdateCard = async (e) => {
    e.preventDefault();
    setErrorMessage("");
    setSuccessMessage("");
    setLoading(true);

    if (!stripe || !elements) {
      setLoading(false);
      return;
    }

    const cardElement = elements.getElement(CardElement);
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
    });

    if (error) {
      console.error("Error updating card:", error);
      setErrorMessage("Error updating card.");
      setLoading(false);
    } else {
      try {
        await handleSubmit(paymentMethod.id);
        setSuccessMessage("Card updated successfully!");
        // Logs
        const addLogFunction = httpsCallable(functions, 'addLog');
        await addLogFunction({ uid, message: 'Card Updated.' });
      } catch (error) {
        console.error("Error updating card:", error);
        setErrorMessage("Error updating card.");
      }
      setLoading(false);
    }
  };

  return (
    <div>
      <p>Change the card on file:</p>
      <form onSubmit={handleUpdateCard}>
        <input type="hidden" name="action" value="update-card" />
        <label>
          <CardElement />
        </label>
        {loading ? (
          <HexagonSpinner />
        ) : (
          <button type="submit">Update Card</button>
        )}
      </form>
      {errorMessage && <p className="error">{errorMessage}</p>}
      {successMessage && <p className="success">{successMessage}</p>}
    </div>
  );
};

export default CardUpdateForm;
