import React, { useEffect, useState } from 'react';
import CryptoJS from 'crypto-js';
import ReactMarkdown from 'react-markdown';

const dataSamples = [
  {
    Provider: {
      "Address": "123 GRUMPY ST, MANHATTAN, NY, 100010000",
      "EIN": "000000001",
      "First Name": "WINCHENZO",
      "Last Name": "GRUMPY",
      "NPI": "1231231234",
      "Organization Name": "GRUMPYFACE ORG",
      "POS Code": "11",
      "Phone": "3109991123"
    }
  },
  {
    Patient: {
      "Address": "123 Address, City, WA, 98101",
      "DOB": "01/01/1980",
      "Email": "johndoe@doe.com",
      "First Name": "John",
      "Gender": "M",
      "Last Name": "Doe",
      "Phone": "(312) 123-1231"
    }
  },
  {
    Encounter: {
      "Billing Status": "NotBilled",
      "Date of Service": "10/15/2023",
      "Diagnosis Code": "H7403 - Tympanosclerosis, bilateral",
      "Organization Name": "WINSTONS CLINICS INC",
      "Provider First Name": "Winston",
      "Provider Last Name": "Doe",
      "Reason for Visit": "Annual visit with patient.",
      "Service": "Wellness Checkup",
      "Encounter Type": "Routine Check-up",
      "Duration": "00:20:00"
    }
  },
  {
    Notes: {
      "Author Name": "Dr. Winston Doe",
      "Text": "The patient was prescribed antibiotics for their ear infection.",
      "Title": "Ear Infection Visit"
    }
  },
  {
    MedicalHistory: [
      {
        "Condition": "Stroke",
        "Notes": "Patient had a stroke and recurrent cerebrovascular episodes.",
        "Treatment": "X Medication",
        "Treatment Start Date": "1952-04-22",
        "Treatment End Date": "1952-04-22",
        "Type": "PatientHistory"
      },
      {
        "Family History": "Father had Cancer",
        "Type": "FamilyHistory"
      },
      {
        "Medication Name": "ibuprofen",
        "Dosage": "200mg",
        "Frequency": "once a week",
        "Start Date": "2024-04-22",
        "End Date": "2024-04-22",
        "Notes": "for hangovers",
        "Type": "Medications"
      },
      {
        "Allergies": "cats",
        "Immunizations": "Smallpox Vaccine",
        "Type": "ImmunizationsAllergies"
      },
      {
        "Blood Pressure": "120/88",
        "Heart Rate": "99",
        "Oxygen Saturation": "99",
        "Respiratory Rate": "99",
        "Temperature": "99",
        "Weight": "180",
        "Type": "Vitals"
      }
    ]
  }
];

const formatData = (data) => {
  let formatted = '';
  const traverse = (obj, indent = 0) => {
    for (let key in obj) {
      if (typeof obj[key] === 'object' && !Array.isArray(obj[key])) {
        formatted += `${' '.repeat(indent * 2)}**${key}**:\n`;
        traverse(obj[key], indent + 1);
      } else if (Array.isArray(obj[key])) {
        formatted += `${' '.repeat(indent * 2)}**${key}**:\n`;
        obj[key].forEach((item, index) => {
          formatted += `${' '.repeat((indent + 1) * 2)}- **${index + 1}**:\n`;
          traverse(item, indent + 2);
        });
      } else {
        formatted += `${' '.repeat(indent * 2)}- **${key}**: ${obj[key]}\n`;
      }
    }
  };
  traverse(data);
  return formatted;
};

const EncryptionSample = () => {
  const [index, setIndex] = useState(0);
  const [hash, setHash] = useState('');
  const [fade, setFade] = useState(false);

  const generateHash = () => {
    const data = dataSamples[index];
    const dataString = JSON.stringify(data);
    const newHash = CryptoJS.SHA512(dataString).toString(CryptoJS.enc.Hex);
    setHash(newHash);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setFade(true);
      setTimeout(() => {
        setIndex((prevIndex) => (prevIndex + 1) % dataSamples.length);
        setFade(false);
      }, 250);
    }, 4000); // Change data every 3 seconds

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    generateHash();
  }, [index]);

  const renderMarkdown = () => {
    const data = dataSamples[index];
    return `
${formatData(data)}
`;
  };

  return (
    <div className='twobygrid'>
      <div className={`twoby-grid-item`}>
        <h2 className='text-center'>What We See</h2>
        <p className={fade ? 'transition' : 'active'} style={{ wordWrap: 'break-word' }}>{hash}</p>
      </div>
      <div className='vertical-line'></div>
      <div className={`twoby-grid-item markdown-container`}>
        <h2 className='text-center'>What You See</h2>
        <ReactMarkdown className={fade ? 'transition' : 'active'}>{renderMarkdown()}</ReactMarkdown>
      </div>
    </div>
  );
};

export default EncryptionSample;
